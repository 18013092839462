import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import NewRegionSelectorCard from "./NewRegionSelectorCard";

@inject(
  "userStore",
  "newRegionSelectorStore",
  "navigationStore",
  "sessionStore"
)
@observer
class NewRegionSelector extends Component {
  async componentDidMount() {
    try {
      console.log(this.props)
      await this.props.newRegionSelectorStore.saveOrigin(this.props.page, this.props.regBool)
      await this.props.newRegionSelectorStore.getlistRegionsIds();
      this.props.newRegionSelectorStore.getSubregions();
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  componentWillUnmount() {
    this.props.newRegionSelectorStore.updateProperty("showAlert", false);
    this.props.newRegionSelectorStore.updateProperty("confirmAlert", false);
  }

  render() {
    const { t } = this.props;
    if (this.props.sessionStore.initialized) {
      return (
        <>
          {this.props.newRegionSelectorStore.showAlert ?
          <div
            className={
              "alert alert-danger" +
              (!!this.props.newRegionSelectorStore.showAlert
                ? " alert-custom show"
                : " alert-custom hide")
            }
          >
            <p id="danger-alert-stats"></p>
            <button
              type="button"
              className="close"
              onClick={() => {
                this.props.newRegionSelectorStore.updateProperty("showAlert", false);
              }}
            >
              x
            </button>
          </div>
          :null}
          {this.props.newRegionSelectorStore.confirmAlert ?
          <div
            className={
              "alert alert-success" +
              (!!this.props.newRegionSelectorStore.confirmAlert
                ? " alert-custom show"
                : " alert-custom hide")
            }
          >
            <p id="succes-alert-stats"></p>
            <button
              type="button"
              className="close"
              onClick={() => {
                this.props.newRegionSelectorStore.updateProperty("confirmAlert", false);
              }}
            >
              x
            </button>
          </div>
          :null}
          <div className="col-md-1 area-quick-search">
            <OverlayTrigger
              delay={{ show: 500, hide: 100 }}
              placement="top"
              overlay={<Tooltip id="tooltip">{this.props.page === "mapLicense" ? t("census.fastSearch") : t("stats.fastSearch")}</Tooltip>}
            >
              <button
                type="button"
                className="btn btn-info"
                data-toggle="modal"
                data-target=".area-select-modal"
              >
                <svg
                  className="svg-icon-ellipsis"
                  data-name="fas fa-ellipsis-v"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 18 62"
                >
                  <circle className="cls-1" cx="9" cy="9" r="9" />
                  <circle className="cls-1" cx="9" cy="31" r="9" />
                  <circle className="cls-1" cx="9" cy="53" r="9" />
                </svg>
              </button>
            </OverlayTrigger>
            <div
              className="modal fade area-select-modal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="AreaSelectModal"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="row modal-header">
                    <div className="col-md-10">
                      <h5 className="modal-title">{t("stats.workingArea")}</h5>
                    </div>
                    <div className="col-md-2">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                  <div className="modal-body">
                    {/*Acordio Pare*/}
                    <div
                      className="accordion md-accordion accordionAreas"
                      id="accordionAreas"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      {this.props.newRegionSelectorStore.Regions.map((region, i) => {
                        //console.log(region.name, i);
                        return (
                          <NewRegionSelectorCard
                            key={"Card Start-" + i}
                            regname={region.name}
                            regadm={region.adm}
                            subreg={region.hasSubregions}
                            regid={region._id}
                            it={1}
                            pos={i}
                            t={this.props.t}
                          />
                        );
                      })}
                    </div>
                  </div>
                  {this.props.userStore.getUser() &&
                  this.props.userStore.getUser().isSuperuser ? (
                    <div className="modal-footer text-center">
                      <button
                        className="btn btn-outline-info"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          this.props.navigationStore.push("/workingRegionEdit");
                        }}
                      >
                        {t("stats.modify")}
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    return null;
  }
}
export default withTranslation()(NewRegionSelector);
