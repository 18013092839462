import { observable, action, computed } from "mobx";
import i18n from "../../i18n";

class TeamStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.updateProperty = this.updateProperty.bind(this);
  }

  @observable dataUsers = [];
  @observable user = null;
  @observable res = null;
  @observable merged = null;
  @observable totalAdsUser = [];
  @observable subregions = {};
  @observable clicked = {};
  @computed get tableData() {
    let data = null
    if (this.dataUsers && this.dataUsers.length > 0) {
      data = this.dataUsers.slice(
        this.rootStore.pageStore.len * this.rootStore.pageStore.page,

        this.rootStore.pageStore.len * (this.rootStore.pageStore.page + 1)
      );

      for (let i = 0; i < data.length; i++) {
        for (var key in data[i]) {
          if (data[i].hasOwnProperty(key)) {
            if (!data[i][key]) {
              data[i][key] = "-";
            }
          }
        }
      }
    }
    return data;
  }

  @action("update attribute") updateProperty = (attrName, value) => {
    this[attrName] = value;
  };

  initView = () => {
    this.updateData();
  };

  updateData = async () => {
    try {
      this.rootStore.pageStore.updateProperty(
        "totalPages",
        Math.ceil(this.dataUsers.length / this.rootStore.pageStore.len)
      );
      this.rootStore.pageStore.updateProperty("page", 0);
    } catch (error) {
      console.log("An error ocurred while executing the action requested");
    }
  };

  @action("Get users team") getUsers = async () => {
    try {
      let user = await this.rootStore.sessionStore.getSession();
      this.user = user
      this.dataUsers = await this.rootStore.requestStore.getUsersFromGroup(
        user.group
      );
	    console.log(this.user,this.dataUsers)
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("Get statistics team") getStatisticsTeam = async (username, target, i) => {
    try {
      if (document.getElementById(username + i).className === "") {
        let aux;
        let result = [];
        this.totalAdsUser = [];
        this.merged = [];
        // for (let i = 0; i < this.dataUsers.length; i++) {
        aux = this.dataUsers[(this.rootStore.pageStore.page * 20) + i];
        if (aux.assignedRegions) {
          this.res = await this.rootStore.requestStore.getAdsTeam(
            aux.username,
            aux.assignedRegions
          );
          let total = this.res.reduce(function (accum, totalAds) {
            return accum + totalAds.numAds;
          }, 0);
          this.totalAdsUser.push({ username: aux.username, totalAds: total });

          for (let i = 0; i < this.res.length; i += 1) {
            let info = await this.rootStore.requestStore.getRegionForTeam(this.res[i]["regionName"], this.res[i]["adm"])
            this.res[i]["parentRegions"] = info[0].parentRegions
            this.res[i]["_id"] = info[0]._id
            if (this.res[i]["numAds"] === 0) {
              let num = await this.rootStore.requestStore.countAdsByRegion(this.res[i]._id, this.res[i]["adm"])
              this.res[i]["numAds"] = num.result
            }

            this.res[i]["hasSubregions"] = info[0].hasSubregions
          }
          let existingId = new Set(this.res.map(item => item._id))
          this.res.map(item => console.log(item))
          this.res = this.res.filter(item => !(Object.values(item.parentRegions).some(parentId => existingId.has(parentId))))
          //console.log("SORTED ISSS ", this.res.slice().sort("regionName"))
          total = this.res.reduce(function (accum, totalAds) {
            return accum + totalAds.numAds;
          }, 0);
          this.totalAdsUser.forEach(element => {
            if (element.username = aux.username) element.totalAds = total;
          });
        }


        result.push(this.res);
        this.merged = [].concat.apply([], result);
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };


  @action("Download ads modified by user") downloadAdsModifiedByUser = async (username) => {
    try {
      let data = await this.rootStore.requestStore.getModifiedAdsByUser(username)
      console.log("data: ", data.data)
      let infoExcel = []
      for (let adGroup of data.data) {
        console.log("adGroup: ", adGroup)
        let group = i18n.t("team.group")
        let id = i18n.t("team.idAd")
        let platform = i18n.t("team.platform")
        let address = i18n.t("team.address")
        let finishedProcessing = i18n.t("team.finishedProcessing")
        let hostAddress = i18n.t("team.hostAddress")
        let hostName = i18n.t("team.hostName")
        let license = i18n.t("team.license")
        let groupTitle = i18n.t("team.title")
        let municipality = i18n.t("team.localitat")
        let tags = i18n.t("team.tags")
        let state = i18n.t("team.state")
        let stateNotes = i18n.t("team.stateNotes")
        let workingUser = i18n.t("team.workingUser")
        let coords = i18n.t("team.coords")
        let adData = await this.rootStore.requestStore.getAdsFromGroupAd(adGroup._id)
        for (let ad of adData.data) {
          if (ad === undefined || ad === null) continue
          let value = {}

          value[group] = adGroup._id;
          value[platform] = ad.website
          value[id] = ad._id;
          value[address] = adGroup.address;
          value[finishedProcessing] = adGroup.finishedProcessing;
          if (adGroup.host) {
            value[hostAddress] = adGroup.host.address;
            value[hostName] = adGroup.host.name;
          }
          else {
            value[hostAddress] = ""
            value[hostName] = ""
          }
          value[license] = adGroup.license;
          value[groupTitle] = adGroup.title;
          value[municipality] = adGroup.municipality;
          value[tags] = (adGroup.tags).toString();
          if (adGroup.state) {
            value[state] = adGroup.state.name;
            if (adGroup.state.message)
              value[stateNotes] = adGroup.state.message.notes
            else value[stateNotes] = ""
          }
          else {
            value[state] = ""
            value[stateNotes] = ""
          }
          value[workingUser] = adGroup.workingUser;
          if (ad.regionStats) {
            if (ad.regionStats.adm1) value["adm1"] = ad.regionStats.adm1.name;
            if (ad.regionStats.adm2) value["adm2"] = ad.regionStats.adm2.name;
            if (ad.regionStats.adm3) value["adm3"] = ad.regionStats.adm3.name;
            if (ad.regionStats.adm4) value["adm4"] = ad.regionStats.adm4.name;
            if (ad.regionStats.adm5) value["adm5"] = ad.regionStats.adm5.name;
            if (ad.regionStats.adm6) value["adm6"] = ad.regionStats.adm6.name;
          }
          value[coords] = ad.geometry.coordinates[0] + ";" + ad.geometry.coordinates[1]
          infoExcel.push(value)
        }
      }
      console.log("infoExcel: ", infoExcel)
      let data2 = await this.rootStore.requestStore.generateExcelForModifiedAds(this.rootStore.userStore.user.username + "_ads_modified_by_" + username, infoExcel);

      console.log("data: ", data)
      console.log("data 2: ", data2);
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  @action("find subRegions") findSubRegions = async (id, adm) => {
    try {
      let idWithAdm = {
        _id: id,
        adm: adm,
      };
      let res2 = await this.rootStore.requestStore.getChildRegionsData(
        idWithAdm
      );
      return res2.data;
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("Click On drop down") clickedOn = async (it, pos, id, adm, user) => {
    if (!(user in this.clicked)) {
      this.clicked[user] = {}
    }
    if (!(it in this.clicked[user])) {
      this.clicked[user][it] = {};
    }
    if (it in this.clicked[user] && !(pos in this.clicked[user][it])) {
      this.clicked[user][it][pos] = false;
    }
    if (!(id in this.subregions)) {
      this.getSubregions(id, adm, user);
    }
    //console.log(it, pos, this.clicked, this.clicked[it]);
    this.clicked[user][it][pos] = !this.clicked[user][it][pos];
  };

  @action("Get subregions") getSubregions = async (id, adm, user) => {
    try {
      if (!(id in this.subregions)) {
        this.subregions[id] = [];
      }
      let test = await this.findSubRegions(id, adm);
      for (let i = 0; i < test.length; i += 1) {
        test[i]["workAds"] = await this.rootStore.requestStore.countAssignedAdsRegion(test[i]._id, user, test[i].adm)
        test[i]["username"] = user
        if (!("numAds" in test[i])) test[i]["numAds"] = 0
        if (test[i]["numAds"] === 0) {
          let num = await this.rootStore.requestStore.countAdsByRegion(test[i]._id, test[i]["adm"])
          test[i]["numAds"] = num.result
        }
        test[i]["regionName"] = test[i]["name"]
        this.subregions[id].push(test[i])
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("Open Working Area") openWA = (id) => {
    this.rootStore.navigationStore.openParentInNewTab(
      "area?focusedRegion=" + id
    );
  }

  @action("Download info for supervisor") downloadInfoForSupervisor = async () => {
    console.log("Downloading...")
    let infoExcel = []
    let group = i18n.t("team.group")
    let idAd = i18n.t("team.idAd")
    let workingUser = i18n.t("team.workingUser")
    let linkGroup = i18n.t("team.linkGroup")
    let linkAd = i18n.t("team.linkAd")
    let platform = i18n.t("team.platform")
    let localitat = i18n.t("team.localitat")
    let date = i18n.t("team.date")
    let persona = i18n.t("team.persona")
    let assignations = await this.rootStore.requestStore.getLogsLastAssigned();
    let ads = await this.rootStore.requestStore.getAdsWithWorkingUser(this.dataUsers.map(item => item.username));
    for (let ad of ads) {
      if (ad !== null) {
        let value = {}
        let city;
        if (ad.regionStats) {
          let keys = Object.keys(ad.regionStats)
          city = ad.regionStats[keys[keys.length - 1]].name
        }
        value[group] = ad.adGroupCode;
        value[workingUser] = ""

        value[linkGroup] = "https://stlpro.talkandcode.com/groupAds/" + ad.adGroupCode
        value[linkAd] = ad.url
        value[platform] = ad.website
        value[idAd] = ad._id
        if (city) value[localitat] = city
        //value[date] = A IMPLEMENTAR
        //value[persona] = A IMPLEMENTAR
        if (ad.workingUser.length > 0) {
          for (let i = 0; i < ad.workingUser.length; i += 1) {
            value[workingUser] = ad.workingUser[i]
            let log = assignations.find(item => item._id.adGroup === ad.adGroupCode && item._id.user === ad.workingUser[i])
            if (log) {
              value[date] = log.date
              value[persona] = log.user
            }
            infoExcel.push(value)
          }
        }
        else {
          infoExcel.push(value)
        }
      }
    }
    infoExcel = infoExcel.sort((a, b) => {
      if (a[group] < b[group]) return -1;
      else return 1
    })
    await this.rootStore.requestStore.generateExcelForSupervisor(this.rootStore.userStore.user.username, infoExcel);
  }

  @action("Download ads that are not assigned for supervisor") downloadNoAssignedForSupervisor = async () => {
    console.log("Downloading...")
    let infoExcel = []
    let group = i18n.t("team.group")
    let idAd = i18n.t("team.idAd")
    let linkGroup = i18n.t("team.linkGroup")
    let linkAd = i18n.t("team.linkAd")
    let platform = i18n.t("team.platform")
    let localitat = i18n.t("team.localitat")
    let ads = await this.rootStore.requestStore.getAdsWithoutWorkingUser();
    for (let ad of ads) {
      if (ad !== null) {
        let value = {}
        let city;
        if (ad.regionStats) {
          let keys = Object.keys(ad.regionStats)
          city = ad.regionStats[keys[keys.length - 1]].name
        }
        value[group] = ad.adGroupCode;
        value[linkGroup] = "https://stlpro.talkandcode.com/groupAds/" + ad.adGroupCode
        value[linkAd] = ad.url
        value[platform] = ad.website
        value[idAd] = ad._id
        if (city) value[localitat] = city
        infoExcel.push(value)
      }
    }
    infoExcel = infoExcel.sort((a, b) => {
      if (a[group] < b[group]) return -1;
      else return 1
    })
    await this.rootStore.requestStore.generateExcelForSupervisor(this.rootStore.userStore.user.username, infoExcel);
  }
}

export default TeamStore;
