import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import MyButton from "../basicUI/MyButton";
import { withTranslation } from "react-i18next";
import Spinner from "react-bootstrap/Spinner";
import VirtualizedList from './VirtualizedList'
//import MyCheckbox from "../basicUI/MyCheckbox";

@inject("adGroupEditStore", "sessionStore", "navigationStore")
@observer
class AdGroupEdit extends Component {
  async componentDidMount() {
    try {
      let { t } = this.props;
      document.title = t("adGroupEdit.title");
      this.props.adGroupEditStore.initializeStore();
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  componentWillUnmount() {
    this.props.adGroupEditStore.showAlert = false;
    this.props.adGroupEditStore.confirmAlert= false;
    this.props.adGroupEditStore.initialized = null
    this.props.adGroupEditStore.myAssigned = null
    this.props.adGroupEditStore.myAssignedInitial = null
    this.props.adGroupEditStore.adGroupsInitial = null
    this.props.adGroupEditStore.adGroupSearch = ""
    this.props.adGroupEditStore.assignedAdGroupSearch = ""
    this.props.adGroupEditStore.workingUserSearch = ""
    this.props.adGroupEditStore.order = 1
    this.props.adGroupEditStore.activeSort = "adGroupCode"
    this.props.adGroupEditStore.loading = null
    this.props.adGroupEditStore.numAdsBD = 0;
  }

  isInMyAssigned = (adgroup) => {
    let ret = false;
    if (this.props.adGroupEditStore.myAssigned)
      this.props.adGroupEditStore.myAssigned.forEach((assigned) => {
        if (assigned._id === adgroup._id) {
          ret = true;
        }
      });
    return ret;
  };

  handleButtonClick = (id) => {
    this.props.adGroupEditStore.selectAdGroupToMine(id);
  };

  render() {
    const { t } = this.props;
    if (this.props.sessionStore.initialized) {
      return (
        <>
          <main role="main" className="container-fluid wre-page">
            <div
              className={
                "alert alert-danger" +
                (!!this.props.adGroupEditStore.showAlert
                  ? " alert-custom show"
                  : " alert-custom hide")
              }
            >
              <button
                type="button"
                className="close"
                onClick={() => {
                  this.props.adGroupEditStore.updateProperty(
                    "showAlert",
                    false
                  );
                }}
              >
                x
              </button>
            </div>

            <div
              className={
                "alert alert-success" +
                (!!this.props.adGroupEditStore.confirmAlert
                  ? " alert-custom show"
                  : " alert-custom hide")
              }
            >
              <p id="succes-alert-workingRegion"></p>
              <button
                type="button"
                className="close"
                onClick={() => {
                  this.props.adGroupEditStore.updateProperty(
                    "confirmAlert",
                    false
                  );
                }}
              >
                x
              </button>
            </div>
            <div className="row working-region-edit">
              <div className="col-md-4">
                <h5>{t("adGroupEdit.assigned")}</h5>
                <input
                  id="workingUserSearch"
                  className="region-inputs"
                  value={this.props.adGroupEditStore.workingUserSearch}
                  onChange={(e) => {
                    this.props.adGroupEditStore.updateProperty(
                      "workingUserSearch",
                      e.target.value
                    );
                    this.props.adGroupEditStore.getSuggestedUser()
                  }}
                  placeholder={t("adGroupEdit.findUsers")}
                ></input>
                <hr />
              </div>

              <div className="col-md-4">
                <h5>{t("adGroupEdit.all")}</h5>
                <div></div>
                <input
                  id="adGroupSearch"
                  className="region-inputs"
                  value={this.props.adGroupEditStore.adGroupSearch}
                  onChange={(e) => {
                    this.props.adGroupEditStore.updateProperty(
                      "adGroupSearch",
                      e.target.value
                    );
                  }}
                  placeholder={t("adGroupEdit.findGroups")}
                ></input>
                <hr />
              </div>
            </div>

            {this.props.adGroupEditStore.initialized ? (
              <div className="row working-region-edit">
                <div className="col-md-4 wre-list" style={{ "borderRight": "0.1rem solid", "borderColor": "#00A5AD" }}>
                  <table className="tableFixHead">
                    <thead><tr><th></th><th width="20%" onClick={(e) => { this.props.adGroupEditStore.onClickTh(e, "username"); }}>
                      {this.props.adGroupEditStore.activeSort === "username" &&
                        this.props.adGroupEditStore.order === 1 ? (
                        <svg
                          className="fas fa-angle-up adgroupcode"
                          style={{ width: "1rem", marginRight: "0.3rem" }}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                        >
                          <path
                            fill="currentColor"
                            d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"
                          ></path>
                        </svg>
                      ) : this.props.adGroupEditStore.activeSort === "username" &&
                        this.props.adGroupEditStore.order === -1 ? (
                        <svg
                          className="fas fa-angle-down adgroupcode"
                          style={{ width: "1rem", marginRight: "0.3rem" }}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 40.11 26.16"
                        >
                          <path
                            className="cls-1"
                            fill="currentColor"
                            d="M27.34,28.77a2.08,2.08,0,0,0-3,0l-4,4s-1,1,0,3L38.42,53.74s1,1,3,0L59.36,35.67a2.07,2.07,0,0,0,0-3l-4-4a2.08,2.08,0,0,0-3,0l-12,12Z"
                            transform="translate(-19.91 -28.02)"
                          />
                        </svg>
                      ) : null}{t("adGroupEdit.username")}</th>
                      <th width="20%" onClick={(e) => { this.props.adGroupEditStore.onClickTh(e, "assigned"); }}>
                        {this.props.adGroupEditStore.activeSort === "assigned" &&
                          this.props.adGroupEditStore.order === 1 ? (
                          <svg
                            className="fas fa-angle-up adgroupcode"
                            style={{ width: "1rem", marginRight: "0.3rem" }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path
                              fill="currentColor"
                              d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"
                            ></path>
                          </svg>
                        ) : this.props.adGroupEditStore.activeSort === "assigned" &&
                          this.props.adGroupEditStore.order === -1 ? (
                          <svg
                            className="fas fa-angle-down adgroupcode"
                            style={{ width: "1rem", marginRight: "0.3rem" }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 40.11 26.16"
                          >
                            <path
                              className="cls-1"
                              fill="currentColor"
                              d="M27.34,28.77a2.08,2.08,0,0,0-3,0l-4,4s-1,1,0,3L38.42,53.74s1,1,3,0L59.36,35.67a2.07,2.07,0,0,0,0-3l-4-4a2.08,2.08,0,0,0-3,0l-12,12Z"
                              transform="translate(-19.91 -28.02)"
                            />
                          </svg>
                        ) : null}
                        {t("adGroupEdit.numAssigned")}</th>
                      <th width="20%" onClick={(e) => { this.props.adGroupEditStore.onClickTh(e, "migrated"); }}>
                        {this.props.adGroupEditStore.activeSort === "migrated" &&
                          this.props.adGroupEditStore.order === 1 ? (
                          <svg
                            className="fas fa-angle-up adgroupcode"
                            style={{ width: "1rem", marginRight: "0.3rem" }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path
                              fill="currentColor"
                              d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"
                            ></path>
                          </svg>
                        ) : this.props.adGroupEditStore.activeSort === "migrated" &&
                          this.props.adGroupEditStore.order === -1 ? (
                          <svg
                            className="fas fa-angle-down adgroupcode"
                            style={{ width: "1rem", marginRight: "0.3rem" }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 40.11 26.16"
                          >
                            <path
                              className="cls-1"
                              fill="currentColor"
                              d="M27.34,28.77a2.08,2.08,0,0,0-3,0l-4,4s-1,1,0,3L38.42,53.74s1,1,3,0L59.36,35.67a2.07,2.07,0,0,0,0-3l-4-4a2.08,2.08,0,0,0-3,0l-12,12Z"
                              transform="translate(-19.91 -28.02)"
                            />
                          </svg>
                        ) : null}
                        {t("adGroupEdit.migrated")}</th>
                      <th width="20%" onClick={(e) => { this.props.adGroupEditStore.onClickTh(e, "tocheck"); }}>
                        {this.props.adGroupEditStore.activeSort === "tocheck" &&
                          this.props.adGroupEditStore.order === 1 ? (
                          <svg
                            className="fas fa-angle-up adgroupcode"
                            style={{ width: "1rem", marginRight: "0.3rem" }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path
                              fill="currentColor"
                              d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"
                            ></path>
                          </svg>
                        ) : this.props.adGroupEditStore.activeSort === "tocheck" &&
                          this.props.adGroupEditStore.order === -1 ? (
                          <svg
                            className="fas fa-angle-down adgroupcode"
                            style={{ width: "1rem", marginRight: "0.3rem" }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 40.11 26.16"
                          >
                            <path
                              className="cls-1"
                              fill="currentColor"
                              d="M27.34,28.77a2.08,2.08,0,0,0-3,0l-4,4s-1,1,0,3L38.42,53.74s1,1,3,0L59.36,35.67a2.07,2.07,0,0,0,0-3l-4-4a2.08,2.08,0,0,0-3,0l-12,12Z"
                              transform="translate(-19.91 -28.02)"
                            />
                          </svg>
                        ) : null}
                        {t("adGroupEdit.active")}</th>
                      <th width="20%" onClick={(e) => { this.props.adGroupEditStore.onClickTh(e, "checked"); }}>
                        {this.props.adGroupEditStore.activeSort === "checked" &&
                          this.props.adGroupEditStore.order === 1 ? (
                          <svg
                            className="fas fa-angle-up adgroupcode"
                            style={{ width: "1rem", marginRight: "0.3rem" }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path
                              fill="currentColor"
                              d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"
                            ></path>
                          </svg>
                        ) : this.props.adGroupEditStore.activeSort === "checked" &&
                          this.props.adGroupEditStore.order === -1 ? (
                          <svg
                            className="fas fa-angle-down adgroupcode"
                            style={{ width: "1rem", marginRight: "0.3rem" }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 40.11 26.16"
                          >
                            <path
                              className="cls-1"
                              fill="currentColor"
                              d="M27.34,28.77a2.08,2.08,0,0,0-3,0l-4,4s-1,1,0,3L38.42,53.74s1,1,3,0L59.36,35.67a2.07,2.07,0,0,0,0-3l-4-4a2.08,2.08,0,0,0-3,0l-12,12Z"
                              transform="translate(-19.91 -28.02)"
                            />
                          </svg>
                        ) : null}
                        {t("adGroupEdit.closed")}</th></tr></thead>
                    {this.props.adGroupEditStore.users.map(item => {
                      return (
                        <tbody key={item._id} className="lists-div">
                          <tr><td><input type="radio" name="users" value={item.username} onClick={() => this.props.adGroupEditStore.changeUserSelected(item)}></input></td>
                            <td>{item.username}</td><td>{item.count}</td><td>{item.migrated}</td><td>{item.inProcess}</td><td>{item.checked}</td></tr>
                          {(this.props.adGroupEditStore.selectedUser && this.props.adGroupEditStore.selectedUser._id === item._id) ?
                            (
                              <tr><td colSpan="10">
                                <table>
                                  <thead><tr><th width="10%"></th><th width="25" onClick={(e) => {
                                    this.props.adGroupEditStore.onClickTh(e, "adGroupCode");
                                  }}>{t("adGroupEdit.adGroupCode")}</th><th width="20%" onClick={(e) => {
                                    this.props.adGroupEditStore.onClickTh(e, "numAds");
                                  }}>{t("adGroupEdit.numAds")}</th><th width="25%" onClick={(e) => {
                                    this.props.adGroupEditStore.onClickTh(e, "city");
                                  }}>{t("adGroupEdit.city")}</th><th width="20%" onClick={(e) => {
                                    this.props.adGroupEditStore.onClickTh(e, "roomType");
                                  }}>{t("adGroupEdit.roomType")}</th></tr></thead>
                                  <tbody>
                                    {!this.props.adGroupEditStore.loading && this.props.adGroupEditStore.myAssigned
                                      ? this.props.adGroupEditStore.myAssigned.map((adgroup) => {
                                        return (
                                          <tr key={adgroup._id}><td>
                                            <MyButton
                                              type="button"
                                              text="-"
                                              className="btn btn-outline-danger btn-dele"
                                              id={adgroup._id}
                                              onClick={(e) => {
                                                this.props.adGroupEditStore.deselectGroup(
                                                  e.target
                                                );
                                              }}
                                            ></MyButton></td>
                                            <td>{adgroup._id}</td><td>{adgroup.childrenId.length}</td><td>{adgroup.municipality ? adgroup.municipality : adgroup.municipi}</td><td>{adgroup.roomType}</td></tr>
                                        );
                                      })
                                      : <tr><td colSpan="3"><div className="SpinnerDiv" style={{ position: "relative", top: "50%", left: "50%" }}>
                                        <Spinner
                                          animation="border"
                                          variant="info"
                                          role="status"
                                          className="loadingSpinner"
                                        >
                                          <span className="sr-only">{t("loading")}</span>
                                        </Spinner>
                                        <p>{t("loading")}</p>
                                      </div></td></tr>}
                                  </tbody>
                                </table>
                              </td>
                              </tr>)
                            : null}
                        </tbody>
                      )
                    })}

                  </table>
                </div>
                <div className="col-md-4 wre-list">
                  <table height="10%" style={{tableLayout:"fixed", width:"90%"}}>
                    <thead>
                      <tr><th width="10%"></th><th width="25%" onClick={(e) => {
                        this.props.adGroupEditStore.onClickTh(e, "adGroupCode");
                      }}>
                        {this.props.adGroupEditStore.activeSort === "adGroupCode" &&
                          this.props.adGroupEditStore.order === 1 ? (
                          <svg
                            className="fas fa-angle-up adgroupcode"
                            style={{ width: "1rem", marginRight: "0.3rem" }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path
                              fill="currentColor"
                              d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"
                            ></path>
                          </svg>
                        ) : this.props.adGroupEditStore.activeSort === "adGroupCode" &&
                          this.props.adGroupEditStore.order === -1 ? (
                          <svg
                            className="fas fa-angle-down adgroupcode"
                            style={{ width: "1rem", marginRight: "0.3rem" }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 40.11 26.16"
                          >
                            <path
                              className="cls-1"
                              fill="currentColor"
                              d="M27.34,28.77a2.08,2.08,0,0,0-3,0l-4,4s-1,1,0,3L38.42,53.74s1,1,3,0L59.36,35.67a2.07,2.07,0,0,0,0-3l-4-4a2.08,2.08,0,0,0-3,0l-12,12Z"
                              transform="translate(-19.91 -28.02)"
                            />
                          </svg>
                        ) : null}
                        {t("adGroupEdit.adGroupCode")}</th><th width="20%" onClick={(e) => {
                          this.props.adGroupEditStore.onClickTh(e, "numAds");
                        }}>
                          {this.props.adGroupEditStore.activeSort === "numAds" &&
                            this.props.adGroupEditStore.order === 1 ? (
                            <svg
                              className="fas fa-angle-up adgroupcode"
                              style={{ width: "1rem", marginRight: "0.3rem" }}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                            >
                              <path
                                fill="currentColor"
                                d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"
                              ></path>
                            </svg>
                          ) : this.props.adGroupEditStore.activeSort === "numAds" &&
                            this.props.adGroupEditStore.order === -1 ? (
                            <svg
                              className="fas fa-angle-down adgroupcode"
                              style={{ width: "1rem", marginRight: "0.3rem" }}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 40.11 26.16"
                            >
                              <path
                                className="cls-1"
                                fill="currentColor"
                                d="M27.34,28.77a2.08,2.08,0,0,0-3,0l-4,4s-1,1,0,3L38.42,53.74s1,1,3,0L59.36,35.67a2.07,2.07,0,0,0,0-3l-4-4a2.08,2.08,0,0,0-3,0l-12,12Z"
                                transform="translate(-19.91 -28.02)"
                              />
                            </svg>
                          ) : null}
                          {t("adGroupEdit.numAds")}</th><th width="25%" onClick={(e) => {
                            this.props.adGroupEditStore.onClickTh(e, "city");
                          }}>
                          {this.props.adGroupEditStore.activeSort === "city" &&
                            this.props.adGroupEditStore.order === 1 ? (
                            <svg
                              className="fas fa-angle-up adgroupcode"
                              style={{ width: "1rem", marginRight: "0.3rem" }}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                            >
                              <path
                                fill="currentColor"
                                d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"
                              ></path>
                            </svg>
                          ) : this.props.adGroupEditStore.activeSort === "city" &&
                            this.props.adGroupEditStore.order === -1 ? (
                            <svg
                              className="fas fa-angle-down adgroupcode"
                              style={{ width: "1rem", marginRight: "0.3rem" }}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 40.11 26.16"
                            >
                              <path
                                className="cls-1"
                                fill="currentColor"
                                d="M27.34,28.77a2.08,2.08,0,0,0-3,0l-4,4s-1,1,0,3L38.42,53.74s1,1,3,0L59.36,35.67a2.07,2.07,0,0,0,0-3l-4-4a2.08,2.08,0,0,0-3,0l-12,12Z"
                                transform="translate(-19.91 -28.02)"
                              />
                            </svg>
                          ) : null}
                          {t("adGroupEdit.city")}</th><th width="20%" onClick={(e) => {
                            this.props.adGroupEditStore.onClickTh(e, "roomType");
                          }}>
                          {this.props.adGroupEditStore.activeSort === "roomType" &&
                            this.props.adGroupEditStore.order === 1 ? (
                            <svg
                              className="fas fa-angle-up adgroupcode"
                              style={{ width: "1rem", marginRight: "0.3rem" }}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                            >
                              <path
                                fill="currentColor"
                                d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"
                              ></path>
                            </svg>
                          ) : this.props.adGroupEditStore.activeSort === "roomType" &&
                            this.props.adGroupEditStore.order === -1 ? (
                            <svg
                              className="fas fa-angle-down adgroupcode"
                              style={{ width: "1rem", marginRight: "0.3rem" }}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 40.11 26.16"
                            >
                              <path
                                className="cls-1"
                                fill="currentColor"
                                d="M27.34,28.77a2.08,2.08,0,0,0-3,0l-4,4s-1,1,0,3L38.42,53.74s1,1,3,0L59.36,35.67a2.07,2.07,0,0,0,0-3l-4-4a2.08,2.08,0,0,0-3,0l-12,12Z"
                                transform="translate(-19.91 -28.02)"
                              />
                            </svg>
                          ) : null}
                          {t("adGroupEdit.roomType")}</th>
                      </tr>
                    </thead>
                  </table>
                  {!this.props.adGroupEditStore.loading ?
                    (this.props.adGroupEditStore.adgroups) ?
                      (
                        <VirtualizedList
                          adGroupEditStore={this.props.adGroupEditStore}
                          adGroups={this.props.adGroupEditStore.adgroups}
                          itemHeight={45}
                          height={600}
                          width={300}
                          onButtonClick={this.handleButtonClick}
                          isInMyAssigned={this.isInMyAssigned}
                          onClickTh={this.props.adGroupEditStore.onClickTh}
                        />
                      )
                      : null
                    : (<div className="row">
                      <div className="SpinnerDiv" style={{ position: "relative", top: "50%", left: "47%" }}>
                        <Spinner
                          animation="border"
                          variant="info"
                          role="status"
                          className="loadingSpinner"
                        >
                          <span className="sr-only">{t("loading")}</span>
                        </Spinner>
                        <p>{t("loading")}</p>
                      </div>
                    </div>)
                  }
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="SpinnerDiv" style={{ position: "relative", top: "50%", left: "47%" }}>
                  <Spinner
                    animation="border"
                    variant="info"
                    role="status"
                    className="loadingSpinner"
                  >
                    <span className="sr-only">{t("loading")}</span>
                  </Spinner>
                  <p>{t("loading")}</p>
                </div>
              </div>
            )}
          </main>
        </>
      );
    }
    return null;
  }
}

export default withTranslation()(AdGroupEdit);
