import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import MyButton from "../basicUI/MyButton";
import { withTranslation } from 'react-i18next';

@inject(
  "mapListStore",
  "navigationStore",
  "sessionStore",
  "userStore",
  "requestStore"
)
@observer
class GroupCard extends Component {
  async componentDidMount() {
  }

  componentWillUnmount() {}

  render() {
    console.log(this.props.userStore.user.productsExtra.compliance)
    const {t} = this.props;
    console.log(this.props)
    if (this.props.sessionStore.initialized) {
      return (
        <div key={this.props.ad._id + this.props.index} className="wa-ad-list">
          <div
            key={this.props.ad._id + this.props.index}
            className="second-child-div-content"
          >
            <MyButton
              className="btn btn-default goAd"
              toolPlace = "right"
              name={t("wa.tooltips.gotoAd")}
              onClick={() => this.props.navigationStore.openInNewTab(this.props.ad.url)}
              text={
                <>
                  <img
                    className="ad-platform-logo"
                    src={"/logos/" + this.props.ad.website.toLowerCase() + ".png"}
                    alt={this.props.ad.website}
                  />
                </>
              }
            />
            <p className="ad-info-message">
            <MyButton
              id=""
              type="button"
              name="Save The Proof"
              className="btn btn-default"
              text={
                  
                  <img 
                  className={
                    "stp-platform-logo"
                    }
                  src={(this.props.ad.stp && !!this.props.ad.stp.done
                      ? "/logos/stpBlack.png"
                      : "/logos/stpGrey.png")}
                  alt={
                    "Save The Proof"
                  }
                />
                }
                onClick={async () => {
                  let url = await this.props.mapListStore.getSTPLogin()
                  console.log(url)
                  this.props.navigationStore.openInNewTab(
                      url
                  )
                  //this.props.groupAdsStore.updateSTP(this.props.ad, this.props.gaIndex)
                }
              }
            />
            </p>
            <MyButton
              id={this.props.ad._id}
              onClick={() => {
                this.props.navigationStore.openInNewTab(
                  "/groupAds/" + this.props.ga
                );
              }}
              className="dropdown"
              onMouseEnter={e => {
                this.props.mapListStore.changeCircleColor(this.props.ad);
              }}
              onMouseLeave={e => {
                this.props.mapListStore.switchCircleColor("repaint");
              }}
              text={
                <>
                  <p className="ad-info-id">
                    {this.props.ad._id.substring(0, 14)}
                    {this.props.ad._id.length > 14 ? "..." : null}
                  </p>
                  <p className="ad-info-license-code">
                    {this.props.ad.license && this.props.ad.license.code ? (
                      <>
                        {this.props.ad.license.code.substring(0, 14)}
                        {this.props.ad.license.code.length > 14 ? "..." : null}
                      </>
                    ) : (
                      "-"
                    )}
                  </p>
                  <p className="ad-info-license-status">
                    {
                      this.props.userStore.user.productsExtra.compliance ?
                        this.props.ad.license && this.props.ad.license.compliance_status?
                          this.props.ad.license.compliance_status === "exc" || this.props.ad.license.compliance_status === "Correcto" ? t("compliance.exc") :
                          this.props.ad.license.compliance_status === "hig" || this.props.ad.license.compliance_status === "Leve" ? t("compliance.hig") : 
                          this.props.ad.license.compliance_status === "mid" ? t("compliance.mid"): 
                          this.props.ad.license.compliance_status === "low" || this.props.ad.license.compliance_status === "Posible Fraude" ? t("compliance.low"): 
                          this.props.ad.license.compliance_status === "def" || this.props.ad.license.compliance_status === "Fraude" ? t("compliance.def") : "-"
                          : "-"
                      : this.props.ad.license ?
                        this.props.ad.license.status === "noff"
                      ? t("wa.noOfficial")
                      : this.props.ad.license.status === "offi"
                      ? t("wa.official")
                      : this.props.ad.license.status === "bfor"
                      ? t("wa.badFormat")
                      : this.props.ad.license.status === "noli"
                      ? t("wa.noLicense")
                      : "-"
                      :"-"
                      
                    }
                  </p>
                  <p className="ad-info-roomtype">
                    {this.props.ad.roomType ? this.props.ad.roomType.substring(0, 12) : '-'}
                    {this.props.ad.roomType && this.props.ad.roomType.length > 12 ? "..." : null}
                  </p>
                  <p className="ad-info-occupancy">
                    {this.props.ad.occupancy ? this.props.ad.occupancy.numCurrentYearReservations : '-'}
                  </p>
                  <p className="ad-info-city">
                    {this.props.ad.city ? this.props.ad.city.substring(0, 12) : '-'}
                    {this.props.ad.city && this.props.ad.city.length > 12 ? "..." : null}
                  </p>
                 
                  
                </>
              }
            />
          </div>
        </div>
      );
    }
  }
}

export default withTranslation()(GroupCard);