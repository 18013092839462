import React, { Component } from "react";
import { observer, inject } from "mobx-react";
// import MyButton from "../basicUI/MyButton";
import MySelect from "../basicUI/MySelect";
import MyInput from "../basicUI/MyInput";
import MySuggestions from "../Search/MySuggestions";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import {
  BarChart,
  Bar,
  //Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell
  //LabelList,
} from "recharts";

@inject("activityStore", "sessionStore", "navigationStore", "pageStore", "userStore")
@observer
class Activity extends Component {
  async componentDidMount() {
    try {
      let { t } = this.props;
      document.title = "STLPRO - " + t("activity.activity");
      this.props.activityStore.updateProperty("superuser", this.props.userStore.getUser().isSuperuser)
      this.props.activityStore.createOptions();
      await this.props.activityStore.initializeStore();
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  async componentDidUpdate() {
    try {
      if (
        this.props.navigationStore.history.location.pathname === "/activity" &&
        this.props.navigationStore.history.location.search === ""
      ) {
        //console.log(this.props.activityStore.url);
        if (this.props.activityStore.url) {
          this.props.navigationStore.reload();
        }
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  componentWillUnmount() {
    this.props.activityStore.updateNestedProperty(
      "startDayFilter.toOption",
      "-1"
    );
    this.props.activityStore.updateNestedProperty(
      "startMonthFilter.toOption",
      "-1"
    );
    this.props.activityStore.updateNestedProperty(
      "startYearFilter.toOption",
      "-1"
    );
    this.props.activityStore.updateNestedProperty(
      "endDayFilter.toOption",
      "-1"
    );
    this.props.activityStore.updateNestedProperty(
      "endMonthFilter.toOption",
      "-1"
    );
    this.props.activityStore.updateNestedProperty(
      "endYearFilter.toOption",
      "-1"
    );
    this.props.activityStore.updateNestedProperty(
      "usersFilters.toOption",
      "-1"
    );
    this.props.activityStore.updateProperty("adFilter", "");
    this.props.activityStore.updateNestedProperty(
      "messageFilter.toOption",
      "-1"
    );
    this.props.activityStore.updateNestedProperty(
      "pageFilter.toOption",
      "-1"
    );
    this.props.activityStore.updateProperty("visibleFilter", "");
    this.props.activityStore.updateProperty("url", "");
    this.props.activityStore.updateProperty("logs", []);
    this.props.activityStore.updateNestedProperty(
      "usersFilters.selectOptions",
      []
    );
  }

  render() {
    const { t } = this.props;
    if (this.props.sessionStore.initialized) {
      return (
        <main role="main" className="container-fluid main">
          <div className="row">
            <div className="col-md-10 activitat-filters">
              <div className="row filter-content">
                <div className="col-md-12" name="filter-btn">
                  <div className="div-content">
                    <button
                      id="flt-date"
                      className="btn btn-outline-info flt-date"
                      onClick={() => {
                        this.props.activityStore.updateVisibleFilter(
                          "StartDate"
                        );
                      }}
                      type="button"
                    >
                      {t("activity.date")}
                    </button>
                    <div
                      id="date-form"
                      className={
                        this.props.activityStore.visibleFilter === "StartDate"
                          ? "card date-form display-block"
                          : "card date-form display-none"
                      }
                    >
                      <form>
                        <div className="row"><label>{t("activity.start")}</label></div>
                        <div className="row">
                          <input type="date"
                            onChange={(e) => {
                              if (e.target.value !== "") {
                                let date = e.target.value.split("-")
                                this.props.activityStore.updateNestedProperty("startDayFilter.toOption", date[2])
                                this.props.activityStore.updateNestedProperty("startMonthFilter.toOption", date[1])
                                this.props.activityStore.updateNestedProperty("startYearFilter.toOption", date[0])
                              }
                              else {
                                this.props.activityStore.updateNestedProperty("startDayFilter.toOption", "-1")
                                this.props.activityStore.updateNestedProperty("startMonthFilter.toOption", "-1")
                                this.props.activityStore.updateNestedProperty("startYearFilter.toOption", "-1")
                              }
                              this.props.activityStore.saveFilters(false, true);
                            }}>
                          </input>
                        </div>
                        <div className="row"><label>{t("activity.end")}</label></div>
                        <div className="row">
                          <input type="date"
                            onChange={(e) => {
                              if (e.target.value !== "") {
                                let date = e.target.value.split("-")
                                this.props.activityStore.updateNestedProperty("endDayFilter.toOption", date[2])
                                this.props.activityStore.updateNestedProperty("endMonthFilter.toOption", date[1])
                                this.props.activityStore.updateNestedProperty("endYearFilter.toOption", date[0])
                              }
                              else {
                                this.props.activityStore.updateNestedProperty("endDayFilter.toOption", "-1")
                                this.props.activityStore.updateNestedProperty("endMonthFilter.toOption", "-1")
                                this.props.activityStore.updateNestedProperty("endYearFilter.toOption", "-1")
                              }
                              this.props.activityStore.saveFilters(false, true);
                            }}>
                          </input>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="div-content">
                    <button
                      id="flt-user"
                      className="btn btn-outline-info flt-user"
                      onClick={(e) => {
                        this.props.activityStore.updateVisibleFilter("User");
                      }}
                      type="button"
                    >
                      {t("activity.user")}
                    </button>
                    <div
                      id="user-form"
                      className={
                        this.props.activityStore.visibleFilter === "User"
                          ? "card user-form display-block"
                          : "card user-form display-none"
                      }
                    >
                      <form>
                        <div className="row"><label>{t("activity.user")}</label></div>
                        <div className="row">
                          <MySelect
                            selectName="usersFilters.toOption"
                            selectClass="select"
                            selectedOption={
                              this.props.activityStore.usersFilters.toOption
                            }
                            onChange={(name, value) => {
                              this.props.activityStore.updateNestedProperty(name, value);
                              this.props.activityStore.saveFilters(false, true);
                            }
                            }
                            selectOptions={
                              this.props.activityStore.usersFilters.selectOptions
                            }
                          /></div>
                      </form>
                      <hr />
                      <button
                        id="button-filter-delete-user"
                        className="btn btn-outline-danger"
                        onClick={() => {
                          this.props.activityStore.deleteFilter("user");
                        }}
                      >
                        <svg
                          className="svg-icon-trash-filter-activity"
                          data-name="fas fa-trash-alt"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 55.93 63.79"
                        >
                          <path
                            className="cls-1"
                            d="M60,71.88a8.89,8.89,0,0,1-2.81.31h-35A5.89,5.89,0,0,1,16.9,69.3a9.68,9.68,0,0,1-.53-1.07l-.23-.72a9.8,9.8,0,0,1-.09-1.33c0-.65,0-1.3,0-2,0-1.33,0-2.67,0-4s0-2.62,0-3.93,0-2.68,0-4v-4c0-1.34,0-2.67,0-4s0-2.62,0-3.93,0-2.68,0-4v-4c0-1.31,0-2.63,0-3.94s0-2.68,0-4l3.95,0q2,0,4,0h4q2,0,4,0H36q2,0,4,0h4c1.32,0,2.63,0,4,0s2.67,0,4,0,2.68,0,4,0,2.67,0,4,0h4c0,1.34,0,2.68,0,4s0,2.63,0,3.94,0,2.67,0,4v4c0,1.32,0,2.63,0,3.94s0,2.67,0,4,0,2.67,0,4,0,2.67,0,4,0,2.63,0,3.95v4l0,2.07A5.9,5.9,0,0,1,61.61,71,8.49,8.49,0,0,1,60,71.88ZM40,32.34a2,2,0,0,0-2,2.09c0,.63,0,1.26,0,1.89v23.9c0,.72,0,1.43,0,2.14a2,2,0,0,0,3.94,0c0-.71,0-1.42,0-2.14V36.32c0-.68,0-1.36,0-2A1.93,1.93,0,0,0,40,32.34Zm12,0a2,2,0,0,0-2,2c0,.67,0,1.33,0,2v23.9c0,.71,0,1.41,0,2.11a2,2,0,0,0,3.92.35,9.64,9.64,0,0,0,0-2.46V56.29a.74.74,0,0,0,0-.39c0-.95,0-1.89,0-2.83a2.32,2.32,0,0,0,0-.79v-4a.74.74,0,0,0,0-.39c0-.92,0-1.84,0-2.76a2.75,2.75,0,0,0,0-.86V40.33a.75.75,0,0,0,0-.39c0-.95,0-1.89,0-2.84a2.34,2.34,0,0,0,0-.79c0-.63,0-1.26,0-1.89A2,2,0,0,0,52,32.34ZM28,64.22a2,2,0,0,0,2-1.89c0-.7,0-1.4,0-2.11V36.32c0-.63,0-1.26,0-1.89a2,2,0,0,0-2-2.09,1.94,1.94,0,0,0-2,1.53,9.13,9.13,0,0,0,0,2.45v4a.75.75,0,0,0,0,.39c0,.92,0,1.84,0,2.76a2.32,2.32,0,0,0,0,.79v4a.74.74,0,0,0,0,.39c0,.94,0,1.89,0,2.83a2.34,2.34,0,0,0,0,.79,20.93,20.93,0,0,0,0,2.24,14.94,14.94,0,0,0,0,1.77c-.08,1,0,2.07,0,3.11a2.58,2.58,0,0,0,0,.83c0,.71,0,1.42,0,2.13A1.93,1.93,0,0,0,28,64.22Z"
                            transform="translate(-12.03 -8.4)"
                          />
                          <path
                            className="cls-1"
                            d="M48,8.52a3.47,3.47,0,0,1,2,2c.23.51.51,1,.74,1.5a.5.5,0,0,0,.58.35,5.63,5.63,0,0,1,.58,0h4c1.34,0,2.68,0,4,0h3.94l1.84,0a3,3,0,0,1,.79.08A2.2,2.2,0,0,1,67.94,14l0,2.38c0,.13,0,.26,0,.39A13.86,13.86,0,0,1,67.88,19a2.39,2.39,0,0,1-1.31,1.32,3.81,3.81,0,0,1-.75.07H16.06l-2.45,0a2.26,2.26,0,0,1-1.5-1.45c0-.23-.06-.45-.06-.68,0-.62,0-1.25,0-1.88l0-2.43a2.4,2.4,0,0,1,1.29-1.43,14.74,14.74,0,0,1,2.3-.08l.4,0c1.31,0,2.63,0,3.94,0h4q2,0,4,0c1,.07,1,.07,1.37-.75.25-.51.5-1,.77-1.53A3,3,0,0,1,33,8.4H47A4.29,4.29,0,0,1,48,8.52Z"
                            transform="translate(-12.03 -8.4)"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div className="div-content">
                    <button
                      id="flt-ad"
                      className="btn btn-outline-info flt-ad"
                      onClick={() => {
                        this.props.activityStore.updateVisibleFilter("Ad");
                      }}
                      type="button"
                    >
                      {t("activity.ad")}
                    </button>
                    <div
                      id="ad-form"
                      className={
                        this.props.activityStore.visibleFilter === "Ad"
                          ? "card ad-form display-block"
                          : "card ad-form display-none"
                      }
                    >
                      <form>
                        <div className="row">
                          <MyInput
                            onChange={(key, value) => {
                              this.props.activityStore.updateProperty("adFilter", value);
                              this.props.activityStore.saveFilters(false, true);
                            }}
                            value={this.props.activityStore.adFilter}
                            name={t("activity.ad")}
                            valid="true"
                            type="text"
                            className="form-control"
                            id="adFilter"
                            placeholder={t("activity.ad")}
                          />
                          {this.props.activityStore.suggestions &&
                            !this.props.activityStore.selectedSuggestion && (
                              <div
                                className={
                                  this.props.activityStore.adFilter
                                    ? "suggestions display-block"
                                    : "suggestions display-none"
                                }
                              >
                                <div
                                  className={
                                    this.props.activityStore.adFilter
                                      ? "card display-block"
                                      : "card display-none"
                                  }
                                  style={{ zIndex: "10000" }}
                                >
                                  <MySuggestions
                                    searchOption={"ad_id"}
                                    suggestions={
                                      this.props.activityStore.suggestions
                                    }
                                    fieldsToShow={["id"]}
                                    onClick={(id, name, adm, elem) => {
                                      this.props.activityStore.clickSuggestionAd(id, name, adm, elem);
                                      this.props.activityStore.saveFilters(false, true);
                                    }
                                    }
                                  />
                                </div>
                              </div>
                            )}</div>
                      </form>
                      <hr />
                      <button
                        id="button-filter-delete-adId"
                        className="btn btn-outline-danger"
                        onClick={() => {
                          this.props.activityStore.deleteFilter("ad");
                        }}
                      >
                        <svg
                          className="svg-icon-trash-filter-activity"
                          data-name="fas fa-trash-alt"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 55.93 63.79"
                        >
                          <path
                            className="cls-1"
                            d="M60,71.88a8.89,8.89,0,0,1-2.81.31h-35A5.89,5.89,0,0,1,16.9,69.3a9.68,9.68,0,0,1-.53-1.07l-.23-.72a9.8,9.8,0,0,1-.09-1.33c0-.65,0-1.3,0-2,0-1.33,0-2.67,0-4s0-2.62,0-3.93,0-2.68,0-4v-4c0-1.34,0-2.67,0-4s0-2.62,0-3.93,0-2.68,0-4v-4c0-1.31,0-2.63,0-3.94s0-2.68,0-4l3.95,0q2,0,4,0h4q2,0,4,0H36q2,0,4,0h4c1.32,0,2.63,0,4,0s2.67,0,4,0,2.68,0,4,0,2.67,0,4,0h4c0,1.34,0,2.68,0,4s0,2.63,0,3.94,0,2.67,0,4v4c0,1.32,0,2.63,0,3.94s0,2.67,0,4,0,2.67,0,4,0,2.67,0,4,0,2.63,0,3.95v4l0,2.07A5.9,5.9,0,0,1,61.61,71,8.49,8.49,0,0,1,60,71.88ZM40,32.34a2,2,0,0,0-2,2.09c0,.63,0,1.26,0,1.89v23.9c0,.72,0,1.43,0,2.14a2,2,0,0,0,3.94,0c0-.71,0-1.42,0-2.14V36.32c0-.68,0-1.36,0-2A1.93,1.93,0,0,0,40,32.34Zm12,0a2,2,0,0,0-2,2c0,.67,0,1.33,0,2v23.9c0,.71,0,1.41,0,2.11a2,2,0,0,0,3.92.35,9.64,9.64,0,0,0,0-2.46V56.29a.74.74,0,0,0,0-.39c0-.95,0-1.89,0-2.83a2.32,2.32,0,0,0,0-.79v-4a.74.74,0,0,0,0-.39c0-.92,0-1.84,0-2.76a2.75,2.75,0,0,0,0-.86V40.33a.75.75,0,0,0,0-.39c0-.95,0-1.89,0-2.84a2.34,2.34,0,0,0,0-.79c0-.63,0-1.26,0-1.89A2,2,0,0,0,52,32.34ZM28,64.22a2,2,0,0,0,2-1.89c0-.7,0-1.4,0-2.11V36.32c0-.63,0-1.26,0-1.89a2,2,0,0,0-2-2.09,1.94,1.94,0,0,0-2,1.53,9.13,9.13,0,0,0,0,2.45v4a.75.75,0,0,0,0,.39c0,.92,0,1.84,0,2.76a2.32,2.32,0,0,0,0,.79v4a.74.74,0,0,0,0,.39c0,.94,0,1.89,0,2.83a2.34,2.34,0,0,0,0,.79,20.93,20.93,0,0,0,0,2.24,14.94,14.94,0,0,0,0,1.77c-.08,1,0,2.07,0,3.11a2.58,2.58,0,0,0,0,.83c0,.71,0,1.42,0,2.13A1.93,1.93,0,0,0,28,64.22Z"
                            transform="translate(-12.03 -8.4)"
                          />
                          <path
                            className="cls-1"
                            d="M48,8.52a3.47,3.47,0,0,1,2,2c.23.51.51,1,.74,1.5a.5.5,0,0,0,.58.35,5.63,5.63,0,0,1,.58,0h4c1.34,0,2.68,0,4,0h3.94l1.84,0a3,3,0,0,1,.79.08A2.2,2.2,0,0,1,67.94,14l0,2.38c0,.13,0,.26,0,.39A13.86,13.86,0,0,1,67.88,19a2.39,2.39,0,0,1-1.31,1.32,3.81,3.81,0,0,1-.75.07H16.06l-2.45,0a2.26,2.26,0,0,1-1.5-1.45c0-.23-.06-.45-.06-.68,0-.62,0-1.25,0-1.88l0-2.43a2.4,2.4,0,0,1,1.29-1.43,14.74,14.74,0,0,1,2.3-.08l.4,0c1.31,0,2.63,0,3.94,0h4q2,0,4,0c1,.07,1,.07,1.37-.75.25-.51.5-1,.77-1.53A3,3,0,0,1,33,8.4H47A4.29,4.29,0,0,1,48,8.52Z"
                            transform="translate(-12.03 -8.4)"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div className="div-content">
                    <button
                      id="flt-ad"
                      className="btn btn-outline-info flt-ad"
                      onClick={() => {
                        this.props.activityStore.updateVisibleFilter("AdGroup");
                      }}
                      type="button"
                    >
                      {t("activity.adGroup")}
                    </button>
                    <div
                      id="adGroup-form"
                      className={
                        this.props.activityStore.visibleFilter === "AdGroup"
                          ? "card ad-form display-block"
                          : "card ad-form display-none"
                      }
                    >
                      <form>
                        <div className="row">
                          <MyInput
                            onChange={(key, value) => {
                              this.props.activityStore.updateProperty("adGroupFilter", value);
                            }}
                            value={this.props.activityStore.adGroupFilter}
                            name={t("activity.adGroup")}
                            valid="true"
                            type="text"
                            className="form-control"
                            id="adGroupFilter"
                            placeholder={t("activity.adGroup")}
                          />
                          {this.props.activityStore.suggestions &&
                            !this.props.activityStore.selectedSuggestion && (
                              <div
                                className={
                                  this.props.activityStore.adGroupFilter
                                    ? "suggestions display-block"
                                    : "suggestions display-none"
                                }
                              >
                                <div
                                  className={
                                    this.props.activityStore.adGroupFilter
                                      ? "card display-block"
                                      : "card display-none"
                                  }
                                  style={{ zIndex: "10000" }}
                                >
                                  <MySuggestions
                                    searchOption={"groupad_id"}
                                    suggestions={
                                      this.props.activityStore.suggestions
                                    }
                                    fieldsToShow={["_id"]}
                                    onClick={(id, name, adm, elem, _id) => {
                                      this.props.activityStore.clickSuggestionAdGroup(id, name, adm, elem, _id);
                                      this.props.activityStore.saveFilters(false, true);
                                    }
                                    }
                                  />
                                </div>
                              </div>
                            )}</div>
                      </form>
                      <hr />
                      <button
                        id="button-filter-delete-adGroupId"
                        className="btn btn-outline-danger"
                        onClick={() => {
                          this.props.activityStore.deleteFilter("adGroup");
                        }}
                      >
                        <svg
                          className="svg-icon-trash-filter-activity"
                          data-name="fas fa-trash-alt"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 55.93 63.79"
                        >
                          <path
                            className="cls-1"
                            d="M60,71.88a8.89,8.89,0,0,1-2.81.31h-35A5.89,5.89,0,0,1,16.9,69.3a9.68,9.68,0,0,1-.53-1.07l-.23-.72a9.8,9.8,0,0,1-.09-1.33c0-.65,0-1.3,0-2,0-1.33,0-2.67,0-4s0-2.62,0-3.93,0-2.68,0-4v-4c0-1.34,0-2.67,0-4s0-2.62,0-3.93,0-2.68,0-4v-4c0-1.31,0-2.63,0-3.94s0-2.68,0-4l3.95,0q2,0,4,0h4q2,0,4,0H36q2,0,4,0h4c1.32,0,2.63,0,4,0s2.67,0,4,0,2.68,0,4,0,2.67,0,4,0h4c0,1.34,0,2.68,0,4s0,2.63,0,3.94,0,2.67,0,4v4c0,1.32,0,2.63,0,3.94s0,2.67,0,4,0,2.67,0,4,0,2.67,0,4,0,2.63,0,3.95v4l0,2.07A5.9,5.9,0,0,1,61.61,71,8.49,8.49,0,0,1,60,71.88ZM40,32.34a2,2,0,0,0-2,2.09c0,.63,0,1.26,0,1.89v23.9c0,.72,0,1.43,0,2.14a2,2,0,0,0,3.94,0c0-.71,0-1.42,0-2.14V36.32c0-.68,0-1.36,0-2A1.93,1.93,0,0,0,40,32.34Zm12,0a2,2,0,0,0-2,2c0,.67,0,1.33,0,2v23.9c0,.71,0,1.41,0,2.11a2,2,0,0,0,3.92.35,9.64,9.64,0,0,0,0-2.46V56.29a.74.74,0,0,0,0-.39c0-.95,0-1.89,0-2.83a2.32,2.32,0,0,0,0-.79v-4a.74.74,0,0,0,0-.39c0-.92,0-1.84,0-2.76a2.75,2.75,0,0,0,0-.86V40.33a.75.75,0,0,0,0-.39c0-.95,0-1.89,0-2.84a2.34,2.34,0,0,0,0-.79c0-.63,0-1.26,0-1.89A2,2,0,0,0,52,32.34ZM28,64.22a2,2,0,0,0,2-1.89c0-.7,0-1.4,0-2.11V36.32c0-.63,0-1.26,0-1.89a2,2,0,0,0-2-2.09,1.94,1.94,0,0,0-2,1.53,9.13,9.13,0,0,0,0,2.45v4a.75.75,0,0,0,0,.39c0,.92,0,1.84,0,2.76a2.32,2.32,0,0,0,0,.79v4a.74.74,0,0,0,0,.39c0,.94,0,1.89,0,2.83a2.34,2.34,0,0,0,0,.79,20.93,20.93,0,0,0,0,2.24,14.94,14.94,0,0,0,0,1.77c-.08,1,0,2.07,0,3.11a2.58,2.58,0,0,0,0,.83c0,.71,0,1.42,0,2.13A1.93,1.93,0,0,0,28,64.22Z"
                            transform="translate(-12.03 -8.4)"
                          />
                          <path
                            className="cls-1"
                            d="M48,8.52a3.47,3.47,0,0,1,2,2c.23.51.51,1,.74,1.5a.5.5,0,0,0,.58.35,5.63,5.63,0,0,1,.58,0h4c1.34,0,2.68,0,4,0h3.94l1.84,0a3,3,0,0,1,.79.08A2.2,2.2,0,0,1,67.94,14l0,2.38c0,.13,0,.26,0,.39A13.86,13.86,0,0,1,67.88,19a2.39,2.39,0,0,1-1.31,1.32,3.81,3.81,0,0,1-.75.07H16.06l-2.45,0a2.26,2.26,0,0,1-1.5-1.45c0-.23-.06-.45-.06-.68,0-.62,0-1.25,0-1.88l0-2.43a2.4,2.4,0,0,1,1.29-1.43,14.74,14.74,0,0,1,2.3-.08l.4,0c1.31,0,2.63,0,3.94,0h4q2,0,4,0c1,.07,1,.07,1.37-.75.25-.51.5-1,.77-1.53A3,3,0,0,1,33,8.4H47A4.29,4.29,0,0,1,48,8.52Z"
                            transform="translate(-12.03 -8.4)"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  {this.props.activityStore.superuser ?
                    <div className="div-content">
                      <button
                        id="flt-message"
                        className="btn btn-outline-info flt-message"
                        onClick={() => {
                          this.props.activityStore.updateVisibleFilter("Page");
                        }}
                        type="button"
                      >
                        {t("activity.page")}
                      </button>
                      <div
                        id="message-form"
                        className={
                          this.props.activityStore.visibleFilter === "Page"
                            ? "card message-form display-block"
                            : "card message-form display-none"
                        }
                      >
                        <form>
                          <div className="row">
                            <label>{t("activity.page")}</label>
                            <MySelect
                              selectName="pageFilter.toOption"
                              selectClass="select"
                              selectedOption={
                                this.props.activityStore.pageFilter.toOption
                              }
                              onChange={(name, value) => {
                                this.props.activityStore.updateNestedProperty(name, value)
                                this.props.activityStore.saveFilters(false, true);
                              }
                              }
                              selectOptions={
                                this.props.activityStore.pageFilter.selectOptions
                              }
                            /></div>
                        </form>
                        <hr />
                        <button
                          id="button-filter-delete-message"
                          className="btn btn-outline-danger"
                          onClick={() => {
                            this.props.activityStore.deleteFilter("page");
                          }}
                        >
                          <svg
                            className="svg-icon-trash-filter-activity"
                            data-name="fas fa-trash-alt"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 55.93 63.79"
                          >
                            <path
                              className="cls-1"
                              d="M60,71.88a8.89,8.89,0,0,1-2.81.31h-35A5.89,5.89,0,0,1,16.9,69.3a9.68,9.68,0,0,1-.53-1.07l-.23-.72a9.8,9.8,0,0,1-.09-1.33c0-.65,0-1.3,0-2,0-1.33,0-2.67,0-4s0-2.62,0-3.93,0-2.68,0-4v-4c0-1.34,0-2.67,0-4s0-2.62,0-3.93,0-2.68,0-4v-4c0-1.31,0-2.63,0-3.94s0-2.68,0-4l3.95,0q2,0,4,0h4q2,0,4,0H36q2,0,4,0h4c1.32,0,2.63,0,4,0s2.67,0,4,0,2.68,0,4,0,2.67,0,4,0h4c0,1.34,0,2.68,0,4s0,2.63,0,3.94,0,2.67,0,4v4c0,1.32,0,2.63,0,3.94s0,2.67,0,4,0,2.67,0,4,0,2.67,0,4,0,2.63,0,3.95v4l0,2.07A5.9,5.9,0,0,1,61.61,71,8.49,8.49,0,0,1,60,71.88ZM40,32.34a2,2,0,0,0-2,2.09c0,.63,0,1.26,0,1.89v23.9c0,.72,0,1.43,0,2.14a2,2,0,0,0,3.94,0c0-.71,0-1.42,0-2.14V36.32c0-.68,0-1.36,0-2A1.93,1.93,0,0,0,40,32.34Zm12,0a2,2,0,0,0-2,2c0,.67,0,1.33,0,2v23.9c0,.71,0,1.41,0,2.11a2,2,0,0,0,3.92.35,9.64,9.64,0,0,0,0-2.46V56.29a.74.74,0,0,0,0-.39c0-.95,0-1.89,0-2.83a2.32,2.32,0,0,0,0-.79v-4a.74.74,0,0,0,0-.39c0-.92,0-1.84,0-2.76a2.75,2.75,0,0,0,0-.86V40.33a.75.75,0,0,0,0-.39c0-.95,0-1.89,0-2.84a2.34,2.34,0,0,0,0-.79c0-.63,0-1.26,0-1.89A2,2,0,0,0,52,32.34ZM28,64.22a2,2,0,0,0,2-1.89c0-.7,0-1.4,0-2.11V36.32c0-.63,0-1.26,0-1.89a2,2,0,0,0-2-2.09,1.94,1.94,0,0,0-2,1.53,9.13,9.13,0,0,0,0,2.45v4a.75.75,0,0,0,0,.39c0,.92,0,1.84,0,2.76a2.32,2.32,0,0,0,0,.79v4a.74.74,0,0,0,0,.39c0,.94,0,1.89,0,2.83a2.34,2.34,0,0,0,0,.79,20.93,20.93,0,0,0,0,2.24,14.94,14.94,0,0,0,0,1.77c-.08,1,0,2.07,0,3.11a2.58,2.58,0,0,0,0,.83c0,.71,0,1.42,0,2.13A1.93,1.93,0,0,0,28,64.22Z"
                              transform="translate(-12.03 -8.4)"
                            />
                            <path
                              className="cls-1"
                              d="M48,8.52a3.47,3.47,0,0,1,2,2c.23.51.51,1,.74,1.5a.5.5,0,0,0,.58.35,5.63,5.63,0,0,1,.58,0h4c1.34,0,2.68,0,4,0h3.94l1.84,0a3,3,0,0,1,.79.08A2.2,2.2,0,0,1,67.94,14l0,2.38c0,.13,0,.26,0,.39A13.86,13.86,0,0,1,67.88,19a2.39,2.39,0,0,1-1.31,1.32,3.81,3.81,0,0,1-.75.07H16.06l-2.45,0a2.26,2.26,0,0,1-1.5-1.45c0-.23-.06-.45-.06-.68,0-.62,0-1.25,0-1.88l0-2.43a2.4,2.4,0,0,1,1.29-1.43,14.74,14.74,0,0,1,2.3-.08l.4,0c1.31,0,2.63,0,3.94,0h4q2,0,4,0c1,.07,1,.07,1.37-.75.25-.51.5-1,.77-1.53A3,3,0,0,1,33,8.4H47A4.29,4.29,0,0,1,48,8.52Z"
                              transform="translate(-12.03 -8.4)"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    : null}
                  <div className="div-content">
                    <button
                      id="flt-message"
                      className="btn btn-outline-info flt-message"
                      onClick={() => {
                        this.props.activityStore.updateVisibleFilter("Message");
                      }}
                      type="button"
                    >
                      {t("activity.type")}
                    </button>
                    <div
                      id="message-form"
                      className={
                        this.props.activityStore.visibleFilter === "Message"
                          ? "card message-form display-block"
                          : "card message-form display-none"
                      }
                    >
                      <form>
                        <div className="row">
                          <label>{t("activity.type")}</label>
                          <MySelect
                            selectName="messageFilter.toOption"
                            selectClass="select"
                            selectedOption={
                              this.props.activityStore.messageFilter.toOption
                            }
                            onChange={(name, value) => {
                              this.props.activityStore.updateNestedProperty(name, value)
                              this.props.activityStore.saveFilters(false, true);
                            }
                            }
                            selectOptions={
                              this.props.activityStore.messageFilter.selectOptions
                            }
                          /></div>
                      </form>
                      <hr />
                      <button
                        id="button-filter-delete-message"
                        className="btn btn-outline-danger"
                        onClick={() => {
                          this.props.activityStore.deleteFilter("message");
                        }}
                      >
                        <svg
                          className="svg-icon-trash-filter-activity"
                          data-name="fas fa-trash-alt"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 55.93 63.79"
                        >
                          <path
                            className="cls-1"
                            d="M60,71.88a8.89,8.89,0,0,1-2.81.31h-35A5.89,5.89,0,0,1,16.9,69.3a9.68,9.68,0,0,1-.53-1.07l-.23-.72a9.8,9.8,0,0,1-.09-1.33c0-.65,0-1.3,0-2,0-1.33,0-2.67,0-4s0-2.62,0-3.93,0-2.68,0-4v-4c0-1.34,0-2.67,0-4s0-2.62,0-3.93,0-2.68,0-4v-4c0-1.31,0-2.63,0-3.94s0-2.68,0-4l3.95,0q2,0,4,0h4q2,0,4,0H36q2,0,4,0h4c1.32,0,2.63,0,4,0s2.67,0,4,0,2.68,0,4,0,2.67,0,4,0h4c0,1.34,0,2.68,0,4s0,2.63,0,3.94,0,2.67,0,4v4c0,1.32,0,2.63,0,3.94s0,2.67,0,4,0,2.67,0,4,0,2.67,0,4,0,2.63,0,3.95v4l0,2.07A5.9,5.9,0,0,1,61.61,71,8.49,8.49,0,0,1,60,71.88ZM40,32.34a2,2,0,0,0-2,2.09c0,.63,0,1.26,0,1.89v23.9c0,.72,0,1.43,0,2.14a2,2,0,0,0,3.94,0c0-.71,0-1.42,0-2.14V36.32c0-.68,0-1.36,0-2A1.93,1.93,0,0,0,40,32.34Zm12,0a2,2,0,0,0-2,2c0,.67,0,1.33,0,2v23.9c0,.71,0,1.41,0,2.11a2,2,0,0,0,3.92.35,9.64,9.64,0,0,0,0-2.46V56.29a.74.74,0,0,0,0-.39c0-.95,0-1.89,0-2.83a2.32,2.32,0,0,0,0-.79v-4a.74.74,0,0,0,0-.39c0-.92,0-1.84,0-2.76a2.75,2.75,0,0,0,0-.86V40.33a.75.75,0,0,0,0-.39c0-.95,0-1.89,0-2.84a2.34,2.34,0,0,0,0-.79c0-.63,0-1.26,0-1.89A2,2,0,0,0,52,32.34ZM28,64.22a2,2,0,0,0,2-1.89c0-.7,0-1.4,0-2.11V36.32c0-.63,0-1.26,0-1.89a2,2,0,0,0-2-2.09,1.94,1.94,0,0,0-2,1.53,9.13,9.13,0,0,0,0,2.45v4a.75.75,0,0,0,0,.39c0,.92,0,1.84,0,2.76a2.32,2.32,0,0,0,0,.79v4a.74.74,0,0,0,0,.39c0,.94,0,1.89,0,2.83a2.34,2.34,0,0,0,0,.79,20.93,20.93,0,0,0,0,2.24,14.94,14.94,0,0,0,0,1.77c-.08,1,0,2.07,0,3.11a2.58,2.58,0,0,0,0,.83c0,.71,0,1.42,0,2.13A1.93,1.93,0,0,0,28,64.22Z"
                            transform="translate(-12.03 -8.4)"
                          />
                          <path
                            className="cls-1"
                            d="M48,8.52a3.47,3.47,0,0,1,2,2c.23.51.51,1,.74,1.5a.5.5,0,0,0,.58.35,5.63,5.63,0,0,1,.58,0h4c1.34,0,2.68,0,4,0h3.94l1.84,0a3,3,0,0,1,.79.08A2.2,2.2,0,0,1,67.94,14l0,2.38c0,.13,0,.26,0,.39A13.86,13.86,0,0,1,67.88,19a2.39,2.39,0,0,1-1.31,1.32,3.81,3.81,0,0,1-.75.07H16.06l-2.45,0a2.26,2.26,0,0,1-1.5-1.45c0-.23-.06-.45-.06-.68,0-.62,0-1.25,0-1.88l0-2.43a2.4,2.4,0,0,1,1.29-1.43,14.74,14.74,0,0,1,2.3-.08l.4,0c1.31,0,2.63,0,3.94,0h4q2,0,4,0c1,.07,1,.07,1.37-.75.25-.51.5-1,.77-1.53A3,3,0,0,1,33,8.4H47A4.29,4.29,0,0,1,48,8.52Z"
                            transform="translate(-12.03 -8.4)"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  {this.props.activityStore.superuser ? <div className="div-content">
                    <button
                      id="flt-message"
                      className="btn btn-outline-info flt-message"
                      onClick={() => {
                        this.props.activityStore.downloadExcel()
                      }}
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        style={{
                          fill: "#17a2b8",
                          minWidth: "1rem",
                        }}
                      >
                        <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                      </svg>
                    </button>
                  </div> : null}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-10 activitat">
              <div id="activitat" className="row">
                <div className="col-md-2">
                  <div className="tab">
                    <button
                      id="todayActivity"
                      className="tablinks"
                      onClick={() => {
                        let start = new Date(Date.now());
                        let end = new Date(Date.now());
                        end.setDate(end.getDate() + 1);
                        if (start.getDate() < 10) {
                          this.props.activityStore.updateNestedProperty(
                            "startDayFilter.toOption",
                            "0" + start.getDate()
                          );
                        } else {
                          this.props.activityStore.updateNestedProperty(
                            "startDayFilter.toOption",
                            "" + start.getDate()
                          );
                        }
                        if (end.getDate() < 10)
                          this.props.activityStore.updateNestedProperty(
                            "endDayFilter.toOption",
                            "0" + end.getDate()
                          );
                        this.props.activityStore.updateNestedProperty(
                          "endDayFilter.toOption",
                          "" + end.getDate()
                        );

                        if (start.getMonth() + 1 < 10) {
                          this.props.activityStore.updateNestedProperty(
                            "startMonthFilter.toOption",
                            "0" + (start.getMonth() + 1)
                          );
                        } else {
                          this.props.activityStore.updateNestedProperty(
                            "startMonthFilter.toOption",
                            "" + (start.getMonth() + 1)
                          );
                        }

                        if (end.getMonth() + 1 < 10) {
                          this.props.activityStore.updateNestedProperty(
                            "endMonthFilter.toOption",
                            "0" + (end.getMonth() + 1)
                          );
                        } else {
                          this.props.activityStore.updateNestedProperty(
                            "endMonthFilter.toOption",
                            "" + (end.getMonth() + 1)
                          );
                        }

                        this.props.activityStore.updateNestedProperty(
                          "startYearFilter.toOption",
                          "" + start.getFullYear()
                        );
                        this.props.activityStore.updateNestedProperty(
                          "endYearFilter.toOption",
                          "" + end.getFullYear()
                        );

                        this.props.activityStore.saveFilters(false, true);
                        let tabs = document.getElementsByClassName("tablinks");
                        for (let i = 0; i < tabs.length; i++) {
                          if (tabs[i].classList.contains("active")) {
                            tabs[i].className = tabs[i].className.replace(
                              "active",
                              " "
                            );
                          }
                        }
                        let tab = document.getElementById("todayActivity");
                        tab.className += " active";
                      }}
                    >
                      {t("activity.today")}
                    </button>
                    <button
                      id="yesterdayActivity"
                      className="tablinks"
                      onClick={() => {
                        let start = new Date(Date.now());
                        let end = new Date(Date.now());
                        start.setDate(start.getDate() - 1);
                        if (start.getDate() < 10) {
                          this.props.activityStore.updateNestedProperty(
                            "startDayFilter.toOption",
                            "0" + start.getDate()
                          );
                        } else {
                          this.props.activityStore.updateNestedProperty(
                            "startDayFilter.toOption",
                            "" + start.getDate()
                          );
                        }
                        if (end.getDate() < 10)
                          this.props.activityStore.updateNestedProperty(
                            "endDayFilter.toOption",
                            "0" + end.getDate()
                          );
                        this.props.activityStore.updateNestedProperty(
                          "endDayFilter.toOption",
                          "" + end.getDate()
                        );

                        if (start.getMonth() + 1 < 10) {
                          this.props.activityStore.updateNestedProperty(
                            "startMonthFilter.toOption",
                            "0" + (start.getMonth() + 1)
                          );
                        } else {
                          this.props.activityStore.updateNestedProperty(
                            "startMonthFilter.toOption",
                            "" + (start.getMonth() + 1)
                          );
                        }

                        if (end.getMonth() + 1 < 10) {
                          this.props.activityStore.updateNestedProperty(
                            "endMonthFilter.toOption",
                            "0" + (end.getMonth() + 1)
                          );
                        } else {
                          this.props.activityStore.updateNestedProperty(
                            "endMonthFilter.toOption",
                            "" + (end.getMonth() + 1)
                          );
                        }

                        this.props.activityStore.updateNestedProperty(
                          "startYearFilter.toOption",
                          "" + start.getFullYear()
                        );
                        this.props.activityStore.updateNestedProperty(
                          "endYearFilter.toOption",
                          "" + end.getFullYear()
                        );

                        this.props.activityStore.saveFilters(false, true);

                        let tabs = document.getElementsByClassName("tablinks");
                        for (let i = 0; i < tabs.length; i++) {
                          if (tabs[i].classList.contains("active")) {
                            tabs[i].className = tabs[i].className.replace(
                              " active",
                              " "
                            );
                          }
                        }
                        let tab = document.getElementById("yesterdayActivity");
                        tab.className += " active";
                      }}
                    >
                      {t("activity.yesterday")}
                    </button>
                    <button
                      id="weekActivity"
                      className="tablinks"
                      onClick={() => {
                        let date = new Date(Date.now());
                        date.setDate(date.getDate() + 1);
                        let lastWeek = new Date(Date.now());
                        lastWeek.setDate(lastWeek.getDate() - 7);
                        if (lastWeek.getDate() < 10) {
                          this.props.activityStore.updateNestedProperty(
                            "startDayFilter.toOption",
                            "0" + lastWeek.getDate()
                          );
                        } else {
                          this.props.activityStore.updateNestedProperty(
                            "startDayFilter.toOption",
                            "" + lastWeek.getDate()
                          );
                        }

                        if (date.getDate() < 10) {
                          this.props.activityStore.updateNestedProperty(
                            "endDayFilter.toOption",
                            "0" + date.getDate()
                          );
                        } else {
                          this.props.activityStore.updateNestedProperty(
                            "endDayFilter.toOption",
                            "" + date.getDate()
                          );
                        }
                        if (lastWeek.getMonth() + 1 < 10) {
                          this.props.activityStore.updateNestedProperty(
                            "startMonthFilter.toOption",
                            "0" + (lastWeek.getMonth() + 1)
                          );
                        } else {
                          this.props.activityStore.updateNestedProperty(
                            "startMonthFilter.toOption",
                            "" + (lastWeek.getMonth() + 1)
                          );
                        }
                        if (date.getMonth() + 1 < 10) {
                          this.props.activityStore.updateNestedProperty(
                            "endMonthFilter.toOption",
                            "0" + (date.getMonth() + 1)
                          );
                        } else {
                          this.props.activityStore.updateNestedProperty(
                            "endMonthFilter.toOption",
                            "" + (date.getMonth() + 1)
                          );
                        }

                        this.props.activityStore.updateNestedProperty(
                          "startYearFilter.toOption",
                          "" + lastWeek.getFullYear()
                        );
                        this.props.activityStore.updateNestedProperty(
                          "endYearFilter.toOption",
                          "" + date.getFullYear()
                        );

                        this.props.activityStore.saveFilters(false, true);
                        let tabs = document.getElementsByClassName("tablinks");
                        for (let i = 0; i < tabs.length; i++) {
                          if (tabs[i].classList.contains("active")) {
                            tabs[i].className = tabs[i].className.replace(
                              " active",
                              " "
                            );
                          }
                        }
                        let tab = document.getElementById("weekActivity");
                        tab.className += " active";
                      }}
                    >
                      {t("activity.week")}
                    </button>
                    <button
                      id="monthActivity"
                      className="tablinks"
                      onClick={() => {
                        let start = new Date(Date.now());
                        let end = new Date(Date.now());
                        start.setMonth(start.getMonth() - 1);
                        end.setDate(end.getDate() + 1);
                        if (start.getDate() < 10) {
                          this.props.activityStore.updateNestedProperty(
                            "startDayFilter.toOption",
                            "0" + start.getDate()
                          );
                        } else {
                          this.props.activityStore.updateNestedProperty(
                            "startDayFilter.toOption",
                            "" + start.getDate()
                          );
                        }
                        if (end.getDate() < 10)
                          this.props.activityStore.updateNestedProperty(
                            "endDayFilter.toOption",
                            "0" + end.getDate()
                          );
                        this.props.activityStore.updateNestedProperty(
                          "endDayFilter.toOption",
                          "" + end.getDate()
                        );

                        if (start.getMonth() + 1 < 10) {
                          this.props.activityStore.updateNestedProperty(
                            "startMonthFilter.toOption",
                            "0" + (start.getMonth() + 1)
                          );
                        } else {
                          this.props.activityStore.updateNestedProperty(
                            "startMonthFilter.toOption",
                            "" + (start.getMonth() + 1)
                          );
                        }

                        if (end.getMonth() + 1 < 10) {
                          this.props.activityStore.updateNestedProperty(
                            "endMonthFilter.toOption",
                            "0" + (end.getMonth() + 1)
                          );
                        } else {
                          this.props.activityStore.updateNestedProperty(
                            "endMonthFilter.toOption",
                            "" + (end.getMonth() + 1)
                          );
                        }

                        this.props.activityStore.updateNestedProperty(
                          "startYearFilter.toOption",
                          "" + start.getFullYear()
                        );
                        this.props.activityStore.updateNestedProperty(
                          "endYearFilter.toOption",
                          "" + end.getFullYear()
                        );

                        this.props.activityStore.saveFilters(false, true);

                        let tabs = document.getElementsByClassName("tablinks");
                        for (let i = 0; i < tabs.length; i++) {
                          if (tabs[i].classList.contains("active")) {
                            tabs[i].className = tabs[i].className.replace(
                              " active",
                              " "
                            );
                          }
                        }
                        let tab = document.getElementById("monthActivity");
                        tab.className += " active";
                      }}
                    >
                      {t("activity.month")}
                    </button>
                    <button
                      id="oldActivity"
                      className="tablinks"
                      onClick={() => {
                        let date = new Date(Date.now());
                        date.setDate(date.getDate() + 1);
                        date.setMonth(date.getMonth() - 2);

                        this.props.activityStore.updateNestedProperty(
                          "startDayFilter.toOption",
                          "-1"
                        );
                        if (date.getDate() < 10) {
                          this.props.activityStore.updateNestedProperty(
                            "endDayFilter.toOption",
                            "0" + date.getDate()
                          );
                        } else {
                          this.props.activityStore.updateNestedProperty(
                            "endDayFilter.toOption",
                            "" + date.getDate()
                          );
                        }
                        this.props.activityStore.updateNestedProperty(
                          "startMonthFilter.toOption",
                          "-1"
                        );
                        if (date.getMonth() + 1 < 10) {
                          this.props.activityStore.updateNestedProperty(
                            "endMonthFilter.toOption",
                            "0" + (date.getMonth() + 1)
                          );
                        } else {
                          this.props.activityStore.updateNestedProperty(
                            "endMonthFilter.toOption",
                            "" + (date.getMonth() + 1)
                          );
                        }
                        this.props.activityStore.updateNestedProperty(
                          "startYearFilter.toOption",
                          "-1"
                        );
                        this.props.activityStore.updateNestedProperty(
                          "endYearFilter.toOption",
                          "" + date.getFullYear()
                        );

                        this.props.activityStore.saveFilters(false, true);

                        let tabs = document.getElementsByClassName("tablinks");
                        for (let i = 0; i < tabs.length; i++) {
                          if (tabs[i].classList.contains("active")) {
                            tabs[i].className = tabs[i].className.replace(
                              " active",
                              " "
                            );
                          }
                        }

                        let tab = document.getElementById("oldActivity");
                        tab.className += " active";
                      }}
                    >
                      {t("activity.old")}
                    </button>
                  </div>
                </div>
                <div className="col-md-10 tabs-lists" name="scrollTable">
                  <div className="tabcontent">
                    <table>
                      <thead>
                        <tr>
                          <th style={{ width: "1%" }}></th>
                          <th style={{ width: "15%" }}>{t("activity.date")}</th>
                          <th style={{ width: "10%" }}>{t("activity.user")}</th>
                          <th style={{ width: "10%" }}>{t("activity.ad")}</th>
                          <th style={{ width: "10%" }}>
                            {t("activity.group")}
                          </th>
                          {(this.props.activityStore.superuser) ?
                            (<th style={{ width: "10%" }}>
                              {t("activity.page")}
                            </th>)
                            : null}
                          <th style={{ width: "44%" }}>
                            {t("activity.message")}
                          </th>
                        </tr>
                      </thead>
                      <tbody id="today-body">
                        {this.props.activityStore.tableData
                          ? this.props.activityStore.tableData.map((log, i) => {
                            return (
                              <tr key={log._id + i}>
                                <td style={{ width: "1%" }}>{i + 1}</td>
                                <td style={{ width: "15%" }}>
                                  {this.props.activityStore.convertToFormatTable(
                                    log.date
                                  )}
                                </td>
                                <td style={{ width: "10%" }}>{log.user}</td>
                                <td style={{ width: "10%" }}>
                                  {this.props.activityStore.parseArray(
                                    log.idAd
                                  )}
                                </td>
                                <td style={{ width: "10%" }}>
                                  {this.props.activityStore.parseArray(
                                    log.idGroupAds
                                  )}
                                </td>
                                {(this.props.activityStore.superuser) ? (
                                  <td style={{ width: "10%" }}>
                                    {
                                      log.page ? log.page : "-"
                                    }
                                  </td>)
                                  : null}
                                <td style={{ width: "44%" }}>
                                  {typeof log.message !== "string" ? log.message.map(item => t(item, item)) : log.message}
                                </td>
                              </tr>
                            );
                          })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-10 paginator">
              <div id="list-pag-Barcelona" className="page">
                <div
                  id="pag-container"
                  className="row pag-content justify-content-center"
                >
                  <ul className="pagination">
                    <li
                      className={
                        this.props.pageStore.page === 0 ? "disabled" : ""
                      }
                    >
                      <button
                        className="btn"
                        onClick={async () => {
                          this.props.pageStore.updateProperty("page", 0);
                          this.props.activityStore.updateProperty(
                            "urlFilters",
                            await this.props.activityStore.getNewUrl()
                          );
                          this.props.navigationStore.push(
                            "/activity" + this.props.activityStore.urlFilters
                          );
                          document.getElementsByName("scrollTable")[0].scrollTo(0, 0)
                        }}
                      >
                        <svg
                          className="svg-icon-pagination"
                          data-name="fas fa-angle-double-left"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 49.54 39.7"
                        >
                          <path
                            d="M58.11,21.11a3.19,3.19,0,0,1,3,1.09c.8.84,1.64,1.63,2.47,2.44l.36.41a3,3,0,0,1-.36,4h0C62.3,30.31,61,31.62,59.71,33s-2.68,2.64-4,4L51.84,40.8a.29.29,0,0,0-.05.13c1.26,1.35,2.59,2.64,3.92,3.93s2.64,2.68,4,4,2.59,2.64,3.92,3.92l.06.06A3,3,0,0,1,64,56.74l-.37.42c-.8.8-1.61,1.59-2.4,2.4a4.18,4.18,0,0,1-1.66,1.12l-.12,0a5.13,5.13,0,0,1-1.41,0,3.39,3.39,0,0,1-1.58-1c-.22-.25-.48-.46-.73-.7l-2.15-2.15c-.59-.64-1.2-1.25-1.84-1.84l-2.16-2.15c-.59-.64-1.2-1.25-1.84-1.84l-2.16-2.15c-.56-.62-1.15-1.21-1.76-1.77l-2.23-2.22c-.56-.62-1.15-1.21-1.76-1.77a3.21,3.21,0,0,1-.94-2.14,3.38,3.38,0,0,1,.93-2.21L41.59,37c.77-.72,1.51-1.46,2.23-2.22L45.59,33c.47-.46.95-.92,1.41-1.39a3.9,3.9,0,0,0,.73-.79A20.88,20.88,0,0,0,49.51,29c.76-.72,1.51-1.45,2.22-2.22l1.77-1.77c.77-.71,1.51-1.45,2.23-2.22.11-.11.24-.2.34-.32A4.32,4.32,0,0,1,58.11,21.11Z"
                            transform="translate(-15.04 -21.06)"
                          />
                          <path
                            d="M34.36,21.1a3.13,3.13,0,0,1,2.87,1c.84.87,1.72,1.71,2.58,2.56l.31.37a3,3,0,0,1-.32,4l-3.92,3.92-.07.07q-2,1.92-3.92,3.92l-1.45,1.43L28,40.85c-.11.18.08.23.15.3l3.74,3.75c.25.26.51.51.77.76l3.19,3.17.07.08c1.29,1.33,2.59,2.64,3.92,3.92A3.47,3.47,0,0,1,40.54,54a3,3,0,0,1-.38,2.75l-.36.42c-.87.85-1.75,1.69-2.6,2.57a3,3,0,0,1-3,1h-.15a4,4,0,0,1-1.55-1.08c-.19-.22-.41-.4-.62-.6-.7-.75-1.43-1.47-2.18-2.17-.58-.63-1.19-1.24-1.82-1.82-.7-.75-1.42-1.47-2.17-2.17-.59-.63-1.19-1.24-1.82-1.82L21.82,48.9s-.1,0-.15-.07c-.57-.6-1.15-1.18-1.75-1.75,0,0,0-.1-.05-.13l-2.13-2.1c-.56-.61-1.14-1.19-1.75-1.75a3.23,3.23,0,0,1-1-2.17,3.4,3.4,0,0,1,1-2.23c.61-.56,1.2-1.15,1.76-1.75.74-.7,1.47-1.42,2.17-2.17.63-.58,1.24-1.19,1.83-1.82s1.46-1.42,2.16-2.16c.61-.57,1.2-1.15,1.77-1.76l2.23-2.23,1.77-1.76,2.22-2.22c.19-.17.38-.34.55-.52A3.6,3.6,0,0,1,34.36,21.1Z"
                            transform="translate(-15.04 -21.06)"
                          />
                        </svg>
                      </button>
                    </li>
                    <li
                      className={
                        this.props.pageStore.page === 0 ? "disabled" : ""
                      }
                    >
                      <button
                        className="btn"
                        onClick={
                          this.props.pageStore.page !== 0
                            ? async () => {
                              this.props.pageStore.updateProperty(
                                "page",
                                this.props.pageStore.page - 1
                              );
                              this.props.activityStore.updateProperty(
                                "urlFilters",
                                await this.props.activityStore.getNewUrl()
                              );
                              this.props.navigationStore.push(
                                "/activity" +
                                this.props.activityStore.urlFilters
                              );
                              document.getElementsByName("scrollTable")[0].scrollTo(0, 0)
                            }
                            : null
                        }
                      >
                        <svg
                          className="svg-icon-pagination"
                          data-name="fas fa-angle-left"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 26.2 40.11"
                        >
                          <path
                            d="M52.15,28.68a2.08,2.08,0,0,0,0-3l-4-4s-1-1-3,0L27.08,39.51s-1,1,0,3L44.94,60.63a2.08,2.08,0,0,0,3,0l4-4a2.08,2.08,0,0,0,0-3L40.06,41.6Z"
                            transform="translate(-26.62 -21.2)"
                          />
                        </svg>
                      </button>
                    </li>
                    {this.props.pageStore.page > 1 && (
                      <li className="">
                        <button
                          className="btn"
                          onClick={
                            this.props.pageStore.page > 1
                              ? async () => {
                                this.props.pageStore.updateProperty(
                                  "page",
                                  this.props.pageStore.page - 2
                                );
                                this.props.activityStore.updateProperty(
                                  "urlFilters",
                                  await this.props.activityStore.getNewUrl()
                                );
                                this.props.navigationStore.push(
                                  "/activity" +
                                  this.props.activityStore.urlFilters
                                );
                                document.getElementsByName("scrollTable")[0].scrollTo(0, 0)
                              }
                              : null
                          }
                        >
                          {this.props.pageStore.page - 1}
                        </button>
                      </li>
                    )}
                    {this.props.pageStore.page > 0 && (
                      <li className="">
                        <button
                          className="btn"
                          onClick={async () => {
                            this.props.pageStore.updateProperty(
                              "page",
                              this.props.pageStore.page - 1
                            );
                            this.props.activityStore.updateProperty(
                              "urlFilters",
                              await this.props.activityStore.getNewUrl()
                            );
                            this.props.navigationStore.push(
                              "/activity" + this.props.activityStore.urlFilters
                            );
                            document.getElementsByName("scrollTable")[0].scrollTo(0, 0)
                          }}
                        >
                          {this.props.pageStore.page}
                        </button>
                      </li>
                    )}
                    <li className="active">
                      <button className="btn">
                        {this.props.pageStore.page + 1}
                      </button>
                    </li>
                    {this.props.pageStore.page <
                      this.props.pageStore.totalPages - 1 && (
                        <li className="">
                          <button
                            className="btn"
                            onClick={async () => {
                              this.props.pageStore.updateProperty(
                                "page",
                                this.props.pageStore.page + 1
                              );
                              this.props.activityStore.updateProperty(
                                "urlFilters",
                                await this.props.activityStore.getNewUrl()
                              );
                              this.props.navigationStore.push(
                                "/activity" + this.props.activityStore.urlFilters
                              );
                              document.getElementsByName("scrollTable")[0].scrollTo(0, 0)
                            }}
                          >
                            {this.props.pageStore.page + 2}
                          </button>
                        </li>
                      )}
                    {this.props.pageStore.page <
                      this.props.pageStore.totalPages - 2 && (
                        <li className="">
                          <button
                            className="btn"
                            onClick={async () => {
                              this.props.pageStore.updateProperty(
                                "page",
                                this.props.pageStore.page + 2
                              );
                              this.props.activityStore.updateProperty(
                                "urlFilters",
                                await this.props.activityStore.getNewUrl()
                              );
                              this.props.navigationStore.push(
                                "/activity" + this.props.activityStore.urlFilters
                              );
                              document.getElementsByName("scrollTable")[0].scrollTo(0, 0)
                            }}
                          >
                            {this.props.pageStore.page + 3}
                          </button>
                        </li>
                      )}
                    <li
                      className={
                        this.props.pageStore.page >=
                          this.props.pageStore.totalPages
                          ? "disabled"
                          : ""
                      }
                    >
                      <button
                        className="btn"
                        onClick={
                          this.props.pageStore.page <
                            this.props.pageStore.totalPages - 1
                            ? async () => {
                              this.props.pageStore.updateProperty(
                                "page",
                                this.props.pageStore.page + 1
                              );
                              this.props.activityStore.updateProperty(
                                "urlFilters",
                                await this.props.activityStore.getNewUrl()
                              );
                              this.props.navigationStore.push(
                                "/activity" +
                                this.props.activityStore.urlFilters
                              );
                              document.getElementsByName("scrollTable")[0].scrollTo(0, 0)
                            }
                            : null
                        }
                      >
                        <svg
                          className="svg-icon-pagination"
                          data-name="fas fa-angle-right"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 26.12 40.11"
                        >
                          <path
                            d="M27.22,53.48a2.08,2.08,0,0,0,0,3l4,4s1,1,3,0l18-18s1-1,0-3l-18-18a2.08,2.08,0,0,0-3,0l-4,4a2.08,2.08,0,0,0,0,3l12,12Z"
                            transform="translate(-26.54 -20.81)"
                          />
                        </svg>
                      </button>
                    </li>
                    <li
                      className={
                        this.props.pageStore.page >=
                          this.props.pageStore.totalPages - 1
                          ? "disabled"
                          : ""
                      }
                    >
                      <button
                        className="btn"
                        onClick={
                          this.props.pageStore.page <
                            this.props.pageStore.totalPages - 1
                            ? async () => {
                              this.props.pageStore.updateProperty(
                                "page",
                                this.props.pageStore.totalPages - 1
                              );
                              this.props.activityStore.updateProperty(
                                "urlFilters",
                                await this.props.activityStore.getNewUrl()
                              );
                              this.props.navigationStore.push(
                                "/activity" +
                                this.props.activityStore.urlFilters
                              );
                              document.getElementsByName("scrollTable")[0].scrollTo(0, 0)
                            }
                            : null
                        }
                      >
                        <svg
                          className="svg-icon-pagination"
                          data-name="fas fa-double-angle-right"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 49.59 39.74"
                        >
                          <path
                            d="M15.78,25.12l.44-.5c.89-.8,1.67-1.71,2.52-2.54a3.17,3.17,0,0,1,2.51-1,4.29,4.29,0,0,1,.5.07,3.71,3.71,0,0,1,1.27.71l1.06,1c.7.75,1.43,1.48,2.18,2.18.58.63,1.18,1.23,1.82,1.81.7.75,1.43,1.48,2.18,2.18.58.63,1.18,1.24,1.81,1.82s1.39,1.43,2.12,2.1,1.18,1.24,1.81,1.82c.7.75,1.43,1.48,2.18,2.18.58.63,1.19,1.23,1.82,1.81A3.2,3.2,0,0,1,40.92,41c0,.2-.05.4-.09.6A3.62,3.62,0,0,1,40,43.13c-.61.56-1.2,1.15-1.76,1.75-.77.73-1.52,1.47-2.24,2.24-.61.56-1.2,1.15-1.76,1.76-.74.7-1.47,1.42-2.17,2.17-.63.58-1.24,1.19-1.83,1.82S28.77,54.29,28.07,55c-.63.58-1.24,1.19-1.83,1.82S24.78,58.29,24.07,59c-.15.14-.3.27-.44.42a4,4,0,0,1-1.88,1.28,3.08,3.08,0,0,1-2.51-.5,5.92,5.92,0,0,1-.45-.41l-2.64-2.6a1.33,1.33,0,0,0-.35-.36,3.35,3.35,0,0,1-.53-2.33,3.59,3.59,0,0,1,.83-1.65l.06-.06a1.24,1.24,0,0,0,.23-.17l3.54-3.53a.44.44,0,0,0,.16-.23h0c.1,0,.16-.09.23-.16l3.62-3.62c.06-.06.1-.14.15-.2a1.59,1.59,0,0,0,.21-.15l3.57-3.58A1.21,1.21,0,0,0,28,41h0c.06,0,0-.06,0-.08q-1.89-2-3.86-3.85L24,36.89l-3.74-3.75a.69.69,0,0,0-.19-.1c0-.06,0-.11-.07-.14Q18.15,31,16.22,29.13a.1.1,0,0,0-.11-.08.54.54,0,0,0-.07-.13A3,3,0,0,1,15.78,25.12Z"
                            transform="translate(-15.22 -21.06)"
                          />
                          <path
                            d="M39.69,25.06,40,24.7,42.7,22a3,3,0,0,1,2.29-1,3.4,3.4,0,0,1,.46.06h.08l.13,0c.22.13.47.18.69.3a10.78,10.78,0,0,1,1.57,1.37Q49,24,50.17,25.07l1.75,1.75c.73.76,1.47,1.51,2.25,2.24.58.58,1.16,1.17,1.75,1.75L58.09,33l1.83,1.83L62.09,37l1.75,1.75a3.35,3.35,0,0,1,1,2.23,3.41,3.41,0,0,1-1,2.17c-.61.56-1.2,1.15-1.76,1.75-.74.7-1.47,1.43-2.17,2.17s-1.24,1.2-1.83,1.83c-.74.7-1.47,1.42-2.17,2.16s-1.24,1.2-1.83,1.83c-.74.7-1.47,1.42-2.17,2.17-.63.58-1.24,1.19-1.83,1.82-.74.7-1.46,1.43-2.17,2.17-.13.13-.27.24-.39.37a4,4,0,0,1-2,1.33,3.2,3.2,0,0,1-3-1.08C41.7,58.8,40.84,58,40,57.15l-.28-.29a2.39,2.39,0,0,0-.12-.22,3,3,0,0,1-.36-2.55,3.93,3.93,0,0,1,.71-1.2l.06-.07a8.78,8.78,0,0,0,1.13-1.07c1-1,1.9-1.92,2.85-2.88,1.34-1.27,2.63-2.58,3.92-3.9,0,0,0,0,0-.06a1.59,1.59,0,0,0,.21-.15l3.58-3.58a1,1,0,0,0,.14-.21c.1-.17-.08-.22-.16-.3L48.3,37.25a1.44,1.44,0,0,0-.37-.28c0-.18-.17-.26-.28-.37L44,33,40.06,29a3,3,0,0,1-.63-.83A2.93,2.93,0,0,1,39.69,25.06Z"
                            transform="translate(-15.22 -21.06)"
                          />
                        </svg>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              {this.props.activityStore.activeUserData && this.props.activityStore.activeUserData.length > 0 ? (
                <div>
                  <p style={{ "textAlign": "center" }}>Nombre d'usuaris que en l'últim mes han fet almenys una crida de tipus:</p>
                  <div className="stats-chart">
                    <div style={{ height: "25rem" }}>
                      <ResponsiveContainer
                        width="100%"
                        height="100%"
                      >
                        <BarChart
                          width={500}
                          height={300}
                          data={
                            this.props.activityStore
                              .activeUserData
                          }
                          name="HOLAAA"
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 20,
                          }}
                        >
                          <CartesianGrid strokeDasharray="1 1" />
                          <XAxis
                            dataKey="_id"
                            interval={0}
                          />
                          <YAxis />
                          <Tooltip />
                          <Bar
                            dataKey="count"
                          >
                            {this.props.activityStore.activeUserData.map((entry, index) => {
                              return (
                                <Cell key={`cell-${index}`} fill={this.props.activityStore.colors[index % this.props.activityStore.colors.length]} />
                              )
                            })}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-md-4">
              {this.props.activityStore.activityEachUser && this.props.activityStore.activityEachUser.length > 0 ? (<div>
                <p style={{ "textAlign": "center" }}>Dies que cada usuari ha fet login aquest últim mes</p>
                <div className="stats-chart">
                  <div style={{ height: "25rem" }}>
                    <ResponsiveContainer
                      width="100%"
                      height="100%"
                    >
                      <BarChart
                        width={500}
                        height={300}
                        data={
                          this.props.activityStore
                            .activityEachUser
                        }
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 20,
                        }}
                      >
                        <CartesianGrid strokeDasharray="1 1" />
                        <XAxis
                          dataKey="_id"
                          interval={0}
                        />
                        <YAxis />
                        <Tooltip />
                        <Bar
                          dataKey="count"
                        >
                          {this.props.activityStore.activityEachUser.map((entry, index) => {
                            return (
                              <Cell key={`cell-${index}`} fill={this.props.activityStore.colors[index % this.props.activityStore.colors.length]} />
                            )
                          })}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
              ) : null}
            </div>
            <div className="col-md-4">
              {this.props.activityStore.statsData && this.props.activityStore.statsData.length > 0 ? (<div>
                <p style={{ "textAlign": "center" }}>Nombre de crides de cada tipus amb els filtres seleccionats</p>
                <div className="stats-chart">
                  <div style={{ height: "25rem" }}>
                    <ResponsiveContainer
                      width="100%"
                      height="100%"
                    >
                      <BarChart
                        width={500}
                        height={300}
                        data={
                          this.props.activityStore
                            .statsData
                        }
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 20,
                        }}
                      >
                        <CartesianGrid strokeDasharray="1 1" />
                        <XAxis
                          dataKey="_id"
                          interval={0}
                        />
                        <YAxis />
                        <Tooltip />
                        <Bar
                          dataKey="count"
                        >
                          {this.props.activityStore.statsData.map((entry, index) => {
                            return (
                              <Cell key={`cell-${index}`} fill={this.props.activityStore.colors[index % this.props.activityStore.colors.length]} />
                            )
                          })}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
              ) : null}
            </div>
          </div>
        </main>
      );
    }
    return null;
  }
}

export default withTranslation()(Activity);
