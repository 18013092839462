import { observable, action, computed } from "mobx";
//import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import i18n from "../../i18n";
import Quill from "quill"
import 'quill/dist/quill.snow.css';

class TicketsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }
  

  @observable mail = null;

  @observable priorityFilter = {
    fromOption: "-1",
    toOption: "-1",
    value: "0",
    url: "",
    selectOptions: [
      { key: "low", value: i18n.t("tickets.priority.low") },
      { key: "minor", value: i18n.t("tickets.priority.minor") },
      { key: "major", value: i18n.t("tickets.priority.major") },
      { key: "critical", value: i18n.t("tickets.priority.critical") },
    ],
  };

  @observable ticket = {
    product: {
      client: "",
      platform: "STL"
    },
    requester: "",
    mail: "",
    priority: "low",
    subject: "",
    description: "",
    images:[],
  };

  @observable file = null;
  @observable ticketSended = false;

  @action("Initial function") initializeStore = async () => {
    let user = await this.rootStore.sessionStore.getSession();
    if (!user) user = this.rootStore.userStore.getUser()
    user = (await this.rootStore.requestStore.getUserById(user.id)).data;
    this.ticket.product.client = user.group;
    this.ticket.mail = user.email;
    this.ticket.requester = user.username;

    this.priorityFilter.selectOptions = [
      { key: "low", value: i18n.t("tickets.priority.low") },
      { key: "minor", value: i18n.t("tickets.priority.minor") },
      { key: "major", value: i18n.t("tickets.priority.major") },
      { key: "critical", value: i18n.t("tickets.priority.critical") },
    ];
    this.createEditor()
    console.log("BBBBBBBBB")
  };

  @action("Clear Store") clearStore = () => {
    this.ticket = {
      product: {
      client: "",
      platform: "STL"
    },
    requester: "",
    mail: "",
    priority: "low",
    subject: "",
    description: "",
    images:[],
  };
  };

  @action("update object") updateObject = (object, attrName, value) => {
    object[attrName] = value;
  };

  @action("update attribute") updateProperty = (attrName, value) => {
    //console.log("UpdateProperty Start", attrName, this[attrName], value);
    this[attrName] = value;
    //console.log("UpdateProperty End", attrName, this[attrName], value);
  };

  @action("update nested attribute") updateNestedProperty = (
    attrPath,
    value
  ) => {
    let path = attrPath.split(".");
    //console.log("UpdateNestedProperty Start", attrPath, this[path[0]][path[1]], value);
    this[path[0]][path[1]] = value;
    //console.log("UpdateNestedProperty End", attrPath, this[path[0]][path[1]], value);
  };

  getMail = async (id) => {
    this.ail = (await this.rootStore.requestStore.getMail(id)).data;
  };

  @computed get allValuesOnTicket() {
    if (this.ticket.description === "") return false;
    if (this.ticket.subject === "") return false;
    if (this.ticket.mail === "") return false;
    return true;
  }

  sendTicket = async () => {
    console.log(this.ticket);
    if (this.allValuesOnTicket) {
      let newTicket = await this.rootStore.requestStore.sendTicket(this.ticket);
      if (newTicket !== null || newTicket !== undefined) {
        this.ticketSended = true;

        let user = await this.rootStore.userStore.getUser();
        user = (await this.rootStore.requestStore.getUserById(user.id)).data;
        console.log(user)
        console.log(newTicket)
        
        let lang = i18n.language;
        
        
        let body = "";
        let htmlBody = "";
        let subject = "[Ticket ID:"+ newTicket.data._id + "] " + this.ticket.subject;
        switch (lang) {
          case "ca":
            body = "Apreciat " + user.firstName + ",\nGràcies per contactar amb el nostre equip de suport. Un tiquet ha estat obert amb la vostra petició. Serà notificat via correu electrònic quan el tiquet sigui respost. Els detalls del tiquet estan a continuació.\n\n Assumpte: " + this.ticket.subject + "\nPrioritat: " + this.ticket.priority + "\nEstat: Obert \nNúmero ticket: " + newTicket.data._id + "\nAtentament,\nEquip de Suport\nTalk&Code | The Smart Tourism Platform\n";
            htmlBody = "Apreciat " + user.firstName + ",<br/><br/>Gràcies per contactar amb el nostre equip de suport. Un tiquet ha estat obert amb la vostra petició. Serà notificat via correu electrònic quan el tiquet sigui respost. Els detalls del tiquet estan a continuació.<br/><br/> Assumpte: " + this.ticket.subject + "<br/>Prioritat: " + this.ticket.priority + "<br/>Estat: Obert <br/>Número ticket: " + newTicket.data._id + "<br/>Atentament,<br/><strong>Equip de Suport</strong><br/><strong>Talk&Code | The Smart Tourism Platform</strong><br>";
            break;
          case "es":
            body = "Apreciado " + user.firstName + ",\nGracias por contactar con nuestro equipo de soporte. Un ticket de soporte ha sido abierto con tu petición. Serás notificado vía correo electrónico cuando el ticket sea respondido. Los detalles del ticket están a continuación.\n\n Asunto: " + this.ticket.subject + "\nPrioridad: " + this.ticket.priority + "\nEstado: Abierto \nNumero ticket: " + newTicket.data._id + "\nAtentamente,\nEquipo de Soporte\nTalk&Code | The Smart Tourism Platform\n";
            htmlBody = "Apreciado " + user.firstName + ",<br/><br/>Gracias por contactar con nuestro equipo de soporte. Un ticket de soporte ha sido abierto con tu petición. Serás notificado vía correo electrónico cuando el ticket sea respondido. Los detalles del ticket están a continuación.<br/><br/> Asunto: " + this.ticket.subject + "<br/>Prioridad: " + this.ticket.priority + "<br/>Estado: Abierto <br/>Numero ticket: " + newTicket.data._id + "<br/>Atentamente,<br/><strong>Equipo de Soporte</strong><br/><strong>Talk&Code | The Smart Tourism Platform</strong><br>";
            break;
          case "fr":
            body = "Cher " + user.firstName + ",\nMerci d'avoir contacté notre équipe de support. Un ticket de support a été ouvert avec votre demande. Vous serez informé par courriel lorsque le ticket aura été traité. Les détails du ticket sont ci-dessous.\n\nSujet : " + this.ticket.subject + "\nPriorité : " + this.ticket.priority + "\nStatut : Ouvert \nNuméro du ticket : " + newTicket.data._id + "\nSincèrement, \nl'équipe de support\nTalk&Code | The Smart Tourism Platform\n";
            htmlBody = "Cher " + user.firstName + ",<br/><br/>Merci d'avoir contacté notre équipe de support. Un ticket de support a été ouvert avec votre demande. Vous serez informé par courriel lorsque le ticket aura été traité. Les détails du ticket sont ci-dessous.<br/><br/>Sujet : " + this.ticket.subject + "<br/>Priorité : " + this.ticket.priority + "<br/>Statut : Ouvert <br/>Numéro du ticket : " + newTicket.data._id + "<br/>Sincèrement, <br/><strong>l'équipe de support</strong><br/><strong>Talk&Code | The Smart Tourism Platform</strong><br>";
            break;
                    
          default:
            body = "Dear " + user.firstName + ",\nThank you for contacting our support team. A support ticket has been opened with your request. You will be notified via email when the ticket is answered. The ticket details are below.\nSubject: " + this.ticket.subject + "\nPriority: " + this.ticket.priority + "\nStatus: Open \nTicket number: " + newTicket.data._id + "\nSincerely, \nSupport Team Talk&Code | The Smart Tourism Platform\n";
            htmlBody = "Dear " + user.firstName + ",<br/><br/>Thank you for contacting our support team. A support ticket has been opened with your request. You will be notified via email when the ticket is answered. The ticket details are below.<br/><br/>Subject: " + this.ticket.subject + "<br/>Priority: " + this.ticket.priority + "<br/>Status: Open <br/>Ticket number: " + newTicket.data._id + "<br/><br/>Sincerely, <br/><strong>Support Team</strong><br/><strong>Talk&Code | The Smart Tourism Platform</strong><br>";
            break;
        }
        body = body + "P Before printing think about the environment C \nThis e-mail and any attachments thereto are classified as confidential information and as Talk&Code copyright, are intended for use by the addressee(s) named herein only and may contain legally privileged and/or confidential information. If you are not the recipient of this e-mail, you are hereby notified that any distribution or copying of this e-mail, and any attachments thereto, is strictly prohibited. If you have received this e-mail in error, please notify us immediately, permanently delete the original including attachments, and destroy any copy or printout thereof."
        htmlBody = htmlBody + "<p style='color:green'>P Before printing think about the environment C</p><p> This e-mail and any attachments thereto are classified as confidential information and as Talk&Code copyright, are intended for use by the addressee(s) named herein only and may contain legally privileged and/or confidential information. If you are not the recipient of this e-mail, you are hereby notified that any distribution or copying of this e-mail, and any attachments thereto, is strictly prohibited. If you have received this e-mail in error, please notify us immediately, permanently delete the original including attachments, and destroy any copy or printout thereof.</p>"
        await this.rootStore.requestStore.sendConfirmationMail(
          subject, //subject
          body, //body (plain text)
          this.ticket.requester, //user
          user.group, //group
          htmlBody//body (html text)
        );
      }
      /*if (this.file !== null) {
        await this.rootStore.requestStore.sendFileForTicket(
          this.file,
          //newTicket._id
        );
      }*/
    }
  };

  onFileChange = async (event) => {
    this.file = event.target.files[0];
    console.log(this.file);
  };

  @action("Create the textbox area with rich-text") createEditor = () => {
    console.log("AAAAAAAAAa")
		let container = document.getElementById("editor");
    console.log(container)

		this.quill = new Quill(container, {
      
      theme:"snow",
      modules:{
        toolbar: [
          ["bold","italic","underline","strike"],
          ["link","image"],
          ["blockquote","code-block"],
          [{"header":1}, {"header":2}],
          [{"list":"ordered"},{"list":"bullet"}],
          [{"script":"sub"},{"script":"super"}],
          [{"indent":"-1"},{"indent":"+1"}],
          [{"direction":"rtl"}],
          [{"size":["small",false,"large","huge"]}],
          [{"header":[1,2,3,4,5,6,false]}],
          [{"color":[]},{"background":[]}],
          [{"font":[]}],
          [{"align":[]}],
        ],
      },
      placeholder:i18n.t("tickets.placeholders.description")
    })

    this.quill.getModule("toolbar").addHandler("image",this.handleQuillImageUpload)
    this.quill.on("text-change",this.handleQuillChange)
	}

  @action("Handle the text-change og quill module") handleQuillChange= () => {
    const content = this.quill.root.innerHTML;
    console.log(content)
    this.ticket.description = content
  }

  @action("Handle the upload of the image by quill module") handleQuillImageUpload = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file")
    input.setAttribute("accept","image/*")
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append("image",file);

      let res = await this.rootStore.requestStore.uploadImage(formData);
      console.log(res)
      //let imageData = await res.blob()
      //let imageData = await res.data.arrayBuffer();
      let blob = new Blob([res.image.data], {type: res.image.headers.get("Content-Type")})
      console.log(blob)
      let imageUrl = URL.createObjectURL(blob)
      console.log(imageUrl)
      let range = this.quill.getSelection();
      this.quill.insertEmbed(range.index, "image",imageUrl)
      console.log(document.getElementById("editor").children[0])
      document.getElementById("editor").children[0].lastChild.lastChild.src = imageUrl

      this.ticket.images.push(res.url)
    }
  }

}

export default TicketsStore;
