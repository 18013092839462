import { observable, action } from "mobx";

class WorkingRegionStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable users;
  @observable regions;
  @observable newAssigned;
  @observable myRegions;
  @observable selectedUser;
  @observable initialized;
  @observable showAlert = false;
  @observable confirmAlert = false;
  @observable regionSearch = "";

  @action("Initialize Store") initializeStore = async () => {
    try {
      if (this.rootStore.userStore.user.isSuperuser) {
        this.users = (
          await this.rootStore.requestStore.getAllUsers({
            projection: "e$d$f$i",
            limit: "9999999999",
            sort: "d",
          })
        ).data;
      } else {
        this.users = (
          await this.rootStore.requestStore.getAllUsersExceptSuperusers({
            projection: "e$d$f$i",
            limit: "9999999999",
            sort: "d",
          })
        ).data;
      }

      this.selectedUser = this.users[0];

      console.log(this.selectedUser);
      if (this.selectedUser.assignedRegions.length > 0) {
        this.myRegions = (
          await this.rootStore.requestStore.getRegions({
            q: "in/$/" + this.selectedUser.assignedRegions,
            limit: "9999999999",
            projection: "q$f$h$j",
          })
        ).data;
      } else this.myRegions = [];

      // this.myRegions = this.myRegions.data;

      this.regions = (
        await this.rootStore.requestStore.getRegions({
          limit: "9999999999",
          projection: "q$f$h$j",
        })
      ).data;

      let userSelect = document.getElementById("userSelect").options;
      this.users.forEach((user) => {
        userSelect.add(new Option(user.username, user._id, false));
      });
      this.regionSearch = "";
      this.initialized = true;
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("update property not in form") updateProperty(key, value) {
    this[key] = value;
  }

  @action("Select user and change myRegions") changeUserSelected = async () => {
    try {
      let toSelect = document.getElementById("userSelect").value;
      this.selectedUser = (
        await this.rootStore.requestStore.getUserById(toSelect, {
          projection: "e$d$f$i",
          limit: "9999999999",
        })
      ).data;

      if (this.selectedUser.assignedRegions.length > 0) {
        this.myRegions = (
          await this.rootStore.requestStore.getRegions({
            q: "in/$/" + this.selectedUser.assignedRegions,
            limit: "9999999999",
            projection: "q$f$h$j",
          })
        ).data;
      } else this.myRegions = [];
      // this.myRegions = this.myRegions.data;
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("Select Region") selectRegionToMine = async (target) => {
    try {
      this.selectedUser.assignedRegions.push(
        target.parentElement.children[1].id
      );

      let res = await this.rootStore.requestStore.updateUserData(
        this.selectedUser._id,
        { assignedRegions: this.selectedUser.assignedRegions },
        this.selectedUser.username
      );

      if (res === undefined) {
        this.updateProperty("showAlert", true);
        document.getElementById("danger-alert-workingRegion").innerHTML =
          "La regió no s'ha pogut afegir. Siusplau, intenti-ho més tard.";
        setTimeout(() => {
          this.updateProperty("showAlert", false);
        }, 5000);
      } else {
        this.myRegions = (
          await this.rootStore.requestStore.getRegions({
            q: "in/$/" + this.selectedUser.assignedRegions,
            limit: "9999999999",
            projection: "q$f$h$j",
          })
        ).data;

        this.updateProperty("confirmAlert", true);
        document.getElementById("succes-alert-workingRegion").innerHTML =
          "La regió s'ha afegit correctament.";
        setTimeout(() => {
          this.updateProperty("confirmAlert", false);
        }, 5000);
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("Deselect Region") deselectRegion = async (target) => {
    try {
      this.selectedUser.assignedRegions.remove(
        target.parentElement.children[1].id
      );

      let res = await this.rootStore.requestStore.updateUserData(
        this.selectedUser._id,
        { assignedRegions: this.selectedUser.assignedRegions },
        this.selectedUser.username
      );

      if (res === undefined) {
        this.updateProperty("showAlert", true);
        document.getElementById("danger-alert-workingRegion").innerHTML =
          "La regió no s'ha pogut eliminar. Siusplau, intenti-ho més tard.";
        setTimeout(() => {
          this.updateProperty("showAlert", false);
        }, 5000);
      } else {
        this.updateProperty("confirmAlert", true);
        document.getElementById("succes-alert-workingRegion").innerHTML =
          "La regió s'ha eliminat correctament.";
        setTimeout(() => {
          this.updateProperty("confirmAlert", false);
        }, 5000);
        if (this.selectedUser.assignedRegions.length > 0) {
          this.myRegions = (
            await this.rootStore.requestStore.getRegions({
              q: "in/$/" + this.selectedUser.assignedRegions,
              limit: "9999999999",
              projection: "q$f$h$j",
            })
          ).data;
        } else this.myRegions = [];
        // this.myRegions = this.regions.data
        this.rootStore.requestStore.updateUserData(
          this.selectedUser._id,
          {
            assignedRegions: this.selectedUser.assignedRegions,
          },
          this.selectedUser.username
        );
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("Deselect Region") getSuggestedRegions = async () => {
    try {
      const inputValue = this.regionSearch.trim().toLowerCase();
      const inputLength = inputValue.length;
      if (inputLength > 0) {
        this.regions = await this.rootStore.requestStore.searchRegionsByName(
          inputValue,
          false
        );
      } else {
        this.regions = (
          await this.rootStore.requestStore.getRegions({
            limit: "9999999999",
            projection: "q$f$h$j",
          })
        ).data;
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("Thing") updateSupervisor = async () => {
    try {
      this.selectedUser.isSupervisor =
        document.getElementById("supervisorCheckbox").checked;
      await this.rootStore.requestStore.updateUserData(
        this.selectedUser._id,
        { isSupervisor: this.selectedUser.isSupervisor },
        this.selectedUser.username
      );
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("Open Working Area") openWA = (id) => {
      this.rootStore.navigationStore.openInNewTab(
        "area?focusedRegion=" + id
      );
  }
}
export default WorkingRegionStore;
