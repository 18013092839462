import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import MyButton from "../basicUI/MyButton";

@inject(
  "userStore",
  "groupAdsStore",
  "navigationStore",
  "sessionStore"
)
@observer
class AssingAds extends Component {
  async componentDidMount() {
    try {
      //await this.props.groupAdsStore.initializeAssign()

    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  componentWillUnmount() {
    this.props.groupAdsStore.updateProperty("showAlertAssign", false);
    this.props.groupAdsStore.updateProperty("confirmAlertAssign", false);
    this.props.groupAdsStore.updateProperty("usersSameRegion", [])
  }

  render() {
    const { t } = this.props;
    if (this.props.sessionStore.initialized) {
      return (
        <>
          {this.props.groupAdsStore.showAlertAssign ?
            <div
              className={
                "alert alert-danger" +
                (!!this.props.groupAdsStore.showAlertAssign
                  ? " alert-custom show"
                  : " alert-custom hide")
              }
            >
              <p id="danger-alert-stats"></p>
              <button
                type="button"
                className="close"
                onClick={() => {
                  this.props.groupAdsStore.updateProperty("showAlertAssign", false);
                }}
              >
                x
              </button>
            </div>
            : null}
          {this.props.groupAdsStore.confirmAlertAssign ?
            <div
              className={
                "alert alert-success" +
                (!!this.props.groupAdsStore.confirmAlertAssign
                  ? " alert-custom show"
                  : " alert-custom hide")
              }
            >
              <p id="succes-alert-stats"></p>
              <button
                type="button"
                className="close"
                onClick={() => {
                  this.props.groupAdsStore.updateProperty("confirmAlertAssign", false);
                }}
              >
                x
              </button>
            </div>
            : null}
          <div className="area-quick-search">
            <OverlayTrigger
              delay={{ show: 500, hide: 100 }}
              placement="top"
              overlay={<Tooltip id="tooltip">{t("ga.assignAds.fastSearch")}</Tooltip>}
            >
              <button
                type="button"
                className="btn btn-outline-info edit-group"
                data-toggle="modal"
                data-target=".area-select-modal"
                data-backdrop="false"
                id="new-group1"
                name={t(
                  "ga.head.assignAdButton"
                )}
                onClick={() => { this.props.groupAdsStore.initializeAssign() }}
              >
                {t("ga.head.assignAdButton")}
              </button>

            </OverlayTrigger>
            <div
              className="modal fade area-select-modal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="AreaSelectModal"
              aria-hidden="true"
              
              
            >
              <div className="modal-dialog" style={{minWidth:"35rem"}}>
                <div className="modal-content" >
                  <div className="row modal-header">
                    <div className="col-md-10">
                      <h5 className="modal-title">{t("ga.assignAds.first")}</h5>
                    </div>
                    <div className="col-md-2">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                  <div className="modal-body">
                    <div
                      className="accordion md-accordion accordionAreas"
                      id="accordionAreas"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      {this.props.groupAdsStore.usersSameRegion.map((user, i) => {
                        //if (!user.isSupervisor || user.username === this.props.userStore.user.username)
                        return (
                          <div
                            key={"Card Start-" + i}
                            className="team-table"

                          ><div
                            className="card-head">
                              <div
                                className="row">

                                <div className="col-md-6">{user.username}</div>
                                <div className="col-md-6">
                                  <MyButton
                                  id="button-go-adgroup"
                                  type="button"
                                  className={this.props.workingUser === null || (this.props.workingUser instanceof Array && !this.props.workingUser.includes(user.username)) ? "btn btn-assign-ads" : "btn btn-remove-assign-ads"}
                                  text={
                                    this.props.workingUser === null || (this.props.workingUser instanceof Array && !this.props.workingUser.includes(user.username)) ? t("ga.assignAds.assign") : t("ga.assignAds.remove")}
                                  onClick={() => {
                                    //this.props.groupAdsStore.assignAdsToAnotherUser(user.username)
                                    this.props.groupAdsStore.toggleWorking(this.props.id, this.props.workingUser, user.username)
                                    //tancar modal
                                    let body = document.getElementById("boop");
                                    body.className = "";
                                  }
                                  } /></div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    return null;
  }
}
export default withTranslation()(AssingAds);