import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import mapLight from "../../assets/img/maps/Gris.png";
import mapStreets from "../../assets/img/maps/Relleu.png";
import mapSatellite from "../../assets/img/maps/Fosc.png";
import mapDark from "../../assets/img/maps/Dark.png";
import Chart from "./Chart";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { withTranslation } from "react-i18next";

const { BroadcastChannel } = require("broadcast-channel");
const channelTab = new BroadcastChannel("tab");

@inject("mapListStore", "sessionStore", "navigationStore")
@observer
class Map extends Component {
  async componentDidMount() {
    try {
      await this.props.mapListStore.initializeUser();
      await this.props.mapListStore.createIDiv();
      await this.props.mapListStore.setMap();
      await this.props.mapListStore.initializeStore();
      window.addEventListener("popstate", this.props.mapListStore.urlChange);

      document.addEventListener("mousemove", (e) => {
        this.props.mapListStore.mousePosition(e);
      });
      channelTab.addEventListener("message", async (event) => {
        await this.props.mapListStore.newTab(
          event.id,
          event.name,
          event.adm,
          true
        );
      });
      // this.props.mapListStore.updateProperty("initializingComponent", false);
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
    // await this.props.mapListStore.getChildRegionsData();
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.props.mapListStore.urlChange);
    document.removeEventListener("mousemove", (e) => {
      this.props.mapListStore.mousePosition(e);
    });
    channelTab.removeEventListener("message", async (event) => {
      this.props.mapListStore.newTab(event.id, event.name, event.adm, true);
    });
    //channelTab.close()
    //this.props.mapListStore.iDiv = undefined
    this.props.mapListStore.deleteMap();
  }

  render() {
    const { t } = this.props;
    if (this.props.sessionStore.initialized) {
      return (
        <>
          <div className="col-md-6 area-map" id="area-map">
            <div className="row map-container" id="map">
              <div className="btn-map">
                <div className="btn-group-vertical" id="custom-map-controls">
                  <button
                    onClick={() => {
                      this.props.mapListStore.zoomIn();
                    }}
                    id="zoomIn"
                    type="button"
                    className="btn btn-outline-secondary btn-bck-color"
                  >
                    <svg
                      className="svg-icon-size-map"
                      data-name="fas fa-plus"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 55.93 55.87"
                    >
                      <path
                        className="cls-1"
                        d="M42.7,67.94c-.9.11-1.8,0-2.7.07-.66,0-1.31,0-2,0a4,4,0,0,1-3.2-1.59A4.15,4.15,0,0,1,34,64V48c0-.58,0-1.17,0-1.75,0-.17-.05-.24-.23-.23H16a4,4,0,0,1-3.9-4.15c0-.6,0-1.21,0-1.82.05-.92,0-1.85.06-2.77a8,8,0,0,1,.46-1.24,4.29,4.29,0,0,1,2.15-1.76,8.93,8.93,0,0,1,1.35-.26c.79.07,1.59,0,2.39,0A9,9,0,0,0,20,34h4a6.75,6.75,0,0,0,1.27,0c.92,0,1.83,0,2.75,0s1.57,0,2.35,0A13.12,13.12,0,0,0,32,34a17.83,17.83,0,0,0,1.79,0c.17,0,.19-.1.19-.23,0-.6,0-1.19,0-1.79v-16c0-.17,0-.34,0-.51A4.11,4.11,0,0,1,36,12.66l1-.41A28.36,28.36,0,0,1,40,12.13l2.25,0a3.17,3.17,0,0,1,1.1.22A4,4,0,0,1,46,16.1v16c0,.58,0,1.16,0,1.74,0,.21.07.27.27.26H48c.82.07,1.64,0,2.47,0A9,9,0,0,0,52,34h4a9,9,0,0,0,1.55,0c.83,0,1.65,0,2.47,0h3.93l.36.05a4,4,0,0,1,3.61,3.37c.12.87,0,1.75.06,2.61s.05,1.9-.08,2.85L67.45,44a4.15,4.15,0,0,1-3.51,2H48l-1.72,0c-.21,0-.26.07-.26.27,0,.57,0,1.14,0,1.71V64a4.11,4.11,0,0,1-2,3.46A6.53,6.53,0,0,1,42.7,67.94Z"
                        transform="translate(-12.04 -12.13)"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      this.props.mapListStore.zoomOut();
                    }}
                    id="zoomOut"
                    type="button"
                    className="btn btn-outline-secondary btn-bck-color"
                  >
                    <svg
                      className="svg-icon-size-map"
                      data-name="fas fa-minus"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 56 12"
                    >
                      <path
                        className="cls-1"
                        d="M15,34a2.94,2.94,0,0,0-3,3v6a2.94,2.94,0,0,0,3,3H65a2.94,2.94,0,0,0,3-3V37a2.94,2.94,0,0,0-3-3Z"
                        transform="translate(-12 -34)"
                      />
                    </svg>
                  </button>
                </div>
                <br />
                <br />
                <OverlayTrigger
                  delay={{ show: 500, hide: 100 }}
                  placement="left"
                  overlay={
                    <Tooltip id="tooltip">{t("wa.map.uncheck")}</Tooltip>
                  }
                >
                  <button
                    onClick={() => {
                      this.props.mapListStore.deselectMarkers();
                    }}
                    id="flt-unpined"
                    type="button"
                    className="btn btn-outline-secondary btn-bck-color"
                  >
                    <svg
                      className="svg-icon-pin-map"
                      data-name="fas fa-map-pin"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 36 64"
                    >
                      <circle className="cls-1" cx="18" cy="18" r="18" />
                      <path
                        className="cls-2"
                        d="M32,26a2,2,0,0,1-4,0s0-11,12-12a2,2,0,0,1,0,4s-7,0-8,8"
                        transform="translate(-22 -8)"
                      />
                      <path
                        className="cls-1"
                        d="M36,48a16.94,16.94,0,0,0,8,0V67l-1.64,3.27a3.17,3.17,0,0,1-1.49,1.51,1.83,1.83,0,0,1-1.74,0,3.17,3.17,0,0,1-1.49-1.51L36,67Z"
                        transform="translate(-22 -8)"
                      />
                    </svg>
                  </button>
                </OverlayTrigger>
                <br />
                <br />
                <OverlayTrigger
                  delay={{ show: 500, hide: 100 }}
                  placement="left"
                  overlay={<Tooltip id="tooltip">{t("wa.map.gmaps")}</Tooltip>}
                >
                  <button
                    className="btn btn-outline-info button-google"
                    onClick={() => {
                      let center = this.props.mapListStore.map.getCenter();
                      this.props.navigationStore.openInNewTab(
                        "https://www.google.es/maps/@" +
                          center.lat +
                          "," +
                          center.lng +
                          "," +
                          this.props.mapListStore.map.getZoom() +
                          "z"
                      );
                    }}
                  >
                    <img
                      className="google-maps-img"
                      src="/logos/google-maps.png"
                      alt="mapgoogle"
                    ></img>
                  </button>
                </OverlayTrigger>
                <br />
                <br />
                <OverlayTrigger
                  delay={{ show: 500, hide: 100 }}
                  placement="left"
                  overlay={<Tooltip id="tooltip">{t("wa.map.gmaps")}</Tooltip>}
                >
                  <button
                    className="btn btn-outline-info button-bing"
                    onClick={() => {
                      let center = this.props.mapListStore.map.getCenter();
                      this.props.navigationStore.openInNewTab(
                        "https://www.bing.com/maps?cp=" +
                          center.lat +
                          "~" +
                          center.lng +
                          "&lvl=" +
                          this.props.mapListStore.map.getZoom()
                      );
                    }}
                  >
                    <img
                      className="bing-maps-img"
                      src="/logos/Bing_Fluent_Logo.svg"
                      alt="mapbing"
                    ></img>
                  </button>
                </OverlayTrigger>
                <br />
                <br />
                {/*<OverlayTrigger
                  delay={{ show: 500, hide: 100 }}
                  placement="left"
                  overlay={<Tooltip id="tooltip">{t("wa.map.chart")}</Tooltip>}
                >
                  <button
                    className="btn btn-outline-info button-google"
                    onClick={() => {
                      this.props.mapListStore.hideChart =
                        !this.props.mapListStore.hideChart;
                    }}
                  >
                    <img
                      className="google-maps-img"
                      src="/logos/donut.jpeg"
                      alt="chart"
                    ></img>
                  </button>
                  </OverlayTrigger>*/}
              </div>

              <div className="map-mode-dropdown">
                <OverlayTrigger
                  delay={{ show: 500, hide: 100 }}
                  placement="right"
                  overlay={<Tooltip id="tooltip">{t("wa.map.style")}</Tooltip>}
                >
                  <button
                    id="basemaps"
                    className="btn btn-outline-info btn-bck-color map-mode-dropbtn"
                  >
                    {" "}
                    <svg
                      className="svg-icon-map-mode"
                      data-name="fas fa-map"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 72 56.32"
                    >
                      <path
                        className="cls-1"
                        d="M24,12V60L7,68s-3,1-3-2V23a4.37,4.37,0,0,1,3-4C11.24,17.59,24,12,24,12Z"
                        transform="translate(-4 -11.84)"
                      />
                      <polygon
                        className="cls-1"
                        points="24 0.16 24 48.16 48 56.16 48 8.16 24 0.16"
                      />
                      <path
                        className="cls-1"
                        d="M56,20l17-8s3-1,3,2V57s0,3-2,4-18,7-18,7Z"
                        transform="translate(-4 -11.84)"
                      />
                    </svg>
                  </button>
                </OverlayTrigger>
                <div className="map-mode-dropdown-content">
                  <button
                    onClick={() => {
                      this.props.mapListStore.setBasemap("mapbox/light-v10");
                    }}
                  >
                    <img id="map-mode-light" src={mapLight} alt="map-light" />
                  </button>
                  <button
                    onClick={(e) => {
                      this.props.mapListStore.setBasemap("mapbox/streets-v11");
                    }}
                  >
                    <img
                      id="map-mode-relief"
                      src={mapStreets}
                      alt="map-streets"
                    />
                  </button>
                  <button
                    onClick={(e) => {
                      this.props.mapListStore.setBasemap(
                        "mapbox/satellite-streets-v11"
                      );
                    }}
                  >
                    <img
                      id="map-mode-relief-dark"
                      src={mapSatellite}
                      alt="map-satellite"
                    />
                  </button>
                  <button
                    onClick={(e) => {
                      this.props.mapListStore.setBasemap("mapbox/dark-v10");
                    }}
                  >
                    <img id="map-mode-dark" src={mapDark} alt="map-dark" />
                  </button>
                </div>
              </div>

              {this.props.mapListStore.license === true ? (
                <div className="map-legend-dropdown license">
                  <button
                    id="basemaps"
                    className="btn btn-outline-info btn-bck-color map-legend-dropbtn"
                  >
                    <svg
                      className="fa fa-circle oficial"
                      style={{
                        height: "0.5rem",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                    <span>{t("wa.official")}</span> <br />
                    <svg
                      className="fa fa-circle badFormat"
                      style={{
                        height: "0.5rem",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                    <span>{t("wa.badFormat")}</span> <br />
                    <svg
                      className="fa fa-circle noDB"
                      style={{
                        height: "0.5rem",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                    <span>{t("wa.noOfficial")}</span> <br />
                    <svg
                      className="fa fa-circle default"
                      style={{
                        height: "0.5rem",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                    <span>{t("wa.noLicense")}</span> <br />
                    {/*<svg
                      className="fa fa-circle owner"
                      style={{
                        height: "0.5rem",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                    <span>{t("ga.map.inspector")}</span> <br />*/}
                  </button>
                </div>
              ) : null}

              {this.props.mapListStore.website === true ? (
                <div className="map-legend-dropdown platform">
                  <button
                    id="basemaps"
                    className="btn btn-outline-info btn-bck-color map-legend-dropbtn"
                  >
                    <svg
                      className="fa fa-circle airbnb"
                      style={{
                        height: "0.5rem",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                    <span>Airbnb</span> <br />
                    <svg
                      className="fa fa-circle homeaway"
                      style={{
                        height: "0.5rem",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                    <span>HomeAway</span> <br />
                    <svg
                      className="fa fa-circle niumba"
                      style={{
                        height: "0.5rem",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                    <span>Niumba</span> <br />
                    <svg
                      className="fa fa-circle rentalia"
                      style={{
                        height: "0.5rem",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                    <span>Rentalia</span> <br />
                    <svg
                      className="fa fa-circle housetrip"
                      style={{
                        height: "0.5rem",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                    <span>HouseTrip</span> <br />
                    <svg
                      className="fa fa-circle booking"
                      style={{
                        height: "0.5rem",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                    <span>Booking</span> <br />
                    <svg
                      className="fa fa-circle tripadvisor"
                      style={{
                        height: "0.5rem",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                    <span>Tripadvisor</span> <br />
                    <svg
                      className="fa fa-circle default"
                      style={{
                        height: "0.5rem",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                    <span>others</span> <br />
                    {/*<svg
                      className="fa fa-circle owner"
                      style={{
                        height: "0.5rem",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                    <span>{t("ga.map.inspector")}</span> <br />*/}
                  </button>
                </div>
              ) : null}

              {this.props.mapListStore.roomtype === true ? (
                <div className="map-legend-dropdown type_room">
                  <button
                    id="basemaps"
                    className="btn btn-outline-info btn-bck-color map-legend-dropbtn"
                  >
                    <svg
                      className="fa fa-circle shared_room"
                      style={{
                        height: "0.5rem",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                    <span>{t("wa.map.shared")}</span> <br />
                    <svg
                      className="fa fa-circle private_room"
                      style={{
                        height: "0.5rem",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                    <span>{t("wa.map.private")}</span> <br />
                    <svg
                      className="fa fa-circle entire_home"
                      style={{
                        height: "0.5rem",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                    <span>{t("wa.map.home")}</span> <br />
                    <svg
                      className="fa fa-circle default"
                      style={{
                        height: "0.5rem",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                    <span>{t("wa.map.others")}</span> <br />
                    {/*<svg
                      className="fa fa-circle owner"
                      style={{
                        height: "0.5rem",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      ></path>
                    </svg>
                    <span>{t("ga.map.inspector")}</span> <br />*/}
                  </button>
                </div>
              ) : null}

              {/*!this.props.mapListStore.hideChart ? <Chart /> : ""*/}
            </div>
            {this.props.mapListStore.focusedRegion ? (
              null
              // <div className="row bottom-data" id="bottom-data">
              //   <table id="bottom-data-table" className="bottom-data-table">
              //     <tbody>
              //       <tr>
              //         <td className="under-info">
              //           <p id="info-1">
              //             {this.props.mapListStore.focusedRegion.numAds !==
              //             undefined
              //               ? Math.round(
              //                   this.props.mapListStore.focusedRegion.numAds *
              //                     100
              //                 ) / 100
              //               : "-"}
              //           </p>
              //           <OverlayTrigger
              //             delay={{ show: 500, hide: 100 }}
              //             placement="right"
              //             overlay={
              //               <Tooltip id="tooltip">{t("wa.map.ads")}</Tooltip>
              //             }
              //           >
              //             <svg
              //               className="svg-icon-bottom-data-table"
              //               data-name="fas fa-home"
              //               xmlns="http://www.w3.org/2000/svg"
              //               viewBox="0 0 79.56 61.92"
              //             >
              //               <path
              //                 className="cls-1"
              //                 d="M53.57,34.54c1.28,1.1,2.58,2.16,3.9,3.21.49.43,1,.85,1.51,1.23,1,.84,1.93,1.64,2.93,2.42.76.67,1.55,1.32,2.35,1.93.65.58,1.31,1.13,2,1.65l0,2.8c0,1.48,0,3,0,4.45s0,3,0,4.43,0,2.92,0,4.37v4.44c0,.93,0,1.86,0,2.79a2.54,2.54,0,0,1-1.68,1.59c-.89.11-1.79,0-2.68.07H57.47a.65.65,0,0,0-.39,0c-1.36,0-2.71,0-4.06,0s-3,0-4.44,0h-.4a2.3,2.3,0,0,1-1.78-2.55c0-.6,0-1.21,0-1.82v-8.8c0-.78,0-1.56,0-2.34a2.16,2.16,0,0,0-2.18-2.1H35.32a2.15,2.15,0,0,0-2.17,2.1c0,.78,0,1.56,0,2.34v8.8c0,.56,0,1.13,0,1.69a2.43,2.43,0,0,1-1.71,2.66l-.48,0c-.48,0-1,0-1.44,0-1,0-2,0-3,0-1.48-.07-3,0-4.44,0a.86.86,0,0,0-.23,0l-4.21.06H15l-.09-.07-.15,0a2.62,2.62,0,0,1-1.46-1.37c0-1,0-2,0-3,0-1.49,0-3,0-4.45s0-2.91,0-4.36,0-3,0-4.45V47.78c0-.93,0-1.86,0-2.8a24.33,24.33,0,0,0,2-1.65c.85-.63,1.65-1.31,2.45-2,1-.75,1.92-1.52,2.84-2.34a16.31,16.31,0,0,0,1.51-1.24q1.93-1.52,3.8-3.13a3.07,3.07,0,0,0,.66-.53l.91-.73L31,30.42a1.26,1.26,0,0,0,.4-.33C32.71,29,34,28,35.32,26.84a12.73,12.73,0,0,0,1.33-1.1c.64-.52,1.29-1,1.93-1.56a2,2,0,0,1,1.19-.5,2,2,0,0,1,1.19.5"
              //                 transform="translate(0 -8)"
              //               />
              //               <path
              //                 className="cls-1"
              //                 d="M56.35,9.81a1.67,1.67,0,0,1,.77-1.54,2,2,0,0,1,1-.25l3.8,0,3.55,0a3.64,3.64,0,0,1,.8.06l.09,0a1.77,1.77,0,0,1,1,1.82c0,.82,0,1.64,0,2.46V25.72c0,.41,0,.81,0,1.21a4.43,4.43,0,0,0,.08,1.83c.27.54,1,.87,1.45,1.29l.07,0c.55.49,1.11,1,1.7,1.4l3.56,3c1.47,1.21,2.92,2.44,4.42,3.63a2,2,0,0,1,.83,2.07,10.93,10.93,0,0,1-.76,1c-.59.71-1.16,1.44-1.74,2.16l-.21.23a11.21,11.21,0,0,1-1.64,1.8,1.86,1.86,0,0,1-2-.37c-.87-.74-1.76-1.45-2.64-2.17l-4.21-3.44a2.86,2.86,0,0,0-.63-.52c-.23-.2-.46-.41-.7-.6-1-.83-2-1.65-3-2.47a19,19,0,0,0-1.55-1.27c-.94-.84-1.92-1.62-2.9-2.4L55,30.08A25.25,25.25,0,0,0,53,28.47Q51.39,27,49.66,25.71a6.92,6.92,0,0,0-1.07-.89l-3.62-3a2.85,2.85,0,0,0-.74-.55h0a1.17,1.17,0,0,0,0-.11c-1.13-.93-2.26-1.87-3.4-2.79a1.3,1.3,0,0,0-1-.32,1.69,1.69,0,0,0-1.21.47c-.4.33-.81.66-1.2,1a26.16,26.16,0,0,0-2,1.74v0l-.26.17-4.8,3.94-3.81,3.18c-.65.48-1.27,1-1.87,1.53l-2.49,2.05c-1,.78-2,1.56-2.9,2.4a18.66,18.66,0,0,0-1.55,1.27c-1.27,1-2.5,2-3.73,3.07a4.45,4.45,0,0,0-.71.59l-.35.26C10.7,41.47,8.56,43.21,6.45,45a1.94,1.94,0,0,1-2,.44,2.82,2.82,0,0,1-.71-.63L2.53,43.33l-.12-.16C1.66,42.19.8,41.29.1,40.26A3.07,3.07,0,0,1,0,39l.07-.16a14.7,14.7,0,0,1,2-1.73c.77-.67,1.57-1.31,2.36-2,.63-.52,1.27-1,1.9-1.56l2.48-2.11a11.91,11.91,0,0,0,1.62-1.29c1-.76,1.91-1.53,2.83-2.33s1.74-1.39,2.57-2.13a20.94,20.94,0,0,0,1.78-1.46c1.24-1,2.44-2,3.63-3a5.53,5.53,0,0,0,.83-.69c.63-.51,1.26-1,1.87-1.52.87-.73,1.79-1.4,2.61-2.19,1.51-1.15,3-2.37,4.4-3.61A8,8,0,0,0,32,12.4q1.68-1.32,3.31-2.73a7.86,7.86,0,0,1,3.14-1.56L39.77,8l1.14.1a7.33,7.33,0,0,1,3.3,1.6l.33.3,9.66,7.94,2.13,1.74"
              //                 transform="translate(0 -8)"
              //               />
              //             </svg>
              //           </OverlayTrigger>
              //           <span className="span-bottom-data-table">
              //             {t("wa.map.total")}
              //           </span>
              //         </td>
              //         <td className="under-info">
              //           <p id="info-2">
              //             {this.props.mapListStore.focusedRegion.capacity !==
              //             undefined
              //               ? Math.round(
              //                   this.props.mapListStore.focusedRegion.capacity *
              //                     100
              //                 ) / 100
              //               : "-"}
              //           </p>
              //           <OverlayTrigger
              //             delay={{ show: 500, hide: 100 }}
              //             placement="right"
              //             overlay={
              //               <Tooltip id="tooltip">
              //                 {t("wa.map.capacity")}
              //               </Tooltip>
              //             }
              //           >
              //             <svg
              //               className="svg-icon-bottom-data-table"
              //               data-name="fas fa-bed"
              //               xmlns="http://www.w3.org/2000/svg"
              //               viewBox="0 0 80 48"
              //             >
              //               <path
              //                 className="cls-1"
              //                 d="M8,57H72v6a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2V38s0-13-13-13H38a2,2,0,0,0-2,2V45H8V19a2,2,0,0,0-2-2H2a2,2,0,0,0-2,2V63a2,2,0,0,0,2,2H6a2,2,0,0,0,2-2Z"
              //                 transform="translate(0 -17)"
              //               />
              //               <circle className="cls-1" cx="22" cy="14" r="10" />
              //             </svg>
              //           </OverlayTrigger>
              //           <span className="span-bottom-data-table">
              //             {t("wa.map.total")}
              //           </span>
              //         </td>
              //         <td className="under-info">
              //           <p id="info-3">
              //             {this.props.mapListStore.focusedRegion.meanAdPrice !==
              //             undefined
              //               ? Math.round(
              //                   this.props.mapListStore.focusedRegion
              //                     .meanAdPrice * 100
              //                 ) / 100
              //               : "-"}
              //           </p>
              //           <OverlayTrigger
              //             delay={{ show: 500, hide: 100 }}
              //             placement="right"
              //             overlay={
              //               <Tooltip id="tooltip">{t("wa.map.rent")}</Tooltip>
              //             }
              //           >
              //             <svg
              //               className="svg-icon-bottom-data-table"
              //               data-name="fas fa-home"
              //               xmlns="http://www.w3.org/2000/svg"
              //               viewBox="0 0 79.56 61.92"
              //             >
              //               <path
              //                 className="cls-1"
              //                 d="M53.57,34.54c1.28,1.1,2.58,2.16,3.9,3.21.49.43,1,.85,1.51,1.23,1,.84,1.93,1.64,2.93,2.42.76.67,1.55,1.32,2.35,1.93.65.58,1.31,1.13,2,1.65l0,2.8c0,1.48,0,3,0,4.45s0,3,0,4.43,0,2.92,0,4.37v4.44c0,.93,0,1.86,0,2.79a2.54,2.54,0,0,1-1.68,1.59c-.89.11-1.79,0-2.68.07H57.47a.65.65,0,0,0-.39,0c-1.36,0-2.71,0-4.06,0s-3,0-4.44,0h-.4a2.3,2.3,0,0,1-1.78-2.55c0-.6,0-1.21,0-1.82v-8.8c0-.78,0-1.56,0-2.34a2.16,2.16,0,0,0-2.18-2.1H35.32a2.15,2.15,0,0,0-2.17,2.1c0,.78,0,1.56,0,2.34v8.8c0,.56,0,1.13,0,1.69a2.43,2.43,0,0,1-1.71,2.66l-.48,0c-.48,0-1,0-1.44,0-1,0-2,0-3,0-1.48-.07-3,0-4.44,0a.86.86,0,0,0-.23,0l-4.21.06H15l-.09-.07-.15,0a2.62,2.62,0,0,1-1.46-1.37c0-1,0-2,0-3,0-1.49,0-3,0-4.45s0-2.91,0-4.36,0-3,0-4.45V47.78c0-.93,0-1.86,0-2.8a24.33,24.33,0,0,0,2-1.65c.85-.63,1.65-1.31,2.45-2,1-.75,1.92-1.52,2.84-2.34a16.31,16.31,0,0,0,1.51-1.24q1.93-1.52,3.8-3.13a3.07,3.07,0,0,0,.66-.53l.91-.73L31,30.42a1.26,1.26,0,0,0,.4-.33C32.71,29,34,28,35.32,26.84a12.73,12.73,0,0,0,1.33-1.1c.64-.52,1.29-1,1.93-1.56a2,2,0,0,1,1.19-.5,2,2,0,0,1,1.19.5"
              //                 transform="translate(0 -8)"
              //               />
              //               <path
              //                 className="cls-1"
              //                 d="M56.35,9.81a1.67,1.67,0,0,1,.77-1.54,2,2,0,0,1,1-.25l3.8,0,3.55,0a3.64,3.64,0,0,1,.8.06l.09,0a1.77,1.77,0,0,1,1,1.82c0,.82,0,1.64,0,2.46V25.72c0,.41,0,.81,0,1.21a4.43,4.43,0,0,0,.08,1.83c.27.54,1,.87,1.45,1.29l.07,0c.55.49,1.11,1,1.7,1.4l3.56,3c1.47,1.21,2.92,2.44,4.42,3.63a2,2,0,0,1,.83,2.07,10.93,10.93,0,0,1-.76,1c-.59.71-1.16,1.44-1.74,2.16l-.21.23a11.21,11.21,0,0,1-1.64,1.8,1.86,1.86,0,0,1-2-.37c-.87-.74-1.76-1.45-2.64-2.17l-4.21-3.44a2.86,2.86,0,0,0-.63-.52c-.23-.2-.46-.41-.7-.6-1-.83-2-1.65-3-2.47a19,19,0,0,0-1.55-1.27c-.94-.84-1.92-1.62-2.9-2.4L55,30.08A25.25,25.25,0,0,0,53,28.47Q51.39,27,49.66,25.71a6.92,6.92,0,0,0-1.07-.89l-3.62-3a2.85,2.85,0,0,0-.74-.55h0a1.17,1.17,0,0,0,0-.11c-1.13-.93-2.26-1.87-3.4-2.79a1.3,1.3,0,0,0-1-.32,1.69,1.69,0,0,0-1.21.47c-.4.33-.81.66-1.2,1a26.16,26.16,0,0,0-2,1.74v0l-.26.17-4.8,3.94-3.81,3.18c-.65.48-1.27,1-1.87,1.53l-2.49,2.05c-1,.78-2,1.56-2.9,2.4a18.66,18.66,0,0,0-1.55,1.27c-1.27,1-2.5,2-3.73,3.07a4.45,4.45,0,0,0-.71.59l-.35.26C10.7,41.47,8.56,43.21,6.45,45a1.94,1.94,0,0,1-2,.44,2.82,2.82,0,0,1-.71-.63L2.53,43.33l-.12-.16C1.66,42.19.8,41.29.1,40.26A3.07,3.07,0,0,1,0,39l.07-.16a14.7,14.7,0,0,1,2-1.73c.77-.67,1.57-1.31,2.36-2,.63-.52,1.27-1,1.9-1.56l2.48-2.11a11.91,11.91,0,0,0,1.62-1.29c1-.76,1.91-1.53,2.83-2.33s1.74-1.39,2.57-2.13a20.94,20.94,0,0,0,1.78-1.46c1.24-1,2.44-2,3.63-3a5.53,5.53,0,0,0,.83-.69c.63-.51,1.26-1,1.87-1.52.87-.73,1.79-1.4,2.61-2.19,1.51-1.15,3-2.37,4.4-3.61A8,8,0,0,0,32,12.4q1.68-1.32,3.31-2.73a7.86,7.86,0,0,1,3.14-1.56L39.77,8l1.14.1a7.33,7.33,0,0,1,3.3,1.6l.33.3,9.66,7.94,2.13,1.74"
              //                 transform="translate(0 -8)"
              //               />
              //             </svg>
              //           </OverlayTrigger>
              //           <span className="span-bottom-data-table">
              //             {t("wa.map.price")}
              //             <br />
              //             {t("wa.map.medium")}
              //           </span>
              //         </td>
              //         <td className="under-info">
              //           <p id="info-4">
              //             {this.props.mapListStore.focusedRegion
              //               .pricePerPerson !== undefined
              //               ? Math.round(
              //                   this.props.mapListStore.focusedRegion
              //                     .pricePerPerson * 100
              //                 ) / 100
              //               : "-"}
              //           </p>
              //           <OverlayTrigger
              //             delay={{ show: 500, hide: 100 }}
              //             placement="right"
              //             overlay={
              //               <Tooltip id="tooltip">{t("wa.map.person")}</Tooltip>
              //             }
              //           >
              //             <svg
              //               className="svg-icon-bottom-data-table"
              //               data-name="fas fa-bed"
              //               xmlns="http://www.w3.org/2000/svg"
              //               viewBox="0 0 80 48"
              //             >
              //               <path
              //                 className="cls-1"
              //                 d="M8,57H72v6a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2V38s0-13-13-13H38a2,2,0,0,0-2,2V45H8V19a2,2,0,0,0-2-2H2a2,2,0,0,0-2,2V63a2,2,0,0,0,2,2H6a2,2,0,0,0,2-2Z"
              //                 transform="translate(0 -17)"
              //               />
              //               <circle className="cls-1" cx="22" cy="14" r="10" />
              //             </svg>
              //           </OverlayTrigger>
              //           <span className="span-bottom-data-table">
              //             {t("wa.map.price")}
              //             <br />
              //             {t("wa.map.medium")}
              //           </span>
              //         </td>
              //         <td className="under-info">
              //           <p id="info-5">
              //             {this.props.mapListStore.focusedRegion.men !==
              //               undefined &&
              //             this.props.mapListStore.focusedRegion.women !==
              //               undefined &&
              //             this.props.mapListStore.focusedRegion.numAds !==
              //               undefined
              //               ? Math.round(
              //                   (this.props.mapListStore.focusedRegion.numAds /
              //                     (this.props.mapListStore.focusedRegion.men +
              //                       this.props.mapListStore.focusedRegion
              //                         .women)) *
              //                     1000 *
              //                     100
              //                 ) / 100
              //               : "-"}
              //           </p>
              //           <OverlayTrigger
              //             delay={{ show: 500, hide: 100 }}
              //             placement="right"
              //             overlay={
              //               <Tooltip id="tooltip">
              //                 {t("wa.map.adDensity")}
              //               </Tooltip>
              //             }
              //           >
              //             <svg
              //               className="svg-icon-bottom-data-table"
              //               data-name="fas fa-home"
              //               xmlns="http://www.w3.org/2000/svg"
              //               viewBox="0 0 79.56 61.92"
              //             >
              //               <path
              //                 className="cls-1"
              //                 d="M53.57,34.54c1.28,1.1,2.58,2.16,3.9,3.21.49.43,1,.85,1.51,1.23,1,.84,1.93,1.64,2.93,2.42.76.67,1.55,1.32,2.35,1.93.65.58,1.31,1.13,2,1.65l0,2.8c0,1.48,0,3,0,4.45s0,3,0,4.43,0,2.92,0,4.37v4.44c0,.93,0,1.86,0,2.79a2.54,2.54,0,0,1-1.68,1.59c-.89.11-1.79,0-2.68.07H57.47a.65.65,0,0,0-.39,0c-1.36,0-2.71,0-4.06,0s-3,0-4.44,0h-.4a2.3,2.3,0,0,1-1.78-2.55c0-.6,0-1.21,0-1.82v-8.8c0-.78,0-1.56,0-2.34a2.16,2.16,0,0,0-2.18-2.1H35.32a2.15,2.15,0,0,0-2.17,2.1c0,.78,0,1.56,0,2.34v8.8c0,.56,0,1.13,0,1.69a2.43,2.43,0,0,1-1.71,2.66l-.48,0c-.48,0-1,0-1.44,0-1,0-2,0-3,0-1.48-.07-3,0-4.44,0a.86.86,0,0,0-.23,0l-4.21.06H15l-.09-.07-.15,0a2.62,2.62,0,0,1-1.46-1.37c0-1,0-2,0-3,0-1.49,0-3,0-4.45s0-2.91,0-4.36,0-3,0-4.45V47.78c0-.93,0-1.86,0-2.8a24.33,24.33,0,0,0,2-1.65c.85-.63,1.65-1.31,2.45-2,1-.75,1.92-1.52,2.84-2.34a16.31,16.31,0,0,0,1.51-1.24q1.93-1.52,3.8-3.13a3.07,3.07,0,0,0,.66-.53l.91-.73L31,30.42a1.26,1.26,0,0,0,.4-.33C32.71,29,34,28,35.32,26.84a12.73,12.73,0,0,0,1.33-1.1c.64-.52,1.29-1,1.93-1.56a2,2,0,0,1,1.19-.5,2,2,0,0,1,1.19.5"
              //                 transform="translate(0 -8)"
              //               />
              //               <path
              //                 className="cls-1"
              //                 d="M56.35,9.81a1.67,1.67,0,0,1,.77-1.54,2,2,0,0,1,1-.25l3.8,0,3.55,0a3.64,3.64,0,0,1,.8.06l.09,0a1.77,1.77,0,0,1,1,1.82c0,.82,0,1.64,0,2.46V25.72c0,.41,0,.81,0,1.21a4.43,4.43,0,0,0,.08,1.83c.27.54,1,.87,1.45,1.29l.07,0c.55.49,1.11,1,1.7,1.4l3.56,3c1.47,1.21,2.92,2.44,4.42,3.63a2,2,0,0,1,.83,2.07,10.93,10.93,0,0,1-.76,1c-.59.71-1.16,1.44-1.74,2.16l-.21.23a11.21,11.21,0,0,1-1.64,1.8,1.86,1.86,0,0,1-2-.37c-.87-.74-1.76-1.45-2.64-2.17l-4.21-3.44a2.86,2.86,0,0,0-.63-.52c-.23-.2-.46-.41-.7-.6-1-.83-2-1.65-3-2.47a19,19,0,0,0-1.55-1.27c-.94-.84-1.92-1.62-2.9-2.4L55,30.08A25.25,25.25,0,0,0,53,28.47Q51.39,27,49.66,25.71a6.92,6.92,0,0,0-1.07-.89l-3.62-3a2.85,2.85,0,0,0-.74-.55h0a1.17,1.17,0,0,0,0-.11c-1.13-.93-2.26-1.87-3.4-2.79a1.3,1.3,0,0,0-1-.32,1.69,1.69,0,0,0-1.21.47c-.4.33-.81.66-1.2,1a26.16,26.16,0,0,0-2,1.74v0l-.26.17-4.8,3.94-3.81,3.18c-.65.48-1.27,1-1.87,1.53l-2.49,2.05c-1,.78-2,1.56-2.9,2.4a18.66,18.66,0,0,0-1.55,1.27c-1.27,1-2.5,2-3.73,3.07a4.45,4.45,0,0,0-.71.59l-.35.26C10.7,41.47,8.56,43.21,6.45,45a1.94,1.94,0,0,1-2,.44,2.82,2.82,0,0,1-.71-.63L2.53,43.33l-.12-.16C1.66,42.19.8,41.29.1,40.26A3.07,3.07,0,0,1,0,39l.07-.16a14.7,14.7,0,0,1,2-1.73c.77-.67,1.57-1.31,2.36-2,.63-.52,1.27-1,1.9-1.56l2.48-2.11a11.91,11.91,0,0,0,1.62-1.29c1-.76,1.91-1.53,2.83-2.33s1.74-1.39,2.57-2.13a20.94,20.94,0,0,0,1.78-1.46c1.24-1,2.44-2,3.63-3a5.53,5.53,0,0,0,.83-.69c.63-.51,1.26-1,1.87-1.52.87-.73,1.79-1.4,2.61-2.19,1.51-1.15,3-2.37,4.4-3.61A8,8,0,0,0,32,12.4q1.68-1.32,3.31-2.73a7.86,7.86,0,0,1,3.14-1.56L39.77,8l1.14.1a7.33,7.33,0,0,1,3.3,1.6l.33.3,9.66,7.94,2.13,1.74"
              //                 transform="translate(0 -8)"
              //               />
              //             </svg>
              //           </OverlayTrigger>
              //           <span className="span-bottom-data-table">
              //             x1.000
              //             <br />
              //             {t("wa.map.inhab")}
              //           </span>
              //         </td>
              //         <td className="under-info">
              //           <p id="info-6">
              //             {this.props.mapListStore.focusedRegion.men !==
              //               undefined &&
              //             this.props.mapListStore.focusedRegion.women !==
              //               undefined &&
              //             this.props.mapListStore.focusedRegion.capacity !==
              //               undefined
              //               ? Math.round(
              //                   (this.props.mapListStore.focusedRegion
              //                     .capacity /
              //                     (this.props.mapListStore.focusedRegion.men +
              //                       this.props.mapListStore.focusedRegion
              //                         .women)) *
              //                     1000 *
              //                     100
              //                 ) / 100
              //               : "-"}
              //           </p>
              //           <OverlayTrigger
              //             delay={{ show: 500, hide: 100 }}
              //             placement="right"
              //             overlay={
              //               <Tooltip id="tooltip">
              //                 {t("wa.map.peopleDensity")}
              //               </Tooltip>
              //             }
              //           >
              //             <svg
              //               className="svg-icon-bottom-data-table"
              //               data-name="fas fa-bed"
              //               xmlns="http://www.w3.org/2000/svg"
              //               viewBox="0 0 80 48"
              //             >
              //               <path
              //                 className="cls-1"
              //                 d="M8,57H72v6a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2V38s0-13-13-13H38a2,2,0,0,0-2,2V45H8V19a2,2,0,0,0-2-2H2a2,2,0,0,0-2,2V63a2,2,0,0,0,2,2H6a2,2,0,0,0,2-2Z"
              //                 transform="translate(0 -17)"
              //               />
              //               <circle className="cls-1" cx="22" cy="14" r="10" />
              //             </svg>
              //           </OverlayTrigger>
              //           <span className="span-bottom-data-table">
              //             x1.000
              //             <br />
              //             {t("wa.map.inhab")}
              //           </span>
              //         </td>
              //         <td className="under-info">
              //           <p id="info-7">
              //             {this.props.mapListStore.focusedRegion
              //               .numAdsPerSquareKm !== undefined
              //               ? Math.round(
              //                   this.props.mapListStore.focusedRegion
              //                     .numAdsPerSquareKm * 100
              //                 ) / 100
              //               : "-"}
              //           </p>
              //           <OverlayTrigger
              //             delay={{ show: 500, hide: 100 }}
              //             placement="right"
              //             overlay={
              //               <Tooltip id="tooltip">{t("wa.map.adkm2")}</Tooltip>
              //             }
              //           >
              //             <svg
              //               className="svg-icon-bottom-data-table"
              //               data-name="fas fa-home"
              //               xmlns="http://www.w3.org/2000/svg"
              //               viewBox="0 0 79.56 61.92"
              //             >
              //               <path
              //                 className="cls-1"
              //                 d="M53.57,34.54c1.28,1.1,2.58,2.16,3.9,3.21.49.43,1,.85,1.51,1.23,1,.84,1.93,1.64,2.93,2.42.76.67,1.55,1.32,2.35,1.93.65.58,1.31,1.13,2,1.65l0,2.8c0,1.48,0,3,0,4.45s0,3,0,4.43,0,2.92,0,4.37v4.44c0,.93,0,1.86,0,2.79a2.54,2.54,0,0,1-1.68,1.59c-.89.11-1.79,0-2.68.07H57.47a.65.65,0,0,0-.39,0c-1.36,0-2.71,0-4.06,0s-3,0-4.44,0h-.4a2.3,2.3,0,0,1-1.78-2.55c0-.6,0-1.21,0-1.82v-8.8c0-.78,0-1.56,0-2.34a2.16,2.16,0,0,0-2.18-2.1H35.32a2.15,2.15,0,0,0-2.17,2.1c0,.78,0,1.56,0,2.34v8.8c0,.56,0,1.13,0,1.69a2.43,2.43,0,0,1-1.71,2.66l-.48,0c-.48,0-1,0-1.44,0-1,0-2,0-3,0-1.48-.07-3,0-4.44,0a.86.86,0,0,0-.23,0l-4.21.06H15l-.09-.07-.15,0a2.62,2.62,0,0,1-1.46-1.37c0-1,0-2,0-3,0-1.49,0-3,0-4.45s0-2.91,0-4.36,0-3,0-4.45V47.78c0-.93,0-1.86,0-2.8a24.33,24.33,0,0,0,2-1.65c.85-.63,1.65-1.31,2.45-2,1-.75,1.92-1.52,2.84-2.34a16.31,16.31,0,0,0,1.51-1.24q1.93-1.52,3.8-3.13a3.07,3.07,0,0,0,.66-.53l.91-.73L31,30.42a1.26,1.26,0,0,0,.4-.33C32.71,29,34,28,35.32,26.84a12.73,12.73,0,0,0,1.33-1.1c.64-.52,1.29-1,1.93-1.56a2,2,0,0,1,1.19-.5,2,2,0,0,1,1.19.5"
              //                 transform="translate(0 -8)"
              //               />
              //               <path
              //                 className="cls-1"
              //                 d="M56.35,9.81a1.67,1.67,0,0,1,.77-1.54,2,2,0,0,1,1-.25l3.8,0,3.55,0a3.64,3.64,0,0,1,.8.06l.09,0a1.77,1.77,0,0,1,1,1.82c0,.82,0,1.64,0,2.46V25.72c0,.41,0,.81,0,1.21a4.43,4.43,0,0,0,.08,1.83c.27.54,1,.87,1.45,1.29l.07,0c.55.49,1.11,1,1.7,1.4l3.56,3c1.47,1.21,2.92,2.44,4.42,3.63a2,2,0,0,1,.83,2.07,10.93,10.93,0,0,1-.76,1c-.59.71-1.16,1.44-1.74,2.16l-.21.23a11.21,11.21,0,0,1-1.64,1.8,1.86,1.86,0,0,1-2-.37c-.87-.74-1.76-1.45-2.64-2.17l-4.21-3.44a2.86,2.86,0,0,0-.63-.52c-.23-.2-.46-.41-.7-.6-1-.83-2-1.65-3-2.47a19,19,0,0,0-1.55-1.27c-.94-.84-1.92-1.62-2.9-2.4L55,30.08A25.25,25.25,0,0,0,53,28.47Q51.39,27,49.66,25.71a6.92,6.92,0,0,0-1.07-.89l-3.62-3a2.85,2.85,0,0,0-.74-.55h0a1.17,1.17,0,0,0,0-.11c-1.13-.93-2.26-1.87-3.4-2.79a1.3,1.3,0,0,0-1-.32,1.69,1.69,0,0,0-1.21.47c-.4.33-.81.66-1.2,1a26.16,26.16,0,0,0-2,1.74v0l-.26.17-4.8,3.94-3.81,3.18c-.65.48-1.27,1-1.87,1.53l-2.49,2.05c-1,.78-2,1.56-2.9,2.4a18.66,18.66,0,0,0-1.55,1.27c-1.27,1-2.5,2-3.73,3.07a4.45,4.45,0,0,0-.71.59l-.35.26C10.7,41.47,8.56,43.21,6.45,45a1.94,1.94,0,0,1-2,.44,2.82,2.82,0,0,1-.71-.63L2.53,43.33l-.12-.16C1.66,42.19.8,41.29.1,40.26A3.07,3.07,0,0,1,0,39l.07-.16a14.7,14.7,0,0,1,2-1.73c.77-.67,1.57-1.31,2.36-2,.63-.52,1.27-1,1.9-1.56l2.48-2.11a11.91,11.91,0,0,0,1.62-1.29c1-.76,1.91-1.53,2.83-2.33s1.74-1.39,2.57-2.13a20.94,20.94,0,0,0,1.78-1.46c1.24-1,2.44-2,3.63-3a5.53,5.53,0,0,0,.83-.69c.63-.51,1.26-1,1.87-1.52.87-.73,1.79-1.4,2.61-2.19,1.51-1.15,3-2.37,4.4-3.61A8,8,0,0,0,32,12.4q1.68-1.32,3.31-2.73a7.86,7.86,0,0,1,3.14-1.56L39.77,8l1.14.1a7.33,7.33,0,0,1,3.3,1.6l.33.3,9.66,7.94,2.13,1.74"
              //                 transform="translate(0 -8)"
              //               />
              //             </svg>
              //           </OverlayTrigger>
              //           <span className="span-bottom-data-table">
              //             {t("wa.map.density")}
              //           </span>
              //         </td>
              //         <td className="under-info">
              //           <p id="info-8">
              //             {this.props.mapListStore.focusedRegion
              //               .capacityPerSquareKm !== undefined
              //               ? Math.round(
              //                   this.props.mapListStore.focusedRegion
              //                     .capacityPerSquareKm * 100
              //                 ) / 100
              //               : "-"}
              //           </p>
              //           <OverlayTrigger
              //             delay={{ show: 500, hide: 100 }}
              //             placement="right"
              //             overlay={
              //               <Tooltip id="tooltip">
              //                 {t("wa.map.peoplekm2")}
              //               </Tooltip>
              //             }
              //           >
              //             <svg
              //               className="svg-icon-bottom-data-table"
              //               data-name="fas fa-bed"
              //               xmlns="http://www.w3.org/2000/svg"
              //               viewBox="0 0 80 48"
              //             >
              //               <path
              //                 className="cls-1"
              //                 d="M8,57H72v6a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2V38s0-13-13-13H38a2,2,0,0,0-2,2V45H8V19a2,2,0,0,0-2-2H2a2,2,0,0,0-2,2V63a2,2,0,0,0,2,2H6a2,2,0,0,0,2-2Z"
              //                 transform="translate(0 -17)"
              //               />
              //               <circle className="cls-1" cx="22" cy="14" r="10" />
              //             </svg>
              //           </OverlayTrigger>
              //           <span className="span-bottom-data-table">
              //             {t("wa.map.density")}
              //           </span>
              //         </td>
              //         <td className="under-info">
              //           <p id="info-9">
              //             {this.props.mapListStore.focusedRegion.occupancy &&
              //             this.props.mapListStore.focusedRegion.occupancy
              //               .maxIncome
              //               ? Math.round(
              //                   this.props.mapListStore.focusedRegion.occupancy
              //                     .maxIncome * 100
              //                 ) / 100
              //               : "-"}
              //           </p>
              //           <OverlayTrigger
              //             delay={{ show: 500, hide: 100 }}
              //             placement="right"
              //             overlay={
              //               <Tooltip id="tooltip">
              //                 {t("wa.map.totalRevenue")}
              //               </Tooltip>
              //             }
              //           >
              //             <svg
              //               className="svg-icon-bottom-data-table"
              //               data-name="fas fa-piggy-bank"
              //               xmlns="http://www.w3.org/2000/svg"
              //               viewBox="0 0 71.54 63.62"
              //             >
              //               <path
              //                 className="cls-1"
              //                 d="M75.7,48v4c0,.14,0,.29,0,.43a18.18,18.18,0,0,1-.05,2.09,2.22,2.22,0,0,1-1.35,1.39l-.9,0a15,15,0,0,0-1.64,0h-4c-.19-.06-.21.12-.28.21A19.31,19.31,0,0,1,64,59.63c-.1.08-.24.12-.25.29v4c0,1.33,0,2.67,0,4,0,.58,0,1.15,0,1.73a3.88,3.88,0,0,1-.08.89,2.28,2.28,0,0,1-1.24,1.24,2.66,2.66,0,0,1-.75.07H53.93a2.62,2.62,0,0,1-.79-.08,2.23,2.23,0,0,1-1.24-1.38c-.11-.82,0-1.65-.07-2.47,0-1.25,0-2.5,0-3.74,0-.22-.08-.26-.27-.26H36.19c-.18,0-.27,0-.26.23,0,1.26,0,2.51,0,3.77,0,.18,0,.36,0,.54a13.33,13.33,0,0,1-.07,2,2.06,2.06,0,0,1-2.14,1.4c-2.51,0-5,0-7.54,0a2,2,0,0,1-2.12-1.53c-.12-.8,0-1.6-.07-2.4v-8c0-.17-.18-.22-.29-.3a21,21,0,0,1-4.13-4.4A28.75,28.75,0,0,1,17.71,52a.57.57,0,0,0,0-.14,19.61,19.61,0,0,1-1.59-7.58c0-.21-.09-.24-.27-.24-.48,0-1,0-1.45,0-.76,0-1.52,0-2.28,0s-1.6-.06-2.39-.15a12.94,12.94,0,0,1-1.59-.54,7.42,7.42,0,0,1-3.3-3.3,15.1,15.1,0,0,1-.55-1.6,8.79,8.79,0,0,1,0-2.85,6.52,6.52,0,0,1,1.55-3.06c.14-.17.3-.32.44-.48a9.16,9.16,0,0,1,1.91-1.29,8,8,0,0,1,2.4-.64c.5,0,1-.18,1.4.34a7.06,7.06,0,0,1,.11,1.59c0,.51,0,1,0,1.54a.92.92,0,0,1-.78.45,1.93,1.93,0,0,0-.47,0,2.91,2.91,0,0,0-2.5,2,3,3,0,0,0,1,3.42,4.06,4.06,0,0,0,2.74.55c1.33,0,2.67,0,4,0,.19,0,.34.06.4-.23l.21-.79c.28-1,.63-2,1-2.94.27-.48.46-1,.74-1.48A19.31,19.31,0,0,1,20,32.09l1.23-1.46a26.16,26.16,0,0,1,2.68-2.48l.08,0a24.89,24.89,0,0,1,4-2.33c.94-.35,1.87-.71,2.84-1a21.2,21.2,0,0,1,5.08-.67c1.33,0,2.66,0,4,0h4c1.31,0,2.62,0,3.93,0h4a22.05,22.05,0,0,1,2.7.28.29.29,0,0,0,.29-.1c.33-.36.68-.72,1-1.08a12.54,12.54,0,0,1,3.3-2.11,2.66,2.66,0,0,0,.69-.31,24.56,24.56,0,0,1,2.54-.58l1.38-.09,4,0,0,.28-1,3.7c-.12.47-.25.92-.35,1.39-.19.87-.47,1.72-.61,2.61-.11.38-.19.77-.31,1.14a.37.37,0,0,0,.12.45c.51.5,1,1,1.47,1.56a19.07,19.07,0,0,1,2.88,4.54c.08.17.16.25.35.24.49,0,1,0,1.45,0l2.37,0a2.2,2.2,0,0,1,1.48,1.29,2.55,2.55,0,0,1,.09.82c0,.62,0,1.25,0,1.87V44C75.67,45.33,75.67,46.66,75.7,48ZM57.84,40.06a2,2,0,1,0-.05,4,2,2,0,0,0,2-2A2,2,0,0,0,57.84,40.06Z"
              //                 transform="translate(-4.15 -8.23)"
              //               />
              //               <path
              //                 className="cls-1"
              //                 d="M30.65,16.17A15.83,15.83,0,0,1,32,13.47l1-1.23a14.24,14.24,0,0,1,3-2.47A12,12,0,0,1,40.2,8.35a11.73,11.73,0,0,1,7.64,1.48,12,12,0,0,1,3,2.48l.87,1.09a16.59,16.59,0,0,1,1.39,2.76c.15.52.29,1,.43,1.56a13.31,13.31,0,0,1,.27,2.44c-.1.13-.24.08-.36.07-.56,0-1.1-.1-1.66-.06H35.92a20.73,20.73,0,0,0-4,.39,7.07,7.07,0,0,0-1.44.29c-.35.09-.5,0-.46-.34a1.34,1.34,0,0,0-.05-.35A13.16,13.16,0,0,1,30.65,16.17Z"
              //                 transform="translate(-4.15 -8.23)"
              //               />
              //             </svg>
              //           </OverlayTrigger>
              //           <span className="span-bottom-data-table">
              //             {t("wa.map.revenue")}
              //             <br />
              //             {t("wa.map.totals")}
              //           </span>
              //         </td>

              //         <td className="under-info">
              //           <p id="info-10">
              //             {this.props.mapListStore.focusedRegion.occupancy &&
              //             this.props.mapListStore.focusedRegion.occupancy
              //               .numCurrentYearReservations
              //               ? Math.round(
              //                   this.props.mapListStore.focusedRegion.occupancy
              //                     .numCurrentYearReservations * 100
              //                 ) / 100
              //               : "-"}
              //           </p>
              //           <OverlayTrigger
              //             delay={{ show: 500, hide: 100 }}
              //             placement="left"
              //             overlay={
              //               <Tooltip id="tooltip">
              //                 {t("wa.map.totalOccupied")}
              //               </Tooltip>
              //             }
              //           >
              //             <svg
              //               className="svg-icon-bottom-data-table"
              //               data-name="fas fa-moon"
              //               xmlns="http://www.w3.org/2000/svg"
              //               viewBox="0 0 56.84 63.51"
              //             >
              //               <path
              //                 className="cls-1"
              //                 d="M35.82,8.93A32.7,32.7,0,0,1,44.3,8.1c1.15,0,2.29.17,3.43.27.38.07.75.15,1.13.21a1.49,1.49,0,0,1,.6,2.79l-1,.62a4.44,4.44,0,0,0-.73.49,22.49,22.49,0,0,0-3.31,2.69A22.78,22.78,0,0,0,40.58,20a6,6,0,0,0-.77,1.37A15,15,0,0,0,38.59,24a19.87,19.87,0,0,0-1.18,4,19.2,19.2,0,0,0-.49,3.92c0,.32,0,.65,0,1a21.74,21.74,0,0,0,.15,3,19.35,19.35,0,0,0,.81,4,19,19,0,0,0,1.49,3.93,3.87,3.87,0,0,0,.41.78,17.76,17.76,0,0,0,2,3.21,16.77,16.77,0,0,0,2,2.4,15.59,15.59,0,0,0,1.66,1.59,14.23,14.23,0,0,0,2.27,1.74,19.9,19.9,0,0,0,3.9,2.19.58.58,0,0,0,.31.13,18.14,18.14,0,0,0,3.78,1.29,19.32,19.32,0,0,0,4,.66,4.18,4.18,0,0,0,.93.08,21.29,21.29,0,0,0,3-.07,14.23,14.23,0,0,0,2.26-.27,2.23,2.23,0,0,1,1.72.15,1.58,1.58,0,0,1,.42,2l-.48.59c-.51.56-1,1.12-1.54,1.67s-1.16,1.16-1.74,1.73a3.55,3.55,0,0,0-.64.52A40.08,40.08,0,0,1,59.72,67a6.86,6.86,0,0,0-1.11.65L55.72,69a39.42,39.42,0,0,1-4,1.4c-1.08.28-2.18.53-3.28.71a31.92,31.92,0,0,1-8.34.29h-.28c-.49-.07-1-.13-1.47-.21l-2.53-.47c-1.29-.33-2.55-.75-3.82-1.18a43.13,43.13,0,0,1-4.09-1.87l-.13-.07c-1.33-.79-2.63-1.61-3.88-2.53l-1.78-1.46-2.21-2.17-1.57-1.82c-.8-1-1.54-2.11-2.27-3.19l-.42-.73c-.72-1.3-1.36-2.63-2-4s-.89-2.66-1.34-4a6.54,6.54,0,0,0-.3-1.38l-.44-2.61c-.13-1.31-.22-2.61-.23-3.92s.1-2.67.24-4c0-.1,0-.19.06-.28A30.58,30.58,0,0,1,13,30c.22-.68.47-1.36.7-2,.62-1.35,1.25-2.69,2-4a2.24,2.24,0,0,0,.36-.64c.75-1.15,1.53-2.27,2.37-3.35l1.55-1.81L22.16,16l1.75-1.43.5-.35a38.43,38.43,0,0,1,3.29-2.13.59.59,0,0,0,.28-.15,42.19,42.19,0,0,1,3.92-1.81C33.19,9.67,34.49,9.26,35.82,8.93Z"
              //                 transform="translate(-11.39 -8.08)"
              //               />
              //             </svg>
              //           </OverlayTrigger>
              //           <span className="span-bottom-data-table">
              //             {t("wa.map.overnights")}
              //           </span>
              //         </td>
              //       </tr>
              //     </tbody>
              //   </table>
              // </div>
            ) : null}
          </div>
        </>
      );
    }
    return null;
  }
}

export default withTranslation()(Map);
