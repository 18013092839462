import { observable, action, computed } from "mobx";
import { computedFn } from "mobx-utils";
import i18n from "../../i18n";
import createHmac from "crypto";
const { BroadcastChannel } = require("broadcast-channel");
const channelAbbrv = new BroadcastChannel("abbrv");

class GroupAdsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.updateContactMessages = this.updateContactMessages.bind(this);
  }

  @observable editing = false;

  @observable ids = [];

  @observable ads = [];
  @observable stp = 0;

  @observable contactMessages = [];

  @observable noDiscards = [];
  @observable discards = [];

  @observable processes = [];
  @observable processesFromStore = [];

  @observable adGroupForm = {
    id: "",
    code: "",
    title: "",
    ownerName: "",
    typeOfRoom: "",
    adAdress: "",
    licenseNumber: "",
    municipality: "",
    latitude: "",
    longitude: "",
    tags: "",

  };

  @observable adGroup = {
    id: "",
    code: "",
    title: "",
    ownerName: "",
    typeOfRoom: "",
    adAdress: "",
    licenseNumber: "",
    municipality: "",
    latitude: "",
    longitude: "",
    reason: "",
    tags: "",
  };

  // Control variables
  @observable index = 0;

  @observable selectedAdIndex = -1;

  @observable user = null;

  @observable workingUser = null;

  @observable updated = false;

  totalR = [];

  @observable found = null;
  @observable showAlert = false;
  @observable confirmAlert = false;
  @observable ownerContacted = false;
  @observable userWhoHasIt;
  @observable remarks = false;
  @observable oldProcedures = false;
  @observable maxTags = 7

  @observable states = []
  @observable message = {
    username: "",
    date: "",
    notes: ""
  }
  @observable statesGroup = {
    name: "",
    value: "",
    abbreviation: "",
    message: this.message
  }
  @observable lastModification = {
    username: "",
    date: ""
  }
  @observable notes = ""
  @observable notesForm = ""
  @observable indexSituation = -1
 
  //Modal variables//
  @observable openModal = false
  @observable showHideModal = false
  @observable closing = false
  @observable saving = false
  @observable edit2 = false
  @observable addState = false
  @observable openGA = false
  @observable group = null
  @observable editNotes = false
  @observable editingN = false
  @observable editing = false
  @observable openeningModal = false
  @observable showModal = false
  @observable mouseP = []
  @observable workingUserAd = null;
  @observable retrievedMunicipality = "";
  @observable complianceGroup = {"exc":0, "hig":0, "mid":0, "low":0, "def":0}


  @observable newObs = true;


  @action("Get listRegions ids") getlistRegionsIds = async () => {
    try {
      let user = await this.rootStore.sessionStore.getSession();
      let res = await this.rootStore.requestStore.getUserById(user.id);
      this.listRegions = res.data.assignedRegions;
      for (let i in this.listRegions) {
        let res = await this.rootStore.requestStore.getRegionForStats(
          this.listRegions[i]
        );
        this.totalR.push(res.data[0]);
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("find subRegions") findSubRegions = async (user) => {
    try {
      let found = false;
      let res2 = await this.rootStore.requestStore.getApartmentsDataForAGroups(
        this.noDiscards, user
      );
      if (res2.data === true) found = true;
      else found = false;
      this.found = found;
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("send update state to process") updateProcesses = async () => {
    try {
      await this.rootStore.requestStore.sendUpdateProcesses(
        this.adGroup.code,
        this.processes
      );
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("send update state to keep ad")
  sendUpdateStateToKeepAd = async (idAd) => {
    try {
      let isThere = false;
      for (let i in this.noDiscards) {
        if (this.noDiscards[i] === idAd) isThere = true;
      }
      if (!isThere) {
        for (let i in this.discards) {
          if (this.discards[i] === idAd) {
            this.discards.splice(i, 1);
            break;
          }
        }
        this.noDiscards.push(idAd);
        await this.rootStore.requestStore.sendUpdateAdIsKeept(
          this.adGroup.id,
          this.discards,
          this.noDiscards,
          idAd
        );
        await this.rootStore.requestStore.sendUpdateAdCode(
          idAd,
          this.adGroup.code
        );
        this.ads[this.selectedAdIndex].adGroupCode = this.adGroup.code;
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("send update state to discard ad")
  sendUpdateStateToDiscardAd = async (idAd) => {
    try {
      let isThere = false;
      for (let i in this.discards) {
        if (this.discards[i] === idAd) isThere = true;
      }
      if (!isThere) {
        for (let i in this.noDiscards) {
          if (this.noDiscards[i] === idAd) {
            this.noDiscards.splice(i, 1);
            break;
          }
        }
        this.discards.push(idAd);
        let res = await this.rootStore.requestStore.getMaxCode();
        let code = res.data._id;
        let newCode = parseInt(code);
        code = (newCode + 1).toString();
        await this.rootStore.requestStore.sendUpdateAdIsDiscarded(
          this.adGroup.id,
          this.discards,
          this.noDiscards,
          idAd,
          code
        );
        await this.rootStore.requestStore.sendUpdateAdCode(idAd, code);
        this.ads[this.selectedAdIndex].adGroupCode = code;
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("Get adGroup by code") getAdGroupByCode = async (code) => {
    try {
      let res = await this.rootStore.requestStore.getAdGroupByCode(code);
      //console.log("RES ES: ", res)
      //let res = await this.rootStore.requestStore.getAllGroupAds()
      if (res && res.data) {
        if (res.data.municipality && res.data.municipality !== "") this.retrievedMunicipality = res.data.municipality
        if (res.data.groups.length > 0) {
          if (res.data.groups[0]._id !== undefined)
            this.adGroupForm.id = this.adGroup.id = res.data.groups[0]._id;
          if (res.data.groups[0]._id !== undefined)
            this.adGroupForm.code = this.adGroup.code = res.data.groups[0]._id;
          if (
            res.data.groups[0].title !== undefined &&
            res.data.groups[0].title !== "Generic group data"
          )
            this.adGroupForm.title = this.adGroup.title =
              res.data.groups[0].title;
          else if (
            res.data.groups[0].title !== undefined &&
            res.data.groups[0].title === "Generic group data"
          )
            this.adGroup.title = res.data.groups[0].title;
          if (
            res.data.groups[0].host !== undefined &&
            res.data.groups[0].host.name !== undefined
          )
            this.adGroupForm.ownerName = this.adGroup.ownerName =
              res.data.groups[0].host.name;
          if (res.data.groups[0].roomType !== undefined)
            this.adGroupForm.typeOfRoom = this.adGroup.typeOfRoom =
              res.data.groups[0].roomType;
          if (res.data.groups[0].address !== undefined)
            this.adGroupForm.adAdress = this.adGroup.adAdress =
              res.data.groups[0].address;
          if (res.data.groups[0].license !== undefined)
            this.adGroupForm.licenseNumber = this.adGroup.licenseNumber =
              res.data.groups[0].license;
          if (res.data.groups[0].municipality !== undefined)
            this.adGroupForm.municipality = this.adGroup.municipality =
              res.data.groups[0].municipality;
          if (
            res.data.groups[0].geometry &&
            res.data.groups[0].geometry.coordinates !== null
          ) { 
            // Latitude es el 1 i la Longitud 0 longitude || latitude
            if (res.data.groups[0].geometry.coordinates[0] !== undefined)
              this.adGroupForm.longitude = this.adGroup.longitude =
                res.data.groups[0].geometry.coordinates[0];
            if (res.data.groups[0].geometry.coordinates[1] !== undefined)
              this.adGroupForm.latitude = this.adGroup.latitude =
                res.data.groups[0].geometry.coordinates[1];
          }
          if (res.data.groups[0].childrenId) {
            this.noDiscards = res.data.groups[0].childrenId;
          }
          if (res.data.groups[0].discardedChildrenId)
            this.discards = res.data.groups[0].discardedChildrenId;
          if (res.data.groups[0].process)
            this.processes = res.data.groups[0].process;
          if (res.data.groups[0].workingUser)
            this.workingUser = res.data.groups[0].workingUser;
          if (res.data.groups[0].reason)
            this.adGroup.reason = res.data.groups[0].reason
          if (res.data.groups[0].ownerContacted)
            this.ownerContacted = res.data.groups[0].ownerContacted;
          //if (res.data.groups[0].notes) this.notes = this.notesForm = res.data.groups[0].notes;
          //if (res.data.groups[0].lastModification) this.lastModification = res.data.groups[0].lastModification;
          if (res.data.groups[0].state) {
            this.statesGroup = res.data.groups[0].state;
            if (this.statesGroup.message && this.statesGroup.message.notes) this.notesForm = this.statesGroup.message.notes
          }
        }
        if (res.data.process.length > 0) {
          this.processesFromStore = res.data.process;
        }

        if (res.data.states && res.data.states.adStates.length > 0) {
          this.states = res.data.states.adStates
        }

        if (res.data.groups[0].state && res.data.states && res.data.states.adStates.length > 0) {
          let elem2 = document.getElementById("inputGroupSelect01")
          let k = -1
          for (let i in this.states) {
            if (this.states[i].name === this.statesGroup.name) {
              k = i
            }
          }
          this.indexSituation = k
          if(this.statesGroup.value !== null) {
            for (let j = 0; j < elem2.length; j++){
              var option = elem2.options[j];
              if (option.value === this.statesGroup.value) option.selected = true
              else option.selected = false
            }
          }
          let elem3 = document.getElementById("id_"+this.statesGroup.name)
          if (elem3) elem3.checked = true
        }

        if (res.data.groups.length > 0 && res.data.groups[0].tags.length > 0) {
          this.adGroupForm.tags = this.adGroup.tags =
          JSON.parse(JSON.stringify(res.data.groups[0].tags));
        }

        if(res.data.groups.length > 0 && res.data.groups[0].ownerContacte) this.ownerContacted = res.data.groups[0].ownerContacted;
        if( res.data.ads && res.data.ads.length > 0) {
          console.log(res.data.ads)
          this.ads = res.data.ads;
          this.selectedAdIndex = 0; ///This sentence, but whyy???
          this.contactMessages = new Array(this.ads.length);
          for (let i = 0; i < this.contactMessages.length; i++)
            this.contactMessages[i] = "";
          // console.log("contactMessages =", JSON.stringify(this.contactMessages));
          for (let j in this.ads) {
            if (this.ads[j].remark) {
              this.remarks = true;
              break;
            }
          }
          for (let j in this.ads) {
            if (this.ads[j].oldProcedure) {
              this.oldProcedures = true;
              break;
            }
          }
          for (let j in this.ads) {
            if (this.ads[j].stp && this.ads[j].stp.done) {
              this.stp = this.stp + 1;
            }
            if (this.ads[j].dateLastRevision) {
              let h = this.ads[j].dateLastRevision.split(" ")[0];
              h = h.split("-", 3);
              let w = h[2][0] + h[2][1];
              this.ads[j]["day"] = w;
              this.ads[j]["month"] = h[1];
              this.ads[j]["year"] = h[0];
              this.ads[j]["full"] = w + "/" + h[1] + "/" + h[0];
            }
            this.ads[j]["index"] = 0;
            if (
              !this.ads[j].occupancy ||
              !this.ads[j].occupancy.monthlyOccupancy ||
              this.ads[j].occupancy.monthlyOccupancy[this.ads[j]["month"]] ===
                undefined
            ) {
              this.ads[j]["monthOcc"] = 0;
            } else
              this.ads[j]["monthOcc"] = this.ads[j].occupancy.monthlyOccupancy[
                this.ads[j]["month"]
              ].numReservations;
            for (let i in this.ads[j].history) {
              if (this.ads[j].history[i].dateLastRevision) {
                let date = this.ads[j].history[i].dateLastRevision.split(" ")[0];
                date = date.split("-", 3);
                let w = date[2][0] + date[2][1];
                this.ads[j].history[i]["day"] = w;
                this.ads[j].history[i]["month"] = date[1];
                this.ads[j].history[i]["year"] = date[0];
                this.ads[j].history[i]["full"] =
                  w + "/" + date[1] + "/" + date[0];
              }
              this.ads[j].history[i]["index"] = 0;
              if (
                !this.ads[j].occupancy ||
                !this.ads[j].occupancy.monthlyOccupancy ||
                this.ads[j].occupancy.monthlyOccupancy[
                  this.ads[j].history[i]["month"]
                ] === undefined
              ) {
                this.ads[j].history[i]["monthOcc"] = 0;
              } else
                this.ads[j].history[i]["monthOcc"] = this.ads[j]
                .occupancy.monthlyOccupancy[
                  this.ads[j].history[i]["month"]
                ].numReservations;
            }
          }
          console.log(this.ads,res.data.ads)
        }
        else {
          this.updateProperty("showAlert", true);
          document.getElementById("danger-alert-groupAds").innerHTML = i18n.t(
            "ga.notifications.notAds"
          );
          setTimeout(() => {
            this.updateProperty("showAlert", false);
          }, 5000);
        }
        if (res.data.user) {
          await this.findSubRegions(res.data.user)
          this.user = res.data.user;
        }
      } else {
        this.updateProperty("showAlert", true);
        // if (res && res.data && (!res.data.ads || res.data.ads.length == 0)) {
        //   document.getElementById("danger-alert-groupAds").innerHTML = i18n.t(
        //     "ga.notifications.notAds"
        //   );
        //   await this.findSubRegions();
        //   if (res.data.user) this.user = res.data.user;
        // }
        // else 
        document.getElementById("danger-alert-groupAds").innerHTML = i18n.t(
          "ga.notifications.notFound"
        );
        setTimeout(() => {
          this.updateProperty("showAlert", false);
        }, 5000);
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("Get ads by code") getAdsByCode = async (code) => {
    //for(let i in res.data) this.ads.push(res.data[i])
    try {
      let copyNoDiscard = JSON.parse(JSON.stringify(this.noDiscards));
      let res = await this.rootStore.requestStore.getMultipleApartmentsByIdForAdGroups(
        copyNoDiscard
      );
      this.ads = JSON.parse(JSON.stringify(res));
      this.selectedAdIndex = 0;
      this.contactMessages = new Array(this.ads.length);
      for (let i = 0; i < this.contactMessages.length; i++)
        this.contactMessages[i] = "";
      console.log("contactMessages =", JSON.stringify(this.contactMessages));
      // for(let j in res) {
      // 	//let res = await this.rootStore.requestStore.getApartmentById(copyNoDiscard[j])
      // 	//let res = await this.rootStore.requestStore.getMultipleApartmentsByIdForAdGroups(copyNoDiscard)
      // 	if(!res[j].adIsSeen) {
      // 		this.ads.push(res[j])
      // 	}
      // }
      // for(let j in res) {
      // 	//let res = await this.rootStore.requestStore.getApartmentById(copyNoDiscard[j])
      // 		if(res[j].adIsSeen) {
      // 			this.ads.push(res[j])
      // 		}
      // }
      // for(let i in this.noDiscards) {
      // 	let res = await this.rootStore.requestStore.getApartmentById(this.noDiscards[i])
      // 	this.ads.push(res.data)
      // }
      // for(let i in this.discards) {
      // 	let res = await this.rootStore.requestStore.getApartmentById(this.discards[i])
      // 	res.data["discarded"] = true;
      // 	this.ads.push(res.data)
      // }
      for (let j in this.ads) this.ads[j]["index"] = 0;
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("send update state to ad") sendUpdateStateToAd = async (id) => {
    try {
      await this.rootStore.requestStore.sendUpdateAdIsNotNew(id, true);
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };


  @action("send update state to ad") sendUpdateStateName = async (id, variableStateName) => {
    try {
      await this.rootStore.requestStore.sendUpdateAdStateName(id, variableStateName);
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };



  getDifferences = (before, after) => {
    let diff = {
      adAdress:"noModified",
      code:"noModified",
      latitude:"noModified",
      longitude:"noModified",
      licenseNumber:"noModified",
      municipality:"noModified",
      ownerName:"noModified",
      tags:[],
      title:"noModified",
      typeOfRoom:"noModified",
    }
    let tagSet = new Set(before.tags.concat(after.tags))
    for(let tag of tagSet){
      if(after.tags.includes(tag) && !before.tags.includes(tag)) diff.tags.push("Added/"+tag);
      if(!after.tags.includes(tag) && before.tags.includes(tag)) diff.tags.push("Removed/"+tag);
    }
    if(before.adAdress !== after.adAdress) diff.adAdress = before.adAdress;
    if(before.code !== after.code) diff.code = before.code;
    if(before.latitude !== after.latitude) diff.latitude = before.latitude;
    if(before.longitude !== after.longitude) diff.longitude = before.longitude;
    if(before.licenseNumber !== after.licenseNumber) diff.licenseNumber = before.licenseNumber;
    if(before.municipality !== after.municipality) diff.municipality = before.municipality;
    if(before.ownerName !== after.ownerName) diff.ownerName = before.ownerName;
    if(before.title !== after.title) diff.title = before.title;
    if(before.typeOfRoom !== after.typeOfRoom) diff.typeOfRoom = before.typeOfRoom;
    return diff;
  }

  @action("save current changes") saveChanges = async () => {
    try {
      let formCopy = JSON.parse(JSON.stringify(this.adGroupForm));
      if (formCopy.tags instanceof Array) formCopy.tags = this.convertToFormat(formCopy.tags)
      else formCopy.tags = this.convertToFormat2(formCopy.tags)
      let aD = [parseFloat(formCopy.longitude), parseFloat(formCopy.latitude)]
      let empty = false
      let diff = false
      if(aD[1] !== parseFloat(this.adGroup.latitude) || aD[0] !== parseFloat(this.adGroup.longitude)) {
        if (isNaN(aD[0]) || isNaN(aD[1])) empty = true
        else diff = true
      }
        //if (empty) await this.rootStore.mapListStore.printAdsFromList(JSON.parse(JSON.stringify(this.ads)));
        //else if (diff) await this.rootStore.mapListStore.printAdsFromList(JSON.parse(JSON.stringify(this.ads)), aD);
      let tags = []
      let splitTags = formCopy.tags.split(/,| /)
      for (let i in splitTags) {
        if (splitTags[i] !== "") tags.push(splitTags[i])
      }
      formCopy.tags = JSON.parse(JSON.stringify(tags));
      let differences = this.getDifferences(this.adGroup, formCopy)
      this.adGroup = formCopy;
      if (this.adGroup.title === "") this.adGroup.title = "Generic group data";
      else if (this.adGroup.latitude === "") this.adGroup.latitude = "";
      else if (this.adGroup.longitude === "") this.adGroup.latitude = "";
      let res = await this.rootStore.requestStore.sendUpdateAdGroup(
        this.adGroup.id,
        this.adGroup,
        differences
      );
      if (res === undefined) {
        this.updateProperty("showAlert", true);
        document.getElementById("danger-alert-groupAds").innerHTML = i18n.t(
          "ga.notifications.notUpdated"
        );
        setTimeout(() => {
          this.updateProperty("showAlert", false);
        }, 5000);
      } else {
        //Here
        if (empty) await this.rootStore.mapListStore.printAdsFromList(JSON.parse(JSON.stringify(this.ads)));
        else if (diff) await this.rootStore.mapListStore.printAdsFromList(JSON.parse(JSON.stringify(this.ads)), aD);
        this.updateProperty("confirmAlert", true);
        document.getElementById("succes-alert-groupAds").innerHTML = i18n.t(
          "ga.notifications.updated"
        );
        setTimeout(() => {
          this.updateProperty("confirmAlert", false);
        }, 5000);
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("discard current changes") discardChanges = () => {
    let formCopy = JSON.parse(JSON.stringify(this.adGroup));
    this.adGroupForm = formCopy;
    if (this.adGroupForm.title === "Generic group data")
      this.adGroupForm.title = "";
  };

  @action("update form property") updateFormProperty(key, value) {
    // if (key === "tags") {
    //   console.log(value)
    //   let textParts = value.split(/,| /);
    //   //let textParts = value.split(" ");
    //   let result = []
    //   console.log(textParts)
    //   for (let i in textParts) {
    //     console.log(textParts[i])
    //     //result.push(textParts[i])
    //     if (textParts[i] !== "") result.push(textParts[i])
    //   }
    //   if (textParts[textParts.length-1] === "") result.push("")
    //   console.log(result)
    //   this.adGroupForm[key] = result;
    // }
    //else 
    this.adGroupForm[key] = value;
  }

  @action("update property not in form") updateProperty(key, value) {
    this[key] = value;
  }

  @action("update property not in form") updateContactMessages(key, value) {
    this.contactMessages[this.selectedAdIndex] = value;
    // console.log("key =", key);
    // console.log("value =", value);
    // console.log("contactMessages =", JSON.stringify(this.contactMessages));
    // console.log("selectedAdIndex =", this.selectedAdIndex);
  }

  @action("none className") noneClassName(message) {
    let element = document.getElementById(message);
    if (element !== null && element.className === "col-md-3 carousel-content") {
      element.className = element.className.replace(
        "col-md-3 carousel-content",
        "col-md-3 carousel-content display-none"
      );
      element.style.display = "none";
    } else if (
      element !== null &&
      element.className === "col-md-3 carousel-content display-block"
    ) {
      element.className = element.className.replace(
        "col-md-3 carousel-content display-block",
        "col-md-3 carousel-content display-none"
      );
      element.style.display = "none";
    }
  }

  @action("block className") blockClassName(message) {
    let element = document.getElementById(message);
    if (element !== null && element.className === "col-md-3 carousel-content") {
      element.className = element.className.replace(
        "col-md-3 carousel-content",
        "col-md-3 carousel-content display-block"
      );
      element.style.display = "block";
    } else if (
      element !== null &&
      element.className === "col-md-3 carousel-content display-none"
    ) {
      element.className = element.className.replace(
        "col-md-3 carousel-content display-none",
        "col-md-3 carousel-content display-block"
      );
      element.style.display = "block";
    }
  }

  @action("update the className") updateInvalidClassName(message) {
    let element = document.getElementById(message);
    if (
      element !== null &&
      element.className === "controlDisable form-control generic-input disabled"
    )
      element.className = element.className.replace(
        "controlDisable form-control generic-input disabled",
        "controlDisable form-control generic-input disabled is-invalid"
      );
    else if (
      element !== null &&
      element.className ===
        "controlDisable form-control generic-input disabled is-valid"
    )
      element.className = element.className.replace(
        "controlDisable form-control generic-input disabled is-valid",
        "controlDisable form-control generic-input disabled is-invalid"
      );
  }

  @action("update the className") updateValidClassName(message) {
    let element = document.getElementById(message);
    if (
      element !== null &&
      element.className === "controlDisable form-control generic-input disabled"
    )
      element.classList = element.className.replace(
        "controlDisable form-control generic-input disabled",
        "controlDisable form-control generic-input disabled is-valid"
      );
    else if (
      element !== null &&
      element.className ===
        "controlDisable form-control generic-input disabled is-invalid"
    )
      element.className = element.className.replace(
        "controlDisable form-control generic-input disabled is-invalid",
        "controlDisable form-control generic-input disabled is-valid"
      );
  }

  @action("update the className") resetClassName(message) {
    let element = document.getElementById(message);
    if (
      element !== null &&
      element.className ===
        "controlDisable form-control generic-input disabled is-valid"
    )
      element.className = element.className.replace(
        "controlDisable form-control generic-input disabled is-valid",
        "controlDisable form-control generic-input disabled"
      );
    else if (
      element !== null &&
      element.className ===
        "controlDisable form-control generic-input disabled is-invalid"
    )
      element.className = element.className.replace(
        "controlDisable form-control generic-input disabled is-invalid",
        "controlDisable form-control generic-input disabled"
      );
  }

  @computed get hasLongitude() {
    return this.adGroupForm.longitude !== "";
  }

  @computed get hasLatitude() {
    return this.adGroupForm.latitude !== "";
  }

  @action("Get user") getUser = async () => {
    try {
      let res = await this.rootStore.sessionStore.getSession();
      let res2 = (await this.rootStore.requestStore.getUserById(res.id)).data;
      return res2;
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("Toggle if a GroupAd is marked as favourite or not")
  toggleWorking = async (_id, workingUser, username) => {
    try {
      if (workingUser === null || workingUser === undefined) {
        let res = await this.rootStore.requestStore.changeWorkingNew(
          _id,
          username,
          "GA"
        );
        if (res.data.iChanged === false) {
          this.userWhoHasIt = res.data.user;
          this.workingUser = this.userWhoHasIt;
          this.rootStore.mapListStore.userWhoHasIt = this.userWhoHasIt;
          this.ads.forEach((child) => {
            this.rootStore.mapListStore.changeStyle(child.id, "pin");
          });
          await this.rootStore.mapListStore.updateMarkerAndCard(
            this.adGroupForm.id
          );
          this.toggleAlert();
        } else {
          this.workingUser = username;
          this.user = res.data.userInfo;
          if (res.data.userInfo === null) {
            this.rootStore.mapListStore.userWhoHasIt = null;
            this.userWhoHasIt = null;
          } else {
            this.rootStore.mapListStore.userWhoHasIt =
              res.data.userInfo.username;
            this.userWhoHasIt = res.data.userInfo.username;
          }
        }
      } else if (workingUser === username) {
        let res = await this.rootStore.requestStore.changeWorkingNew(_id, null, "GA");
        if (res.data.iChanged === false) {
          this.userWhoHasIt = res.data.user;
          this.workingUser = this.userWhoHasIt;
          this.rootStore.mapListStore.userWhoHasIt = this.userWhoHasIt;
          this.toggleAlert();
        } else {
          this.rootStore.mapListStore.userWhoHasIt = null;
          this.userWhoHasIt = null;
          this.workingUser = null;
        }
      } else {
        //this.userWhoHasIt = res.data.user
        this.toggleAlert();
      }
      //this.updated = true
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @computed get isFound() {
    return this.found;
  }

  @computed get userIsTheWorkingUser() {
    return this.user && this.workingUser === this.user.username;
  }

  @action("Toggle the alert") toggleAlert = () => {
    this.updateProperty("showAlert", true);
    document.getElementById("danger-alert-groupAds").innerHTML =
      "<strong>" +
      i18n.t("ga.notifications.attention") +
      this.userWhoHasIt +
      "</strong> " +
      i18n.t("ga.notifications.update");
    setTimeout(() => {
      this.updateProperty("showAlert", false);
    }, 5000);
  };

  toggleFav = async (code) => {
    try {
      let user = await this.rootStore.sessionStore.getSession();
      user = (await this.rootStore.requestStore.getUserById(user.id)).data;

      let favAds = user.favAds;
      if (code) {
        if (favAds) {
          if (favAds.includes(code))
            for (let i = 0; i < favAds.length; i++) {
              if (favAds[i] === code) {
                favAds.splice(i, 1);
                i--;
              }
            }
          else favAds.push(code);
        } else favAds = [code];
      }
      user = await this.rootStore.requestStore.sendUpdateUser(user._id, {
        favAds: favAds,
      },"GA",{favAds:"modified"});
      return user.data;
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("get all the processes in a GA") getProcesses = async (idGroup) => {
    try {
      let res = await this.rootStore.requestStore.getAllProcesses(idGroup);
      return res.data;
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("send message to ad owner") sendMessage = async () => {
    let currentIndex = this.selectedAdIndex;
    let currentAdId = this.ads[currentIndex].id;
    let currentAdGroupId = this.adGroup.id;
    try {
      console.log("INSIDE SEND EMAIL");
      // let formData = {
      //   adId : 'fbjgkrfg'
      // }

      console.log("selected ad =", JSON.stringify(this.ads[currentIndex]));

      if (this.contactMessages[currentIndex] !== "") {
        this.rootStore.userStore.updateProperty(
          "alertMessage",
          "ID: " + currentAdId + ". " + i18n.t(
            "ga.contact.messageInProcess"
          )
        );
        // this.rootStore.userStore.updateProperty("confirmAlert", false);
        // this.rootStore.userStore.updateProperty("showAlert", false);
        this.rootStore.userStore.updateProperty("messageInProcessAlert", true);
        let formData = {
          adId: this.ads[currentIndex].id,
          ownerContacted: this.ads[currentIndex].ownerContacted,
          message: this.contactMessages[currentIndex],
        };

        let adWebsite = this.ads[currentIndex].website;
        console.log("adWebsite =", adWebsite);

        let res;
        if (adWebsite === "Airbnb") {
          res = await this.rootStore.requestStore.sendAirbnbMessage(formData);
        } else if (adWebsite === "Homeaway") {
          res = await this.rootStore.requestStore.sendHomeawayMessage(formData);
        } else if (adWebsite === "Housetrip") {
          res = await this.rootStore.requestStore.sendHousetripMessage(
            formData
          );
        } else if (adWebsite === "Leboncoin") {
          res = await this.rootStore.requestStore.sendLeboncoinMessage(
            formData
          );
        } else if (adWebsite === "Niumba") {
          res = await this.rootStore.requestStore.sendNiumbaMessage(formData);
        } else if (adWebsite === "Rentalia") {
          res = await this.rootStore.requestStore.sendRentaliaMessage(formData);
        } else if (adWebsite === "Tripadvisor") {
          res = await this.rootStore.requestStore.sendTripadvisorMessage(
            formData
          );
        } else {
          throw new Error(
            "Aquesta plataforma no permet l'enviament de missatges"
          );
        }
        // this.rootStore.userStore.updateProperty("confirmAlert", false);
        // this.rootStore.userStore.updateProperty("showAlert", false);
        this.rootStore.userStore.updateProperty("messageInProcessAlert", false);
        console.log("RES ES... ", res);
        if (res.data.message === "Success!") {
          console.log("SUCCESS");
          console.log("res.data =", JSON.stringify(res.data));

          // Comprovem que no s'hagi canviat de groupad ni que aquest s'hagi modificat
          if (this.ads[currentIndex].id === currentAdId) {
            console.log("this.ads =", JSON.stringify(this.ads));
            console.log(
              "this.ads[currentIndex] =",
              JSON.stringify(this.ads[currentIndex])
            );
            console.log(
              "this.ads[currentIndex].contactMessages =",
              JSON.stringify(this.ads[currentIndex].contactMessages)
            );

            this.ads[currentIndex].contactMessages =
              res.data.ad.contactMessages;
            this.ads[currentIndex].ownerContacted = res.data.ad.ownerContacted;
          }
          if (this.adGroup.id === currentAdGroupId) {
            if (!this.ownerContacted) this.ownerContacted = true;
          }
          this.rootStore.userStore.updateProperty(
            "alertMessage",
            "ID: " + currentAdId + ". " + i18n.t(
              "ga.contact.messageSuccess"
            )
          );
          this.rootStore.userStore.updateProperty("confirmMessageAlert", true);
          setTimeout(() => {
            this.rootStore.userStore.updateProperty("confirmMessageAlert", false);
          }, 5000);
        } 
        else {
          console.log("ERROR 1");
          if (res && res.data && (res.data.message === "No available start date" | res.data.message === "No available end date")) {
            this.rootStore.userStore.updateProperty(
              "alertMessage",
              "ID: " + currentAdId + ". " + i18n.t(
                "ga.contact.noAvailableDates"
              )
            );
            this.rootStore.userStore.updateProperty("showMessageAlert", true);
            setTimeout(() => {
              this.rootStore.userStore.updateProperty("showMessageAlert", false);
            }, 5000);
          }
          else if (res && res.data && res.data.message === "adMayNotExist") {
            this.rootStore.userStore.updateProperty(
              "alertMessage",
              "ID: " + currentAdId + ". " + i18n.t(
                "ga.contact.noAvailableAd"
              )
            );
            this.rootStore.userStore.updateProperty("showMessageAlert", true);
            setTimeout(() => {
              this.rootStore.userStore.updateProperty("showMessageAlert", false);
            }, 5000);
          }
          else {
            this.rootStore.userStore.updateProperty(
              "alertMessage",
              "ID: " + currentAdId + ". " + i18n.t(
                "ga.contact.messageError"
              )
              // "ID: " + currentAdId + ". " + res.data.message
            );
            this.rootStore.userStore.updateProperty("showMessageAlert", true);
            setTimeout(() => {
              this.rootStore.userStore.updateProperty("showMessageAlert", false);
            }, 5000);
          }
        }
      } else {
        throw new Error("Message not long enough");
      }
    } catch (error) {
      this.rootStore.userStore.updateProperty("confirmMessageAlert", false);
      this.rootStore.userStore.updateProperty("showMessageAlert", false);
      this.rootStore.userStore.updateProperty("messageInProcessAlert", false);
      console.log(error);
      this.rootStore.userStore.updateProperty(
        "alertMessage",
        "ID: " + currentAdId + ". " + i18n.t(
          "ga.contact.messageSuccess"
        )
      );
      this.rootStore.userStore.updateProperty("showMessageAlert", true);
      setTimeout(() => {
        this.rootStore.userStore.updateProperty("showMessageAlert", false);
      }, 5000);
    }
  };

  convertToFormat = computedFn (
    function convertToFormatTable(tags) {
      let cop = JSON.parse(JSON.stringify(tags))
      if (cop !== "" | cop.length === 0) {
        let result = []
        for (let i in cop) {
            result += cop[i]
            if (parseInt(i)+1 < cop.length) result += ", "
        }
        return result
      }
      else return ""
  })

  convertToFormat2 = computedFn (
    function convertToFormatTable2(tags) {
      let cop = JSON.parse(JSON.stringify(tags))
      if (cop !== "" | cop.length === 0) {
        let result = ""
        for (let i in cop) {
            result += cop[i]
            if (cop[i] === "," && parseInt(i)+1 < cop.length && cop[parseInt(i)+1] !== " ") result += " "
        }
        return result
      }
      else return ""
  })

  /*
    Converteix les dates ISO de les tasques a un format concret.
    Format desitjat: hh:mm DD-MM-YYYY
    Format ISO: "2013-03-10T02:00:00Z
    */
   convertToFormatTable = computedFn(
    function convertToFormatTable(date) {
      ///hh:mm DD-MM-YYYY
      //"2013-03-10T02:00:00Z
      if (date) {
        let h = date.split("T")[0];
        let t = date.split("T", 2);
        t = t[1].split(".")[0];
        t = t.split(":", 2);
        h = h.split("-", 3);
        return h[2] + "-" + h[1] + "-" + h[0] + "      " + t[0] + ":" + t[1]
      }
      else return null
    }
  );


  /*
    Obre els comentaris
    */
   @action("open Gen Report") openGenReport = async (e, modalGa) => {
    try {
      this.editingN = true
      this.openModal = true
      this.showHideModal = true;
      this.edit2 = true
      if (modalGa) this.editing = true
    } catch (error) {
      console.log(error);
    }
  };

  @action("Closing") closed = async () => {
    this.closing = true
  }

  /*
    Tanca qualsevol modal obert
    */

  @action("CloseModal") closeModal = async () => {
    this.openeningModal = false
    this.showModal = false
    this.mouseP = []
  }

   @action("Close") close = async (modGa) => {
    this.saving = false;
    this.closing = false
    this.showHideModal = false;
    this.openModal = false
    this.edit2 = false
    this.openGA = false
    this.editingN = false
    this.editNotes = false
    if (this.statesGroup && this.statesGroup.message && this.statesGroup.message.notes) {
      let noteDef = JSON.parse(JSON.stringify(this.statesGroup.message.notes));
      this.notesForm = noteDef;
    }
    if (modGa) {
      this.showHideModal = true
      this.openModal = true
      this.edit2 = true
      this.editNotes = true
    }
    else this.editing = false
  };


   /*
    Actualitza a BD el comentari d'un estat
    */
   @action("save comment") addExistentNotes = async (notesUpdated, modGa) => {
    try {
      //Parte de los comentarios
      let notesCopy = JSON.parse(JSON.stringify(notesUpdated));
      //Parte de la fecha/usuario
      var today = new Date();
      var year = today.getFullYear();
      var month = today.getMonth() + 1;
      var day = today.getDate();
      if (month <= 9) month = "0" + month;
      if (day <= 9) day = "0" + day;
      var date = year + "-" + month + "-" + day;
      var hours = today.getHours();
      var minutes = today.getMinutes();
      var seconds = today.getSeconds();
      if (hours <= 9) hours = "0" + hours;
      if (minutes <= 9) minutes = "0" + minutes;
      if (seconds <= 9) seconds = "0" + seconds;
      var time = hours + ":" + minutes + ":" + seconds;
      var datatime = date + "T" + time + ".000Z";
      //this.message.date = datatime
      //this.message.username = this.user.username
      if(!this.statesGroup || !this.statesGroup.message || !this.statesGroup.message.date || !this.statesGroup.message.username || !this.statesGroup.message.notes) {
        this.statesGroup.message = {
          date: "",
          username: "",
          notes: ""
        }
      }
      this.statesGroup.message.date = datatime
      this.statesGroup.message.username = this.user.username
      this.statesGroup.message.notes = notesCopy
      let res = await this.rootStore.requestStore.updateNotesAndLastModification(this.statesGroup.message, this.adGroup.id);
      this.notes = notesCopy
      this.close(modGa)      
      if (res === undefined) {
        this.updateProperty("showAlert", true);
        document.getElementById("danger-alert-groupAds").innerHTML = i18n.t(
          "ga.notifications.notUpdated"
        );
        setTimeout(() => {
          this.updateProperty("showAlert", false);
        }, 5000);
       } else {
          let group = JSON.parse(JSON.stringify(this.statesGroup));
          await this.rootStore.requestStore.updateStateGA(this.statesGroup, this.adGroup.id);
          let info = {
            id: this.adGroup.id,
            statesGroup: group,
            index: this.indexSituation
          }
          await channelAbbrv.postMessage(info);
          this.updateProperty("confirmAlert", true);
          document.getElementById("succes-alert-groupAds").innerHTML = i18n.t(
            "ga.notifications.updated"
          );
          setTimeout(() => {
            this.updateProperty("confirmAlert", false);
          }, 5000);
        }
    } catch(error) {
	      if (error.toString() === "Error: AuthError")
	        console.log("Authentication failed");
			}
  }
 
  @action("radio button changed") radioChanged = async (i) => {
    let name = this.states[i].name
    for (let j in this.states) {
      if (this.states[j].name !== name) {
        let elem = document.getElementById("id_"+this.states[j].name)
        if (elem) elem.checked = false
      }
    }
    this.indexSituation = i
    let elem2 = document.getElementById("inputGroupSelect01")
    for (let j = 0; j < elem2.length; j++){
      var option = elem2.options[j];
      if (option.value === "choose") option.selected = true
      else option.selected = false
    }
    this.statesGroup.name = name
    this.statesGroup.abbreviation = await this.convertToAbbreviation(name)
    this.statesGroup.value = null
    let group = JSON.parse(JSON.stringify(this.statesGroup));
    await this.rootStore.requestStore.updateStateGA(this.statesGroup, this.adGroup.id);
    let info = {
      id: this.adGroup.id,
      statesGroup: group,
      index: this.indexSituation
    }
    await channelAbbrv.postMessage(info);
  };

  @action("convert to abbreviation") convertToAbbreviation = async (name) => {
    let abbrv = ""
    switch (name) {
      case "tocheck":
        abbrv = "TC"
        break;
      case "checked":
        abbrv = "C"
        break;
      case "inprocess":
        abbrv = "IP"
        break;
      case "procreport":
        abbrv = "PR"
        break;
      case "notlocated":
        abbrv = "NL"
        break;
      case "noNIRTC":
        abbrv = "NO NIRTC"
        break;
      case "otherEstab":
        abbrv = "OE"
        break;
      case "noLic":
        abbrv = "NLic"
        break;
      default:
        abbrv = ""
        break;
    }
    return abbrv 
  };

  @action("selected dropdown changed") selectChanged = async (value) => {
    this.statesGroup.value = value
    await this.rootStore.requestStore.updateStateGA(this.statesGroup, this.adGroup.id);
    let group = JSON.parse(JSON.stringify(this.statesGroup));
    await this.rootStore.requestStore.updateStateGA(this.statesGroup, this.adGroup.id);
    let info = {
      id: this.adGroup.id,
      statesGroup: group,
      index: this.indexSituation
    }
    await channelAbbrv.postMessage(info);
  };

  @action("add Status") addStatus = async () => {
    try {
      this.openModal = true
      this.showHideModal = true;
      this.edit2 = true
      this.addState = true
    } catch (error) {
      console.log(error);
    }
  };

    /*
    Obre i mostra tots els noms de les tasques dels processos d'un GA
    */
   @action("open tasks") openGATasks = async (adState, lastModification, notes,idGroup, x, y, working) => {
    try {
      this.group = idGroup
      this.statesGroup = adState
      this.lastModification = lastModification
      this.notes = notes
      this.openeningModal = true
      this.showModal = true
      this.workingUserAd = working
      this.mouseP.push(x)
      this.mouseP.push(y)
    } catch (error) {
      console.log(error);
    }
  };
  
    /*
    Obre i mostra tots els processos d'un GA
    */
   @action("open process reports") openGAStates = async (adState, lastModification, notes, user, states, idGroup) => {
    try {
      this.group = this.adGroup.id = idGroup
      this.statesGroup = adState
      this.lastModification = lastModification
      if(this.statesGroup && this.statesGroup.message && this.statesGroup.message.notes) this.notes = this.notesForm = this.statesGroup.message.notes
      else this.notes = this.notesForm = ""
      this.showHideModal = true;
      this.openModal = true;
      this.editNotes = true;
      this.edit2 = true
      this.user = user
      this.states = states
      if (this.statesGroup && this.states && this.states.length > 0) {
          //let elem2 = document.getElementById("inputGroupSelect01")
          let k = -1
          for (let i in this.states) {
            if (this.states[i].name === this.statesGroup.name) {
              k = i
            }
          }
          this.indexSituation = k
          // if(this.statesGroup.value !== null) {
          //   for (let j = 0; j < elem2.length; j++){
          //     var option = elem2.options[j];
          //     if (option.value === this.statesGroup.value) option.selected = true
          //     else option.selected = false
          //   }
          // }
          //let elem3 = document.getElementById("id_"+this.statesGroup.name)
          //if (elem3) elem3.checked = true
        }
    } catch (error) {
      console.log(error);
    }
  };

  @action("get the state in a GA") getState = async (idGroup) => {
    try {
      let res = await this.rootStore.requestStore.getStateGA(idGroup);
      return res.data;
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  @action("get all the states") getAllStates = async (group) => {
    try {
      let res = await this.rootStore.requestStore.getAllStates(group);
      return res.data;
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  @computed get hasCorrectIndexVal() {
    return (this.selectedAdIndex !== undefined || this.selectedAdIndex !== null)  &&  this.selectedAdIndex !== -1
  }
  
  @computed get hasAdAndHistory() {
    return !!this.ads[this.selectedAdIndex] && !!this.ads[this.selectedAdIndex].history
  }


  hasAdAndProperty = computedFn(
    function hasAdAndProperty(value) {
      return !!this.ads[this.selectedAdIndex] && !!this.ads[this.selectedAdIndex][value]
    }
  );

  hasAdAndNestedProperty = computedFn(
    function hasAdAndProperty(value1, value2) {
      return !!this.ads[this.selectedAdIndex] && !!this.ads[this.selectedAdIndex][value1][value2]
    }
  );

  
  @action("Send the save the proof get command") downloadSTP = async (ad, index) => {
    console.log("I WANT TO GET ", ad)
    let stpinfo = (await this.rootStore.requestStore.getSTPDownload(ad.id)).data
    let url = "https://secure.savetheproof.com/autologin?"
    url = url + "username=" + stpinfo.email
    url = url + "&timestamp=" + stpinfo.time
    url = url + "&hmac=" + stpinfo.hash
    let toEncode = "/certificate/" + ad.stp.stpid + "/download"
    url = url + "&destination=" + encodeURIComponent(toEncode)
    console.log(url)
    return url;
  }

  @action("Get credentials to login on Save the Proof") getSTPLogin = async (ad) => {
    let stpinfo = (await this.rootStore.requestStore.getSTPLogin(ad.id)).data
    let count = 0
    if (ad.stp && ad.stp.certificates)
      count = ad.stp.certificates.length
    let url = "https://secure.savetheproof.com/autologin?"
    url = url + "username=" + stpinfo.email
    url = url + "&timestamp=" + stpinfo.time
    url = url + "&hmac=" + stpinfo.hash
    //TODO afegir contador,copiar de MapListStore
    let toEncode = "/autostart_browsing_certificate?extjobid="+ad.id+"_"+count+"&welcomepage="+ad.url
    url = url + "&destination=" + encodeURIComponent(toEncode)
    console.log(toEncode, encodeURIComponent(toEncode))
    console.log(url)
    return url;
  }

  @action("Update STP variable of an ad") updateSTP = async (ad) => {
    console.log(ad)
    let count = 0;
    if (ad.stp && ad.stp.certificates)
      count = ad.stp.certificates.length
    if (!ad.stp){
      let id = ad.id+"_"+0
      ad.stp = {
        "done":true,
        "certificates":[]
      }
      this.stp = this.stp + 1;
      let certificate = {
        extjob:id,
        stpid:id,
        date: Date.today
      }
      ad.stp.certificates.push(certificate)
    }
    else if (ad.stp.done){

      let id = ad.id+"_"+count
      let certificate = {
        extjob:id,
        stpid:id,
        date: Date.today
      }
      ad.stp.certificates.push(certificate)
    } else{

      let id = ad.id+"_"+count
      ad.stp.done = true;
      let certificate = {
        extjob:id,
        stpid:id,
        date: Date.today
      }
      ad.stp.certificates.push(certificate)
      this.stp = this.stp + 1;
    }
    //this.rootStore.requestStore.sendUpdateAdSTP(ad.id,ad.stp)
    
  }

  searchComplianceForGroupAd = async () => {
    try {
      let data = await this.rootStore.requestStore.searchComplianceForGroupAd(this.adGroup.id);
      for (let res of data.data){
        if(this.complianceGroup[res.license.compliance_status] === 0 || this.complianceGroup[res.license.compliance_status] === undefined || this.complianceGroup[res.license.compliance_status] === NaN || this.complianceGroup[res.license.compliance_status] === null)
          this.complianceGroup[res.license.compliance_status] = 0
        this.complianceGroup[res.license.compliance_status] += 1;
      }
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

}
export default GroupAdsStore;
