import React, { Component } from "react";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";

@observer
class TeamCard extends Component {

    render() {
        const t = this.props.t;
        console.log(this.props.item)
        return (
            <table className="areas-info-rec table table-borderless text-left">
                <tbody>
                    <tr>
                        <td className="team-arrow">
                            {this.props.item.hasSubregions ? (
                                <button onClick={async (e) => {
                                    this.props.teamStore.clickedOn(this.props.it, this.props.pos, this.props.item._id, this.props.item.adm, this.props.item.username)
                                }}>
                                    <svg
                                        className="svg-icon-arrow-down-team"
                                        data-name="fas fa-angle-down"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 40.11 26.16"

                                    >
                                        <path
                                            className="cls-1"
                                            d="M27.34,28.77a2.08,2.08,0,0,0-3,0l-4,4s-1,1,0,3L38.42,53.74s1,1,3,0L59.36,35.67a2.07,2.07,0,0,0,0-3l-4-4a2.08,2.08,0,0,0-3,0l-12,12Z"
                                            transform="translate(-19.91 -28.02)"
                                        />
                                    </svg>
                                </button>
                            ) : null}
                        </td>
                        <td
                        onClick={() => {
                            this.props.teamStore.openWA(this.props.item._id);
                          }}>{this.props.item.regionName}
                      </td>
                      <td className="text-right aligned">
                        {this.props.item.workAds + " " + t("team.of") + " " + this.props.item.numAds}
                      </td>
                    </tr>
                    {this.props.item.username in this.props.teamStore.clicked &&
                        this.props.it in this.props.teamStore.clicked[this.props.item.username] &&
                        this.props.pos in
                        this.props.teamStore.clicked[this.props.item.username][this.props.it] &&
                        this.props.teamStore.clicked[this.props.item.username][this.props.it][
                        this.props.pos] && this.props.item._id in this.props.teamStore.subregions ? (
                        this.props.teamStore.subregions[this.props.item._id].map((item, i) => {
                            return (<tr key={i}><td colSpan="100" className="borders">
                                <TeamCard
                                    item={item}
                                    t={this.props.t}
                                    pos={i}
                                    teamStore={this.props.teamStore}
                                    it={this.props.it + 1}
                                />
                            </td></tr>)
                        })
                    ) : null
                    }
                </tbody>
            </table>
        );
    }
}

export default withTranslation()(TeamCard)