import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import NewStats from "../../NewRegionSelector/NewRegionSelector";
//import RegionSelector from "../../RegionSelector/RegionSelector";
import { withTranslation } from "react-i18next";
import {
    BarChart,
    Bar,
    //Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    //LabelList,
} from "recharts";

const upperCaseFirstLetter = (string) =>
    `${string.slice(0, 1).toUpperCase()}${string.slice(1)}`;

@inject("statsNircStore", "userStore")
@observer
class StatsNirc extends Component {
    async componentDidMount() {
        try {
            let { t } = this.props;
            document.title = t("statistics.title");
            await this.props.statsNircStore.initializeStore();
        } catch (error) {
            if (error.toString() === "Error: AuthError")
                console.log("Authentication failed");
        }
    }

    render() {
        const { t } = this.props;
        if (this.props.statsNircStore && this.props.statsNircStore.data) {
            // console.log(this.props.statsNircStore.data);
            return (
                <>
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <h4 className="stats-title">
                                {this.props.statsNircStore.cityName}
                            </h4>
                            <div className="quick-search-statistics">
                                <NewStats
                                    page="statsNirc"
                                    regBool={true}
                                ></NewStats>
                            </div>
                            <div className="row stats-table">
                                <p style={{"text-align":"left"}}>
                                    {t("statistics.stats.date") +
                                        this.props.statsNircStore.dateInfo}
                                    <br />
                                    <br />
                                    {t("statistics.stats.count.pre") +
                                        this.props.statsNircStore.totalAds +
                                        t("statistics.stats.count.inter") +
                                        this.props.statsNircStore.totalGroups +
                                        t("statistics.stats.count.post")}
                                    <br />
                                    {t("statistics.ref")}
                                </p>
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th
                                                key={
                                                    "LLARS COMPARTIDES< 31 NITS"
                                                }
                                                colspan="6"
                                            >
                                                {t(
                                                    "statistics.stats.llars.title"
                                                )}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="cover"></th>
                                            {[
                                                t(
                                                    "statistics.stats.llars.total"
                                                ),
                                                t(
                                                    "statistics.stats.llars.correct"
                                                ),
                                                t(
                                                    "statistics.stats.llars.incorrect"
                                                ),
                                                t(
                                                    "statistics.stats.llars.nircthut"
                                                ),
                                                t(
                                                    "statistics.stats.llars.percentage"
                                                ),
                                            ].map((currentValue, i) => (
                                                <th key={"" + currentValue + i}>
                                                    {currentValue}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.values(
                                            this.props.statsNircStore.data
                                        ).map((currentValue, i) => (
                                            <tr
                                                key={
                                                    "1" +
                                                    currentValue["website"] +
                                                    i
                                                }
                                            >
                                                <td>
                                                    {currentValue["website"]}
                                                </td>
                                                {Object.values(
                                                    currentValue["firstTable"]
                                                ).map((currentVal, i) => (
                                                    <td
                                                        key={
                                                            "" + currentVal + i
                                                        }
                                                    >
                                                        {currentVal}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th
                                                key={
                                                    "HABITATGES SENCERS <31 NITS"
                                                }
                                                colSpan="6"
                                            >
                                                {t(
                                                    "statistics.stats.hut.title"
                                                )}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="cover"></th>
                                            {[
                                                t("statistics.stats.hut.total"),
                                                t(
                                                    "statistics.stats.hut.correct"
                                                ),
                                                t(
                                                    "statistics.stats.hut.incorrect"
                                                ),
                                                t(
                                                    "statistics.stats.hut.percentage"
                                                ),
                                            ].map((currentValue, i) => (
                                                <th key={"" + currentValue + i}>
                                                    {currentValue}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.values(
                                            this.props.statsNircStore.data
                                        ).map((currentValue, i) => (
                                            <tr
                                                key={
                                                    "1" +
                                                    currentValue["website"] +
                                                    i
                                                }
                                            >
                                                <td>
                                                    {currentValue["website"]}
                                                </td>
                                                {Object.values(
                                                    currentValue["secondTable"]
                                                ).map((currentVal, i) => (
                                                    <td
                                                        key={
                                                            "" + currentVal + i
                                                        }
                                                    >
                                                        {currentVal}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th key={"TOTALS"} colSpan="4">
                                                {t(
                                                    "statistics.stats.total.title"
                                                )}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="cover"></th>
                                            {[
                                                t(
                                                    "statistics.stats.total.less"
                                                ),
                                                t(
                                                    "statistics.stats.total.more"
                                                ),
                                                t(
                                                    "statistics.stats.total.total"
                                                ),
                                            ].map((currentValue, i) => (
                                                <th key={"" + currentValue + i}>
                                                    {currentValue}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.values(
                                            this.props.statsNircStore.data
                                        ).map((currentValue, i) => (
                                            <tr
                                                key={
                                                    "1" +
                                                    currentValue["website"] +
                                                    i
                                                }
                                            >
                                                <td>
                                                    {currentValue["website"]}
                                                </td>
                                                {Object.values(
                                                    currentValue["thirdTable"]
                                                ).map((currentVal, i) => (
                                                    <td
                                                        key={
                                                            "" + currentVal + i
                                                        }
                                                    >
                                                        {currentVal}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="stats-chart">
                                <p>{t("statistics.stats.chart")}</p>
                                <div style={{ height: "25rem" }}>
                                    <ResponsiveContainer
                                        width="100%"
                                        height="100%"
                                    >
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={
                                                this.props.statsNircStore
                                                    .dataGraph
                                            }
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 20,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis
                                                dataKey="name"
                                                interval={0}
                                            />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Bar
                                                dataKey="total"
                                                fill="#8884d8"
                                            ></Bar>
                                            <Bar
                                                dataKey="nirtc Correcte"
                                                fill="#82ca9d"
                                            />
                                            <Bar
                                                dataKey="no nirtc"
                                                fill="#31ca5a"
                                            />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                            <details>
                                <summary>
                                    {t("statistics.stats.metodology.title")}
                                </summary>
                                <hr />
                                <div>
                                    <details>
                                        <summary>
                                            {upperCaseFirstLetter(
                                                t(
                                                    "statistics.stats.llars.title"
                                                ).toLowerCase()
                                            )}
                                        </summary>
                                        <hr />
                                        <div>
                                            <p>
                                                {upperCaseFirstLetter(
                                                    t(
                                                        "statistics.stats.llars.total"
                                                    ).toLowerCase()
                                                ) +
                                                    t(
                                                        "statistics.stats.metodology.llars.total"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.llars.roomType"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.website"
                                                    )}
                                            </p>
                                            <p>
                                                {upperCaseFirstLetter(
                                                    t(
                                                        "statistics.stats.llars.correct"
                                                    ).toLowerCase()
                                                ) +
                                                    t(
                                                        "statistics.stats.metodology.llars.correct"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.llars.roomType"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.website"
                                                    )}
                                            </p>
                                            <p>
                                                {upperCaseFirstLetter(
                                                    t(
                                                        "statistics.stats.llars.incorrect"
                                                    ).toLowerCase()
                                                ) +
                                                    t(
                                                        "statistics.stats.metodology.llars.incorrect"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.llars.roomType"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.website"
                                                    )}
                                            </p>
                                            <p>
                                                {upperCaseFirstLetter(
                                                    t(
                                                        "statistics.stats.llars.nircthut"
                                                    ).toLowerCase()
                                                ) +
                                                    t(
                                                        "statistics.stats.metodology.llars.nircthut"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.llars.roomType"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.website"
                                                    )}
                                            </p>
                                            <p>
                                                {upperCaseFirstLetter(
                                                    t(
                                                        "statistics.stats.llars.percentage"
                                                    ).toLowerCase()
                                                ) +
                                                    t(
                                                        "statistics.stats.metodology.llars.percentage"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.llars.roomType"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.website"
                                                    )}
                                            </p>
                                        </div>
                                    </details>
                                    <hr />
                                    <details>
                                        <summary>
                                            {upperCaseFirstLetter(
                                                t(
                                                    "statistics.stats.hut.title"
                                                ).toLowerCase()
                                            )}
                                        </summary>
                                        <hr />
                                        <div>
                                            <p>
                                                {upperCaseFirstLetter(
                                                    t(
                                                        "statistics.stats.hut.total"
                                                    ).toLowerCase()
                                                ) +
                                                    t(
                                                        "statistics.stats.metodology.hut.total"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.llars.roomType"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.website"
                                                    )}
                                            </p>
                                            <p>
                                                {upperCaseFirstLetter(
                                                    t(
                                                        "statistics.stats.hut.correct"
                                                    ).toLowerCase()
                                                ) +
                                                    t(
                                                        "statistics.stats.metodology.hut.correct"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.hut.roomType"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.website"
                                                    )}
                                            </p>
                                            <p>
                                                {upperCaseFirstLetter(
                                                    t(
                                                        "statistics.stats.hut.incorrect"
                                                    ).toLowerCase()
                                                ) +
                                                    t(
                                                        "statistics.stats.metodology.hut.incorrect"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.hut.roomType"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.website"
                                                    )}
                                            </p>
                                            <p>
                                                {upperCaseFirstLetter(
                                                    t(
                                                        "statistics.stats.hut.percentage"
                                                    ).toLowerCase()
                                                ) +
                                                    t(
                                                        "statistics.stats.metodology.hut.percentage"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.hut.roomType"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.website"
                                                    )}
                                            </p>
                                        </div>
                                    </details>
                                    <hr />
                                    <details>
                                        <summary>
                                            {upperCaseFirstLetter(
                                                t(
                                                    "statistics.stats.total.title"
                                                ).toLowerCase()
                                            )}
                                        </summary>
                                        <hr />
                                        <div>
                                            <p>
                                                {upperCaseFirstLetter(
                                                    t(
                                                        "statistics.stats.total.less"
                                                    ).toLowerCase()
                                                ) +
                                                    t(
                                                        "statistics.stats.metodology.total.less"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.hut.roomType"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.website"
                                                    )}
                                            </p>
                                            <p>
                                                {upperCaseFirstLetter(
                                                    t(
                                                        "statistics.stats.total.more"
                                                    ).toLowerCase()
                                                ) +
                                                    t(
                                                        "statistics.stats.metodology.total.more"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.hut.roomType"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.website"
                                                    )}
                                            </p>
                                            <p>
                                                {upperCaseFirstLetter(
                                                    t(
                                                        "statistics.stats.total.total"
                                                    ).toLowerCase()
                                                ) +
                                                    t(
                                                        "statistics.stats.metodology.total.total"
                                                    ) +
                                                    t(
                                                        "statistics.stats.metodology.website"
                                                    )}
                                            </p>
                                        </div>
                                    </details>
                                    <hr />
                                </div>
                            </details>
                            <hr />
                            <br/>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                </>
            );
        } else {
            return <></>;
        }
    }
}

export default withTranslation()(StatsNirc);
