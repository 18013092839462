import { observable, computed, action, reaction } from "mobx";
import i18n from "../../i18n";

class GenericListStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.updateProperty = this.updateProperty.bind(this);
    this.updateNestedProperty = this.updateNestedProperty.bind(this);
  }

  // observables
  @observable ad = null;
  @observable selectedList = "Ads";
  @observable sortCriteria = "id";
  @observable groupCriteria = "id";
  @observable data = [];
  @observable dataType = null;
  @observable pageIndexes = [];
  @observable totalData = [];
  @observable urlFilters = "";
  @observable loading = true;
  @observable visibleFilter = "";
  @observable selectedElemToDropdown = "";
  @observable elemsInsideDropdown = [];
  @observable limitFromURL = "";
  @observable additionalFilters = "";
  @observable showWebsite = true;
  @observable showPrice = true;
  @observable favLength = 0;
  @observable lastAction = "";
  @observable length = {
    countGroups: 0,
    countAds: 0
  }
  @observable favsLength = "";
  @observable favsList = [];
  @observable assignedList = [];

  // filters
  @observable occupancyFilter = {
    fromOption: "-1",
    toOption: "-1",
    value: "0",
    valueMax: "-",
    url: "",
    selectOptions: [],
  };

  @observable platformFilter = {
    options: [],
    url: "",
    selectOptions: [
      { key: "Airbnb", value: "Airbnb" },
      { key: "Booking", value: "Booking" },
      { key: "Homeaway", value: "Homeaway" },
      { key: "Tripadvisor", value: "Tripadvisor" },
      { key: "Housetrip", value: "Housetrip" },
      { key: "Niumba", value: "Niumba" },
      { key: "Rentalia", value: "Rentalia" },
    ],
  };

  @observable licenseFilter = {
    options: [],
    url: "",
    selectOptions: [],
  };

  @observable priceFilter = {
    fromValue: "",
    toValue: "",
    url: "",
  };

  @observable webAdsFilter = {
    value: "",
    url: "",
  };

  @observable licAdsFilter = {
    value: "",
    url: "",
  };

  @observable complianceFilter = {
    options: [],
    url: "",
    selectOptions: [],
    value: "",
    selectOptionKeys: [],
  };

  @observable complianceExtraFilter = {
    options: [],
    url: "",
    selectOptions: [],
  };

  @computed get tableFields() {
    if (this.selectedList === "Favourites") {
      let res = [
        {
          key: "groupKey",
          value: i18n.t("lists.table.groupID"),
          width: { width: "20%" },
        },
        {
          key: "workingUser",
          value: i18n.t("lists.table.assigned"),
          width: { width: "10%" },
        },
        {
          key: "licensesCode",
          value: i18n.t("lists.table.licenseCode"),
          width: { width: "20%" },
          isArray: true,
        },
      ];
      if (this.rootStore.userStore.user.productsExtra.compliance)
        res.push({
          key: "licCompliances",
          value: i18n.t("compliance.status"),
          width: { width: "20%" },
        })
      else res.push({
        key: "licensesStatus",
        value: i18n.t("lists.table.licenseStatus"),
        width: { width: "20%" },
        isArray: true,
      });
      res.push(
        {
          key: "numWebsites",
          value: i18n.t("lists.table.numWebsites"),
          width: { width: "20%" },
        },
        {
          key: "totalNumAds",
          value: i18n.t("lists.table.numAds"),
          width: { width: "20%" },
        },
      );
      return res;
    } else if (this.selectedList === "Ads") {
      let res = [
        {
          key: "id",
          value: i18n.t("lists.table.adID"),
          width: { width: "10%" },
        },
        {
          key: "workingUser",
          value: i18n.t("lists.table.assigned"),
          width: { width: "10%" },
        },
        {
          key: "groupCode",
          value: i18n.t("lists.table.groupID"),
          width: { width: "10%" },
        },
        {
          key: "licCode",
          value: i18n.t("lists.table.licenseCode"),
          width: { width: "15%" },
        },

      ];
      if (this.rootStore.userStore.user.productsExtra.compliance)
        res.push({
          key: "licCompliance",
          value: i18n.t("compliance.status"),
          width: { width: "15%" },
        })
      else
        res.push(
          {
            key: "licStatus",
            value: i18n.t("lists.table.licenseStatus"),
            width: { width: "10%" },
          })
      if (this.showWebsite)
        res.push(
          {
            key: "website",
            value: i18n.t("lists.table.platform"),
            width: { width: "15%" },
          },
          {
            key: "occupancySum",
            value: i18n.t("lists.table.occupancy"),
            width: { width: "5%" },
          },
          {
            key: "price",
            value: i18n.t("lists.table.price"),
            width: { width: "5%" },
          },
          {
            key: "roomType",
            value: i18n.t("lists.table.roomType"),
            width: { width: "10%" },
          }
        );
      else {
        if (this.showPrice)
          res.push(
            {
              key: "occupancySum",
              value: i18n.t("lists.table.occupancy"),
              width: { width: "5%" },
            },
            {
              key: "price",
              value: i18n.t("lists.table.price"),
              width: { width: "5%" },
            },
            {
              key: "roomType",
              value: i18n.t("lists.table.roomType"),
              width: { width: "10%" },
            }
          );
        else
          res.push(
            {
              key: "occupancySum",
              value: i18n.t("lists.table.occupancy"),
              width: { width: "5%" },
            },
            {
              key: "roomType",
              value: i18n.t("lists.table.roomType"),
              width: { width: "10%" },
            }
          );
      }
      return res;
    } else if (this.selectedList === "Hosts") {
      return [
        {
          key: "groupKey",
          value: i18n.t("lists.table.hostID"),
          width: { width: "20%" },
        },
        {
          key: "hostNickname",
          value: i18n.t("lists.table.hostNickname"),
          width: { width: "20%" },
        },
        {
          key: "website",
          value: i18n.t("lists.table.platform"),
          width: { width: "20%" },
        },
        {
          key: "numAds",
          value: i18n.t("lists.table.numAds"),
          width: { width: "15%" },
        },
        {
          key: "numLicenses",
          value: i18n.t("lists.table.numLicense"),
          width: { width: "15%" },
        },
      ];
    } else if (this.selectedList === "Licenses") {
      return [
        {
          key: "groupKey",
          value: i18n.t("lists.table.licenseID"),
          width: { width: "25%" },
        },
        {
          key: "numAds",
          value: i18n.t("lists.table.numAds"),
          width: { width: "25%" },
        },
        {
          key: "numWebsites",
          value: i18n.t("lists.table.numWebsites"),
          width: { width: "25%" },
        },
        {
          key: "licStatus",
          value: i18n.t("lists.table.licenseStatus"),
          width: { width: "25%" },
        },
      ];
    } else if (this.selectedList === "Assigned") {
      let res = [
        {
          key: "groupKey",
          value: i18n.t("lists.table.groupID"),
          width: { width: "20%" },
        },
        {
          key: "licensesCode",
          value: i18n.t("lists.table.licenseCode"),
          width: { width: "20%" },
          isArray: true,
        },
      ];
      if (this.rootStore.userStore.user.productsExtra.compliance)
        res.push({
          key: "licCompliances",
          value: i18n.t("compliance.status"),
          width: { width: "20%" },
        })
      else res.push({
        key: "licensesStatus",
        value: i18n.t("lists.table.licenseStatus"),
        width: { width: "20%" },
        isArray: true,
      });
      res.push(
        {
          key: "numWebsites",
          value: i18n.t("lists.table.numWebsites"),
          width: { width: "20%" },
        },
        {
          key: "totalNumAds",
          value: i18n.t("lists.table.numAds"),
          width: { width: "20%" },
        },
      );
      return res;
    }
    return [];
  }

  @computed get tableDropdownFields() {
    if (this.selectedList === "Favourites") {
      // let example = {
      //   _id: "bookng27941",
      //   price: 71,
      //   city: "Castelldefels",
      //   website: "Booking",
      //   adGroupCode: "10000004",
      //   "license.code": "ATB-000008"
      // };
      if (this.rootStore.userStore.user.productsExtra.compliance)
        return [
          {
            key: "id",
            value: i18n.t("lists.table.adID"),
            width: { width: "20%" },
          },
          {
            key: "license.code",
            value: i18n.t("lists.table.licenseCode"),
            width: { width: "20%" },
          },
          {
            key: "license.compliance_status",
            value: i18n.t("compliance.status"),
            width: { width: "20%" },
          },
          {
            key: "city",
            value: i18n.t("lists.table.city"),
            width: { width: "20%" },
          },
          {
            key: "price",
            value: i18n.t("lists.table.price"),
            width: { width: "20%" },
          },
        ];
      else
        return [
          {
            key: "id",
            value: i18n.t("lists.table.adID"),
            width: { width: "20%" },
          },
          {
            key: "license.code",
            value: i18n.t("lists.table.licenseCode"),
            width: { width: "20%" },
          },
          {
            key: "license.status",
            value: i18n.t("lists.table.licenseStatus"),
            width: { width: "20%" },
          },
          {
            key: "city",
            value: i18n.t("lists.table.city"),
            width: { width: "20%" },
          },
          {
            key: "price",
            value: i18n.t("lists.table.price"),
            width: { width: "20%" },
          },
        ];
    } else if (this.selectedList === "Hosts") {
      // let example = {
      //   _id: "airbnb16923588",
      //   price: 100,
      //   city: "Sant Feliu de GuÃ­xols",
      //   website: "Airbnb",
      //   "license.code": "HUTG-025321"
      // };
      if (this.rootStore.userStore.user.productsExtra.compliance)
        return [
          {
            key: "id",
            value: i18n.t("lists.table.adID"),
            width: { width: "20%" },
          }, {
            key: "workingUser",
            value: i18n.t("lists.table.assigned"),
            width: { width: "10%" },
          },{
            key: "adGroupCode",
            value: i18n.t("lists.table.groupID"),
            width: { width: "15%" },
          },
          {
            key: "license.code",
            value: i18n.t("lists.table.licenseCode"),
            width: { width: "20%" },
          },
          {
            key: "license.compliance_status",
            value: i18n.t("compliance.status"),
            width: { width: "20%" },
          },
          {
            key: "city",
            value: i18n.t("lists.table.city"),
            width: { width: "20%" },
          },
        ];
      else
        return [
          {
            key: "id",
            value: i18n.t("lists.table.adID"),
            width: { width: "20%" },
          },
          {
            key: "workingUser",
            value: i18n.t("lists.table.assigned"),
            width: { width: "10%" },
          },
          {
            key: "adGroupCode",
            value: i18n.t("lists.table.groupID"),
            width: { width: "15%" },
          },
          {
            key: "license.code",
            value: i18n.t("lists.table.licenseCode"),
            width: { width: "20%" },
          },
          {
            key: "license.status",
            value: i18n.t("lists.table.licenseStatus"),
            width: { width: "20%" },
          },
          {
            key: "city",
            value: i18n.t("lists.table.city"),
            width: { width: "15%" },
          },
        ];
    } else if (this.selectedList === "Licenses") {
      // let example = {
      //   _id: "homeawp8468291",
      //   price: 102,
      //   city: "Pals",
      //   website: "Homeaway",
      //   adGroupCode: "10049004",
      //   "license.code": "â€‹HUTG-008893"
      // };
      return [
        {
          key: "id",
          value: i18n.t("lists.table.adID"),
          width: { width: "25%" },
        },
        
        {
          key: "adGroupCode",
          value: i18n.t("lists.table.groupID"),
          width: { width: "20%" },
        },
        {
          key: "license.code",
          value: i18n.t("lists.table.licenseCode"),
          width: { width: "20%" },
        },
        {
          key: "city",
          value: i18n.t("lists.table.city"),
          width: { width: "25%" },
        },
      ];
    } else if (this.selectedList === "Assigned") {
      if (this.rootStore.userStore.user.productsExtra.compliance)
        return [
          {
            key: "id",
            value: i18n.t("lists.table.adID"),
            width: { width: "20%" },
          },
          {
            key: "license.code",
            value: i18n.t("lists.table.licenseCode"),
            width: { width: "20%" },
          },
          {
            key: "license.compliance_status",
            value: i18n.t("compliance.status"),
            width: { width: "20%" },
          },
          {
            key: "city",
            value: i18n.t("lists.table.city"),
            width: { width: "20%" },
          },
          {
            key: "price",
            value: i18n.t("lists.table.price"),
            width: { width: "20%" },
          },
        ];
      else
        return [
          {
            key: "id",
            value: i18n.t("lists.table.adID"),
            width: { width: "20%" },
          },
          {
            key: "license.code",
            value: i18n.t("lists.table.licenseCode"),
            width: { width: "20%" },
          },
          {
            key: "license.status",
            value: i18n.t("lists.table.licenseStatus"),
            width: { width: "20%" },
          },
          {
            key: "city",
            value: i18n.t("lists.table.city"),
            width: { width: "20%" },
          },
          {
            key: "price",
            value: i18n.t("lists.table.price"),
            width: { width: "20%" },
          },
        ];
    }
    return [];
  }

  @computed get tableData() {
    let data = this.totalData.slice(
      this.rootStore.pageStore.len * this.rootStore.pageStore.page,
      this.rootStore.pageStore.len * (this.rootStore.pageStore.page + 1)
    );

    // for (let i = 0; i < data.length; i++) {
    //   for (var key in data[i]) {
    //     if (data[i].hasOwnProperty(key)) {
    //       if (!data[i][key]) {
    //         data[i][key] = "-";
    //       }
    //     }
    //   }
    // }
    return data;
  }

  @computed get defaultSortCriteria() {
    let res;
    if (this.selectedList === "Favourites") {
      //res = "groupId";
      res = "groupKey";
    } else if (this.selectedList === "Ads") {
      res = "id";
    } else if (this.selectedList === "Hosts") {
      //res = "hostId";
      res = "groupKey";
    } else if (this.selectedList === "Licenses") {
      //res = "licCode";
      res = "groupKey";
    } else if (this.selectedList === "Assigned") {
      res = "groupKey";
    }
    return res;
  }

  @computed get defaultGroupCriteria() {
    let res;
    if (this.selectedList === "Favourites") {
      res = "adGroupCode";
    } else if (this.selectedList === "Ads") {
      res = "id";
    } else if (this.selectedList === "Hosts") {
      res = "hostId";
    } else if (this.selectedList === "Licenses") {
      res = "licCode";
    } else if (this.selectedList === "Assigned") {
      res = "groupKey";
    }

    return res;
  }

  @action("Initialize Store") initializeStore = async () => {
    this.getPlatformFilterOptions();
    this.createOptions();
    this.initView();
    this.getComplianceFilterOptions();
    //funció pel número de assigned
    console.log("PRE COUNT ASSIGNED")
    this.countAssigned();
    this.countFavs();
    console.log("POST COUNT FAVS")
  }

  @action("Get compliance options for the filter") getComplianceFilterOptions = () => {
    let selectOptions = []
    let selectOptionsExtra = []

    let pes = this.rootStore.userStore.user.productsExtra
    for (let cs of pes.complianceStatus) {
      selectOptions.push({ key: cs.key, value: i18n.t("compliance." + cs.key), fillColor: cs.fillColor, strokeColor: cs.strokeColor });
      this.complianceFilter.selectOptionKeys.push(cs.key);
    }

    if (pes.constraints) {
      for (let constraint of pes.constraints)
        selectOptionsExtra = { key: constraint.key, value: i18n.t("compliance." + constraint.key) }
    }
    else {
      selectOptionsExtra = [
        { key: "show_text", value: i18n.t("compliance.show_text") },
        { key: "registry_match", value: i18n.t("compliance.registry_match") },
        { key: "radi_municipality", value: i18n.t("compliance.radi_municipality") },
        { key: "pattern_match", value: i18n.t("compliance.pattern_match") },
        { key: "duplicated", value: i18n.t("compliance.duplicated") },
        { key: "commercial_name", value: i18n.t("compliance.commercial_name") },
      ]
    }
    this.complianceFilter.selectOptions = selectOptions;
    this.complianceExtraFilter.selectOptions = selectOptionsExtra;
  }

  @action("Get platform options for the filter")
  getPlatformFilterOptions = async () => {
    let res = await this.rootStore.requestStore.getPlatforms();
    let selectOptions = [];

    if (!!res) {
      let platforms = res.data;
      for (let platform of platforms) {
        if(platform == "Vrbo") selectOptions.push({ key: "Homeaway", value: platform });
        else selectOptions.push({ key: platform, value: platform });
      }
    }
    this.platformFilter.selectOptions = selectOptions;
  };

  @action("Create options for filters") createOptions = () => {
    this.occupancyFilter.selectOptions = [
      { key: "01", value: i18n.t("months.january") },
      { key: "02", value: i18n.t("months.february") },
      { key: "03", value: i18n.t("months.march") },
      { key: "04", value: i18n.t("months.april") },
      { key: "05", value: i18n.t("months.may") },
      { key: "06", value: i18n.t("months.june") },
      { key: "07", value: i18n.t("months.july") },
      { key: "08", value: i18n.t("months.august") },
      { key: "09", value: i18n.t("months.september") },
      { key: "10", value: i18n.t("months.october") },
      { key: "11", value: i18n.t("months.november") },
      { key: "12", value: i18n.t("months.december") },
    ];

    this.licenseFilter.selectOptions = [
      { key: "official", value: i18n.t("lists.official") },
      { key: "badform", value: i18n.t("lists.badFormat") },
      { key: "notoff", value: i18n.t("lists.noOfficial") },
      { key: "nolic", value: i18n.t("lists.noLicense") },
    ];
  };

  @action("update attribute") updateProperty = (attrName, value) => {
    this[attrName] = value;
  };

  @action("update selectedElemToDropdown")
  updateSelectedElemToDropdown = (idx) => {
    if (this.selectedElemToDropdown === idx) {
      this.selectedElemToDropdown = "";
    } else {
      // this.selectedElemToDropdown = "";
      this.selectedElemToDropdown = idx;

      this.getElemsInsideDropdown();
    }
  };

  parseNestedData = (data) => {
    for (let i = 0; i < data.length; i++) {
      for (let key in data[i]) {
        if (typeof data[i][key] === "object" && data[i][key] !== null) {
          for (let key2 in data[i][key]) {
            var secondKey = key2;
            data[i][key + "." + secondKey] = data[i][key][key2];
          }
          delete data[i][key];
        }
      }
    }
    return data;
  };

  getElemsInsideDropdown = async () => {
    try {
      if (this.selectedList !== "Ads" && this.selectedElemToDropdown !== "") {
        this.updateProperty("elemsInsideDropdown", []);
        let adIds = this.tableData[this.selectedElemToDropdown].adIds;
        let data = await this.rootStore.requestStore.getMultipleApartmentsById(
          adIds
        );
        let res = this.parseNestedData(data);
        this.updateProperty("elemsInsideDropdown", res);
        if (this.selectedList !== "Assigned") this.rootStore.mapListStore.printAdsFromList(res);
      }
    } catch (e) {
      if (e.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  printCircles = async (ad) => {
    this.rootStore.mapListStore.changeCircleColor(ad);
  };

  removePrintCircles = async () => {
    this.rootStore.mapListStore.switchCircleColor("repaint");
  };

  //Prints all ads in the adgroup
  printGroupCircles = async (adGroup) => {
    let realAdGroup = await this.rootStore.requestStore.getAdGroupById(adGroup.groupKey);
    let adsList = await this.rootStore.requestStore.getAdsByGroup(adGroup.groupKey);
    adsList = adsList.data;
    let geometries = [];
    for (let i = 0; i < adsList.length; i++) {
      geometries.push(adsList[i].geometry);
    }
    let data = realAdGroup.data;
    data.childrenGeometry = geometries;
    let res = this.parseNestedData(data);
    this.rootStore.mapListStore.changeCirclesColor(res);
  }

  focusAd = async (ad) => {
    let res = await this.rootStore.requestStore.getApartmentById(ad);
    this.rootStore.mapListStore.PrintAndFocusAd(res.data);
  };

  @action("update attribute") updateVisibleFilter = (value) => {
    if (this.visibleFilter === value) this.visibleFilter = "";
    else this.visibleFilter = value;
  };

  @action("update checkbox filter") updateRadioButtonListFilter = (attrPath, value, key) => {
    let path = attrPath.split(".");
    let array = this[path[0]][path[1]];
    console.log(path[0], path[1], attrPath, array, key, value);
    if (value) {
        this[path[0]][path[1]] = key;
    } else {
        this[path[0]][path[1]] = "";
    }
  };

  @action("update nested attribute") updateNestedProperty = (
    attrPath,
    value
  ) => {
    let path = attrPath.split(".");
    this[path[0]][path[1]] = value;
  };

  @action("update attribute") toggleProperty = (attrName) => {
    this[attrName] = !this[attrName];
  };

  @action("update nested attribute") toggleNestedProperty = (attrPath) => {
    let path = attrPath.split(".");
    this[path[0]][path[1]] = !this[path[0]][path[1]];
  };

  @action("update checkbox filter") updateCheckboxListFilter = (
    attrPath,
    key,
    value
  ) => {
    let path = attrPath.split(".");
    let array = this[path[0]][path[1]];
    if (!value) {
      const index = array.indexOf(key);
      if (index > -1) {
        array.splice(index, 1);
      }
    } else {
      array.push(key);
    }
    this[path[0]][path[1]] = array;
  };

  goToGroupAdsPage = (groupId) => {
    this.rootStore.navigationStore.openInNewTab("/groupAds/" + groupId);
  };

  changeSortCriteria = (field) => {
    //console.log("FIELD IS... ", field)
    if (field !== this.sortCriteria) {
      this.updateProperty("sortCriteria", field);
    } else {
      this.rootStore.pageStore.sortDirection =
        -1 * this.rootStore.pageStore.sortDirection;
    }
    this.updateData(false, true);
  };

  initView = () => {
    this.initFilters();
    this.updateData(true, false);
  };

  initFilters = () => {
    let search = this.rootStore.navigationStore.history.location.search;
    if (!!search) {
      if (search[0] === "?") search = search.substr(1);
      let searchParams = search.split("&");
      for (let i = 0; i < searchParams.length; i++) {
        let elemParams = searchParams[i].split("=");
        let values;
        switch (elemParams[0]) {
          case "list":
            this.updateProperty("selectedList", elemParams[1]);
            break;
          case "group_by":
            this.updateProperty("groupCriteria", elemParams[1]);
            break;
          case "sort":
            this.updateProperty("sortCriteria", elemParams[1]);
            break;
          case "dir":
            this.rootStore.pageStore.updateProperty(
              "sortDirection",
              parseInt(elemParams[1])
            );
            break;
          case "page":
            this.rootStore.pageStore.updateProperty(
              "page",
              parseInt(elemParams[1]) - 1
            );
            break;
          case "occ":
            values = elemParams[1].split("$");
            this.updateNestedProperty("occupancyFilter.fromOption", values[0]);
            this.updateNestedProperty("occupancyFilter.toOption", values[1]);
            this.updateNestedProperty("occupancyFilter.value", values[2]);
            this.updateNestedProperty("occupancyFilter.valueMax", values[3])
            this.updateOccupancyUrl();
            break;
          case "platf":
            values = elemParams[1].split("$");
            this.updateNestedProperty("platformFilter.options", values);
            this.updatePlatformUrl();
            break;
          case "licstate":
            values = elemParams[1].split("$");
            this.updateNestedProperty("licenseFilter.options", values);
            this.updateLicenseUrl();
            break;
          case "price":
            values = elemParams[1].split("$");
            this.updateNestedProperty("priceFilter.fromValue", values[0]);
            this.updateNestedProperty("priceFilter.toValue", values[1]);
            this.updatePriceUrl();
            break;
          case "webads":
            values = elemParams[1];
            this.updateNestedProperty("webAdsFilter.value", values);
            this.updateWebAdsUrl();
            break;
          case "licads":
            values = elemParams[1];
            this.updateNestedProperty("licAdsFilter.value", values);
            this.updateLicAdsUrl();
            break;
          case "limit":
            values = elemParams[1];
            this.updateProperty("limitFromURL", values);
            break;
          default:
            this.updateProperty(
              "additionalFilters",
              elemParams[0] + "=" + elemParams[1]
            );
            break;
        }
      }
    }
  };

  getNewUrl = () => {
    try {
      let res = "?list=" + this.selectedList;
      res += "&group_by=" + this.groupCriteria;
      if (!!this.occupancyFilter && !!this.occupancyFilter.url) res += "&" + this.occupancyFilter.url;
      if (!!this.platformFilter && !!this.platformFilter.url) {
        res += "&" + this.platformFilter.url;
      }
      if (!!this.licenseFilter && !!this.licenseFilter.url) {
        res += "&" + this.licenseFilter.url;
      }
      if (!!this.priceFilter && !!this.priceFilter.url) {
        res += "&" + this.priceFilter.url;
      }
      if (!!this.webAdsFilter && !!this.webAdsFilter.url) {
        res += "&" + this.webAdsFilter.url;
      }
      if (!!this.licAdsFilter && !!this.licAdsFilter.url) {
        res += "&" + this.licAdsFilter.url;
      }
      if (!!this.complianceFilter && !!this.complianceFilter.url) {
        res += "&" + this.complianceFilter.url;
      }
      if (!!this.additionalFilters) {
        res += "&" + this.additionalFilters;
      }
      if (!!this.sortCriteria) {
        res += "&sort=" + this.sortCriteria;
        res += "&dir=" + this.rootStore.pageStore.sortDirection;
      } else {
        res += "&sort=" + this.defaultSortCriteria
        res += "&dir=1";
      }
      res += "&page=" + (this.rootStore.pageStore.page + 1);
      // Limit ha d'anar l'Ãºltim perquÃ¨ a la funciÃ³ this.getTotalData() es trunca per limit
      // i s'agafa el seu nÃºmero per decidir el nombre d'elements totals a agafar
      if (this.limitFromURL !== "") res += "&limit=" + this.limitFromURL;
      else res += "&limit=" + this.rootStore.pageStore.len;
      return res;
    } catch (e) {
      if (e.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  updateData = async (firstTime, resetPage) => {
    try {
      this.updateProperty("urlFilters", this.getNewUrl());
      this.updateProperty("loading", true);
      this.updateProperty("selectedElemToDropdown", "");
      this.updateProperty("elemsInsideDropdown", []);
      if (firstTime)
        this.rootStore.navigationStore.replace("/lists" + this.urlFilters);
      else this.rootStore.navigationStore.push("/lists" + this.urlFilters);
      let retrievedData = await this.getTotalData();
      console.log("retrieved data: ", retrievedData);
      this.updateProperty("totalData", retrievedData);
      this.rootStore.pageStore.updateProperty(
        "totalPages",
        Math.ceil(this.totalData.length / this.rootStore.pageStore.len)
      );
      if (resetPage) this.rootStore.pageStore.updateProperty("page", 0);
      this.updateProperty("loading", false);
      if (this.selectedList === "Assigned") {
        let data = this.assignedList;
        let res = this.parseNestedData(data);
        this.rootStore.mapListStore.printAdsFromList(res);
      }
    } catch (error) {
      console.log("An error ocurred while executing the action requested");
    }
  };

  getTotalData = async () => {
    try {
      let paginationUrl = this.urlFilters;
      let urlArray = paginationUrl.split("limit=");
      paginationUrl =
        urlArray[0] +
        "limit=" +
        parseInt(urlArray[1]) * this.rootStore.pageStore.maxPages;
      let res = [];
      if (this.selectedList === "Favourites") {
        let resposta = await this.rootStore.requestStore.getFavsListPaginated(
          paginationUrl
        );
        this.favsList = resposta.ads;
        res = resposta.adGroups;

        let groups = {};
        for (let i in this.favsList) {
          if (groups[this.favsList[i].adGroupCode] === undefined) groups[this.favsList[i].adGroupCode] = [];
          groups[this.favsList[i].adGroupCode].push(this.favsList[i]);
        }

        for (let i in res) {
          let numComplianceStatus = {};
          if (res[i].licCompliances[0].length > 0) {
            let found = false;
            let listGroups = Object.keys(groups);
            for (let j = 0; j < listGroups.length && !found; ++j) {
              if (res[i].groupKey === listGroups[j]) {
                numComplianceStatus = this.countAdsByComplianceStatus(groups[listGroups[j]]);
                res[i].numComplianceStatus = numComplianceStatus;
                found = true;
              }
            }
          }
        }

      } else if (this.selectedList === "Ads") {
        res = await this.rootStore.requestStore.getAdListPaginated(
          paginationUrl
        );
        res = res.map(item => {
          let result = ""
          if (Array.isArray(item.workingUser)) result = item.workingUser.join(", ")
          item.workingUser = result
          return item
        })
        // res = this.parseLicenses(res);
      } else if (this.selectedList === "Hosts") {
        res = await this.rootStore.requestStore.getHostsListPaginated(
          paginationUrl
        );
      } else if (this.selectedList === "Licenses") {
        res = await this.rootStore.requestStore.getLicensesListPaginated(
          paginationUrl
        );
        // res = this.parseLicenses(res);
      } else if (this.selectedList === "Assigned") {
        let resposta = await this.rootStore.requestStore.getAssignedListPaginated(
          paginationUrl
        );
        this.assignedList = resposta.ads;
        res = resposta.adGroups;

        let groups = {};
        for (let i in this.assignedList) {
          if (groups[this.assignedList[i].adGroupCode] === undefined) groups[this.assignedList[i].adGroupCode] = [];
          groups[this.assignedList[i].adGroupCode].push(this.assignedList[i]);
        }

        for (let i in res) {
          let numComplianceStatus = {};
          if (res[i].licCompliances[0].length > 0) {
            let found = false;
            let listGroups = Object.keys(groups);
            for (let j = 0; j < listGroups.length && !found; ++j) {
              if (res[i].groupKey === listGroups[j]) {
                numComplianceStatus = this.countAdsByComplianceStatus(groups[listGroups[j]]);
                res[i].numComplianceStatus = numComplianceStatus;
                found = true;
              }
            }
          }
        }

      }
      return res;
    } catch (e) {
      if (e.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  };

  // TABS
  openListTabs = (listName) => {
    if(!this.loading) {
      this.updateProperty("additionalFilters", "");
      this.updateProperty("selectedElemToDropdown", "");
      this.updateProperty("elemsInsideDropdown", []);
      this.updateProperty("selectedList", listName);
      this.resetFilters();
      if (listName === "Licenses") {
        if(this.rootStore.userStore.user.group === "generalitat") 
		this.updateNestedProperty("licenseFilter.options", [
          		"notoff",
          		"official",
          		"badform",
        	]);
	else this.updateNestedProperty("licenseFilter.options", ["any"]);
        this.updateLicenseUrl();
      }
      this.updateProperty("sortCriteria", this.defaultSortCriteria);
      this.updateProperty("groupCriteria", this.defaultGroupCriteria);
      this.updateData(false, true);
    }
    else{
      this.lastAction = listName
    }
    reaction(
      () => this.loading,
      (loading) => {
          if(!loading){
            if(this.lastAction !== ""){
              let thing = this.lastAction
              this.lastAction = ""
              this.openListTabs(thing)
            }
          }
      }
  );
};


  // END TABS

  // FILTERS
  resetFilters = () => {
    this.resetOccupancyFilter();
    this.resetPlatformFilter();
    this.resetLicenseFilter();
    this.resetPriceFilter();
    this.resetWebAdsFilter();
    this.resetLicAdsFilter();
  };

  @action resetOccupancyFilter = () => {
    this.occupancyFilter.fromOption = "-1";
    this.occupancyFilter.toOption = "-1";
    this.occupancyFilter.value = "0";
    this.occupancyFilter.valueMax = "-";
    this.occupancyFilter.url = "";
  };

  @action resetPlatformFilter = () => {
    this.platformFilter.options = [];
    this.platformFilter.url = "";
  };

  @action resetLicenseFilter = () => {
    this.licenseFilter.options = [];
    this.licenseFilter.url = "";
  };

  @action resetPriceFilter = () => {
    this.priceFilter.fromValue = "";
    this.priceFilter.toValue = "";
    this.priceFilter.url = "";
  };

  @action resetWebAdsFilter = () => {
    this.webAdsFilter.value = "";
    this.webAdsFilter.url = "";
  };

  @action resetLicAdsFilter = () => {
    this.licAdsFilter.value = "";
    this.licAdsFilter.url = "";
  };

  @action resetComplianceFilter = () => {
    this.complianceFilter.options = [];
    this.complianceFilter.url = "";
    this.complianceFilter.value = "";
    this.complianceExtraFilter.options = [];
    this.complianceExtraFilter.url = "";
  }

  updateOccupancyUrl = () => {
    this.updateNestedProperty(
      "occupancyFilter.url",
      "occ=" +
      this.occupancyFilter.fromOption +
      "$" +
      this.occupancyFilter.toOption +
      "$" +
      this.occupancyFilter.value +
      "$" +
      this.occupancyFilter.valueMax
    );
  };

  updatePlatformUrl = () => {
    let urlParams = "";
    for (let i = 0; i < this.platformFilter.options.length; i++) {
      if (urlParams !== "") urlParams += "$";
      urlParams += this.platformFilter.options[i];
    }
    this.updateNestedProperty("platformFilter.url", "platf=" + urlParams);
  };

  updateLicenseUrl = () => {
    let urlParams = "";
    for (let i = 0; i < this.licenseFilter.options.length; i++) {
      if (urlParams !== "") urlParams += "$";
      urlParams += this.licenseFilter.options[i];
    }

    this.updateNestedProperty("licenseFilter.url", "licstate=" + urlParams);
  };

  updatePage = (pageNum) => {
    this.updateProperty("page", pageNum);
    this.updateProperty("urlFilters", this.getNewUrl());
    this.rootStore.navigationStore.push("/lists" + this.urlFilters);
  };

  updatePriceUrl = () => {
    this.updateNestedProperty(
      "priceFilter.url",
      "price=" + this.priceFilter.fromValue + "$" + this.priceFilter.toValue
    );
  };

  updateWebAdsUrl = () => {
    this.updateNestedProperty(
      "webAdsFilter.url",
      "webads=" + this.webAdsFilter.value
    );
  };

  updateLicAdsUrl = () => {
    this.updateNestedProperty(
      "licAdsFilter.url",
      "licads=" + this.licAdsFilter.value
    );
  };

  updateComplianceUrl = () => {
    let urlParams = "";
    if (this.complianceFilter.value !== "") {
        urlParams += this.complianceFilter.value;
    }
    for (let i = 0; i < this.complianceExtraFilter.options.length; i++) {
        if (urlParams !== "") urlParams += "$";
        urlParams += this.complianceExtraFilter.options[i];
    }
    this.updateNestedProperty("complianceFilter.url", "compliance=" + urlParams);
};

  saveOccupancyFilter = () => {
    this.updateOccupancyUrl();
    this.updateVisibleFilter("occupancy");
  };

  savePlatformFilter = () => {
    this.updatePlatformUrl();
    this.updateVisibleFilter("platform");
  };

  saveLicenseFilter = () => {
    this.updateLicenseUrl();
    this.updateVisibleFilter("license");
  };

  savePriceFilter = () => {
    this.updatePriceUrl();
    this.updateVisibleFilter("price");
  };

  saveWebAdsFilter = () => {
    this.updateWebAdsUrl();
    this.updateVisibleFilter("webAds");
  };

  saveLicAdsFilter = () => {
    this.updateLicAdsUrl();
    this.updateVisibleFilter("licAds");
  };

  saveComplianceFilter = () => {
    this.updateComplianceUrl();
    this.updateVisibleFilter("compliance");
  }

  saveFilter = (type) => {
    if (type === "occupancy") {
      this.saveOccupancyFilter();
    } else if (type === "platform") {
      this.savePlatformFilter();
    } else if (type === "license") {
      this.saveLicenseFilter();
    } else if (type === "price") {
      this.savePriceFilter();
    } else if (type === "webads") {
      this.saveWebAdsFilter();
    } else if (type === "licads") {
      this.saveLicAdsFilter();
    } else if (type === "compliance") {
      this.saveComplianceFilter();
    }
    this.updateData(false, true);
  };

  deleteFilter = (type) => {
    //filters
    if (type === "occupancy") {
      this.updateVisibleFilter("occupancy");
      this.resetOccupancyFilter();
    } else if (type === "platform") {
      this.updateVisibleFilter("platform");
      this.resetPlatformFilter();
    } else if (type === "license") {
      this.updateVisibleFilter("license");
      this.resetLicenseFilter();
    } else if (type === "price") {
      this.updateVisibleFilter("price");
      this.resetPriceFilter();
    } else if (type === "webads") {
      this.updateVisibleFilter("webAds");
      this.resetWebAdsFilter();
    } else if (type === "licads") {
      this.updateVisibleFilter("licAds");
      this.resetLicAdsFilter();
    } else if (type == "compliance") {
      this.updateVisibleFilter("compliance");
      this.resetComplianceFilter();
    }

    this.updateData(false, true);
  };

  parseLicenses = (array) => {
    for (let i = 0; i < array.length; i++) {
      if (!array[i]["licCode"]) array[i]["licCode"] = "-";
      let state = "No license";
      switch (array[i]["licStatus"]) {
        case true:
          state = "Official";
          break;
        case false:
          state = "Not official";
          break;
        case "Not_in_DDBB":
          state = "Not in Database";
          break;
        case "Bad Format":
          state = "Bad Format";
          break;
        default:
          state = "-";
          break;
      }
      array[i]["licStatus"] = state;
    }
    return array;
  };
  // END FILTERS

  //Counts the number of ads and adGroups assigned to the user
  countAssigned = async () => {
    let assignedCount = await this.rootStore.requestStore.getAssignedCount();
    console.log(assignedCount)
    this.length = assignedCount.data;
  }

  //Counts the number of ads and adGroups that the user has as favorites
  countFavs = async () => {
    let favsCount = await this.rootStore.requestStore.getFavsCount();
    this.favsLength = favsCount.data.countAds;
  }

  //Counts the instances of each type of compliance status in a list of ads
  countAdsByComplianceStatus = (ads) => {
    let cont = {};
    for (let i in ads) {
      if (cont[ads[i].license.compliance_status] === undefined) cont[ads[i].license.compliance_status] = 1;
      else cont[ads[i].license.compliance_status] += 1;
    }
    return cont;
  }

}

export default GenericListStore;
