import React, { useState, useRef, useEffect } from 'react';
import MyButton from "../basicUI/MyButton";
import i18n from "../../i18n";


const VirtualizedList = ({ adGroupEditStore, adGroups, itemHeight, height, onButtonClick, isInMyAssigned, onClickTh }) => {
  const [scrollTop, setScrollTop] = useState(0);
  const containerRef = useRef(null);

  const handleScroll = () => {
    setScrollTop(containerRef.current.scrollTop);
  };

  const totalHeight = adGroups.length * itemHeight;
  const startIndex = Math.floor(scrollTop / itemHeight);
  const endIndex = Math.min(adGroups.length - 1, Math.floor((scrollTop + height) / itemHeight));
  const visibleItems = adGroups.slice(startIndex, endIndex + 1);

  const paddingTop = startIndex * itemHeight;
  const paddingBottom = totalHeight - paddingTop - visibleItems.length * itemHeight;

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{ height: `90%`, overflowY: 'auto', position: 'relative' }}
    >
      <div style={{ height: `${totalHeight}px`, position: 'relative' }}>
        <div style={{ paddingTop, paddingBottom }}>
          <table>
            <tbody>
            {visibleItems.map((adgroup, index) => {
              if (!isInMyAssigned(adgroup)) {
                return (
                  <tr key={adgroup._id}><td width="10%">
                    <MyButton
                      type="button"
                      text="+"
                      className="btn btn-outline-info btn-add"
                      id={adgroup._id}
                      onClick={(e) => onButtonClick(adgroup._id)}
                    /></td>
                    <td width="20%">{adgroup._id}</td><td width="16%">{adgroup.childrenId.length}</td><td  width="22%">{adgroup.municipality ? adgroup.municipality : adgroup.municipi}</td><td width="27%">{adgroup.roomType}</td>
                  </tr>
                );
              }
              return null;
            })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default VirtualizedList;
