import { observable, action, computed } from "mobx";
class OtaStore {
	constructor(rootStore) {
		this.rootStore = rootStore;
	}

	@action("Download Excel") downloadExcel = async () => {
		let arrayData = []
		let plataform = ["bookng", "bookng", "bookng", "bookng", "bookng"]
		let ids = ["1001977","1004119","1010034","1014230","1015197"]
		let urls = ["https://www.booking.com/hotel/es/jam-hostel-barcelona.ca.html","https://www.booking.com/hotel/es/sensation-authentic-gracia.ca.html","https://www.booking.com/hotel/es/ideal-youth-hostel.ca.html","https://www.booking.com/hotel/es/hostal-argo.ca.html","https://www.booking.com/hotel/es/apartamentos-las-dunas-deltebre1.ca.html"]
		let nirtcs = ["","HUTX-000627","","HUTB-004821",""] 
		for (let i = 0; i < 5; i++) {
			let data = {}
			data["N."] = i + 1
			data["Plataforma"] = plataform[i]
			data["id Announcement"] = ids[i]
			data["Url"] = urls[i]
			data["NIRTC Web"] = nirtcs[i]
			data["Legislation"] = "Law 13/2002 and Decree 75/2020 see articles *"
			arrayData.push(data)
		}
		await this.rootStore.requestStore.generateExcelOTA("Template", arrayData);
	}

}
export default OtaStore;