import { observable, action } from "mobx";

class CommandStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @observable cityHallData = null;
    @observable cityName;
    @observable cityHallDataLast = null;
    @observable cityId;
    @observable cityAdm;
    @observable errorGroupAdsCount;
    @observable month;
    @observable dateInfo = "03-2024"

    @action("return month") returnAvailableMonth = async (
        data,
        month,
        cont
    ) => {
        //Si hay datos del mes, devuelve el mes
        if (data[month] && Object.keys(data[month]).length > 0) return month;
        else {
            //Si el contador es 12 ya ha pasado por todo los meses (Del 0 al 11) y por lo tanto que devuelva error
            if (cont === 12) {
                return null;
            }
            //Si el mes es 0, vuelve al 11 y sino se resta uno. Falta volver a llamar a la funcion con parametros correctos
            else {
                let myMonth = month === 0 ? 11 : month - 1;
                return this.returnAvailableMonth(data, myMonth, cont + 1);
            }
        }
    };

    @action("save current changes") initiateStore = async () => {
        let search = this.rootStore.navigationStore.history.location.search;
        if (!!search || search === "") {
            if (search[0] === "?") {
                search = search.substr(1);
                let elemParams = search.split("=");
                if (elemParams[0] === "region") {
                    let city =
                        await this.rootStore.requestStore.getCityHallData(
                            elemParams[1]
                        );
                    let cityname =
                        await this.rootStore.requestStore.getCityHallName(
                            elemParams[1]
                        );
                    console.log(city, cityname);
                    this.cityId = city._id;
                    this.cityAdm = cityname.adm;
                    this.cityName = cityname.name;
                    if (city.dateInfo != undefined) this.dateInfo = city.dateInfo;
                    //let month = (new Date().getMonth()) % 12;
                    let month = (new Date().getMonth() + 1) % 12;
                    let myMonth = await this.returnAvailableMonth(
                        city.city_hall.dataHistory,
                        month,
                        0
                    );
                    if (myMonth !== null) {
                        this.cityHallData = city.city_hall.dataHistory[myMonth];
                        this.month = myMonth;
                        // if (city.city_hall.dataHistory[month] && Object.keys(city.city_hall.dataHistory[month]).length > 0) this.cityHallData = city.city_hall.dataHistory[month];
                        // else this.cityHallData = city.city_hall.dataHistory[month-1];
                        let pastMonth = null;

                        if (month !== 0)
                            pastMonth = await this.returnAvailableMonth(
                                city.city_hall.dataHistory,
                                month - 1,
                                0
                            );
                        else
                            pastMonth = await this.returnAvailableMonth(
                                city.city_hall.dataHistory,
                                11,
                                0
                            );

                        if (pastMonth !== null)
                            this.cityHallDataLast =
                                city.city_hall.dataHistory[pastMonth];
                        let groupAdsError =
                            await this.rootStore.requestStore.getGroupAdsErrorCount(
                                city._id
                            );
                        // console.log(groupAdsError);
                        this.errorGroupAdsCount = groupAdsError.count;
                        // console.log(this.errorGroupAdsCount);
                    }
                }
            } else {
                let userData = (
                    await this.rootStore.requestStore.getUserAssignedRegions(
                        this.rootStore.userStore.getUser().id
                    )
                ).data;
                this.rootStore.navigationStore.replace(
                    "/command?region=" + userData.assignedRegions[0]
                );
                this.rootStore.navigationStore.reload();
            }
        }
    };

    @action("go to filter") tableToWA = (row, column) => {
        if (row !== "num_users") {
            if (row === "total_ads") {
                if (column !== "total")
                    this.rootStore.navigationStore.openInNewTab(
                        "area?focusedRegion=" +
                            this.cityId +
                            "&platf=" +
                            column[0].toUpperCase() +
                            column.substring(1)
                    );
                else
                    this.rootStore.navigationStore.openInNewTab(
                        "/area?focusedRegion=" + this.cityId
                    );
            } else {
                if (column !== "total")
                    this.rootStore.navigationStore.openInNewTab(
                        "area?focusedRegion=" +
                            this.cityId +
                            "&cityhall=" +
                            row +
                            "&platf=" +
                            column[0].toUpperCase() +
                            column.substring(1)
                    );
                else
                    this.rootStore.navigationStore.openInNewTab(
                        "/area?focusedRegion=" +
                            this.cityId +
                            "&cityhall=" +
                            row
                    );
            }
        }
    };

    @action("Change city") changeCity = async (id) => {
        let city = await this.rootStore.requestStore.getCityHallData(id);
        this.rootStore.navigationStore.replace("/command?region=" + city._id);
        this.rootStore.navigationStore.reload();
    };

    @action("Download Excel") downloadExcel = async (
        userName,
        platform,
        license
      ) => {
        console.log("DOING DOWNLOADING EXCEL FUNC");
        let ans;
        if (this.month !== null) {
          let month;
          if (this.month === 0) month = 12;
          else month = this.month - 1;
    
          if (month < 10) month = "0" + month;
          else month = "" + month;
          if (platform === "all")
            ans = await this.rootStore.requestStore.getAdsForExcel(
              { _id: this.cityId, adm: this.cityAdm },
              "error=0&cityhall=" +
                license +
                "&workingUser=NoFilter&favAds=NoFilter&minNights=" +
                month +
                "$31"
            );
          else
            ans = await this.rootStore.requestStore.getAdsForExcel(
              { _id: this.cityId, adm: this.cityAdm },
              "error=0&cityhall=" +
                license +
                "&workingUser=NoFilter&favAds=NoFilter&minNights=" +
                month +
                "$31&platf=" +
                platform
            );
          //let ads = JSON.parse(JSON.stringify(this.totalUrlAds));
          // console.log(ans);
    
          let auxAds = [];
          if (ans && ans.data && ans.data.ads) {
            console.log(ans.data.ads);
            let ads = ans.data.ads;
    
            for (let i = 0; i < ads.length; i++) {
              // let municipality = await this.generateVal(ads[i], "municipality")
              // let county = await this.generateVal(ads[i], "county")
              // let title = await this.generateVal(ads[i], "title")
              // let commercialType = await this.generateVal(ads[i], "commercialType")
              // let transactionType = await this.generateVal(ads[i], "transactionType")
              // let price = await this.generateVal(ads[i], "price")
              // let surface = await this.generateVal(ads[i], "surface")
              //plataforma, id anunci (sense plataforma), url, llicència
              // if (municipality &&  county && title && commercialType && transactionType && price && surface) {
              let platform = "Plataforma";
              let id = "ID anunci";
              let url = "URL";
              let license = "Llicencia";
              let val = {};
    
              val[platform] = ads[i].id.slice(0, 6);
              val[id] = ads[i].id.slice(6);
              val[url] = ads[i].url;
              val[license] = ads[i].licCode;
    
              auxAds.push(val);
              //}
            }
          }
          // console.log(auxAds)
          if (auxAds.length > 0) {
            if (auxAds.length > 15000) {
              window.alert("excelTooMuch");
              //await this.rootStore.requestStore.generateExcel(userName, auxAds)
            } else {
              console.log("GOING TO GENERATE THE EXCEL");
              await this.rootStore.requestStore.generateExcel(userName, auxAds);
              // window.alert(i18n.t("polygon.searches.excelCreated"))
            }
          } else {
            //Poner el caso de que demasiados anuncios
            window.alert("noExcelCreated");
          }
        } else {
          //Poner el caso de que demasiados anuncios
          window.alert("noExcelCreated");
        }
      };
}

export default CommandStore;
