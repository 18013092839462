import React, { Component } from "react";
import { observer, inject } from "mobx-react";
//import Stats from "../RegionSelector/RegionSelector";
import NewStats from "../NewRegionSelector/NewRegionSelector";
import { withTranslation } from "react-i18next";
import MySelect from "../basicUI/MySelect";

@inject("cockpitStore", "userStore")
@observer
class Cockpit extends Component {
    async componentDidMount() {
        try {
            let { t } = this.props;
            document.title = t("command.title");
            await this.props.cockpitStore.initiateStore();
            this.status = this.props.userStore.getComplianceStatus();
        } catch (error) {
            if (error.toString() === "Error: AuthError")
                console.log("Authentication failed");
        }
    }

    async componentWillUnmount() {
        try {
            this.props.cockpitStore.cityHallData = null;
            this.props.cockpitStore.cityHallDataLast = null;
        } catch (error) {
            console.log(error.toString());
        }
    }

    overInProcess = async (e, row) => {/*
        //console.log(e);
        //console.log(e.movementX, e.movementY, e.clientX, e.clientY);
        let x = e.clientX;
        let y = e.clientY;
        //console.log(x, y);
        let style = {
            top: JSON.stringify(y) + "px",
            left: JSON.stringify(x - 120) + "px",
        };
        //console.log(style);
        let { t } = this.props;

        let textForModal = "";
        switch (row) {
            case "totalAds":
                textForModal = t("command.modalInfo.totalAds");
                break;
            case "withLicense":
                textForModal = t("command.modalInfo.withLicense");
                break;
            case "okAds":
                textForModal = t("command.modalInfo.okAds");
                break;
            case "withLicenseIncorrect":
                textForModal = t("command.modalInfo.withLicenseIncorrect");
                break;
            case "licenseDuplicated":
                textForModal = t("command.modalInfo.licenseDuplicated");
                break;
            case "usersWithLicense":
                textForModal = t("command.modalInfo.usersWithLicense");
                break;
            case "withAddressWithLicense":
                textForModal = t("command.modalInfo.withAddressWithLicense");
                break;
            case "withError":
                textForModal = t("command.modalInfo.withError");
                break;
            case "withErrorExempt":
                textForModal = t("command.modalInfo.withErrorExempt");
                break;
            case "withErrorNoLicense":
                textForModal = t("command.modalInfo.withErrorNoLicense");
                break;
            case "withErrors":
                textForModal = t("command.modalInfo.withErrors");
                break;
            case "idOwnerDuplicated":
                textForModal = t("command.modalInfo.idOwnerDuplicated");
                break;
            case "usersWithErrorLicense":
                textForModal = t("command.modalInfo.usersWithErrorLicense");
                break;
            case "WithErrorWithAddress":
                textForModal = t("command.modalInfo.withErrorWithAddress");
                break;
            case "fraud":
                textForModal = t("command.modalInfo.fraud");
                break;
            default:
                break;
        }

        document.getElementById("textModalCityHall").textContent = textForModal;
        document.getElementById("modalForCityHall").className = document
            .getElementById("modalForCityHall")
            .className.replace("display-none", "display-block");
        document.getElementById("modalForCityHall").style.left = style.left;
        document.getElementById("modalForCityHall").style.top = style.top;*/
    };

    closeModal = () => {
        //console.log("closing");
        document.getElementById("modalForCityHall").className = document
            .getElementById("modalForCityHall")
            .className.replace("display-block", "display-none");
        document.getElementById("modalForCityHall").style.top = "";
        document.getElementById("modalForCityHall").style.left = "";
    };

    onLeaves = async (e) => {
        /*let x = e.clientX;
    let y = e.clientY;
    let style = {
      top: JSON.stringify(y) + "px",
      left: JSON.stringify(x - 120) + "px",
    };*/
        this.closeModal();
    };

    render() {
        const { t } = this.props;
        console.log(
            this.props.cockpitStore.complianceData,
            this.props.cockpitStore.dateInfo
        );
        let status = ["fraude", "posibleFraude", "leve", "correcto"]
        if (this.props.userStore.user.group === "juntaandalucia")
            status = ["correcto","indicios","clandestina","rta_no_coincidente","rta_duplicado"]
        return this.props.cockpitStore.complianceData? (
            <>
                <div
                    id={"modalForCityHall"}
                    className={"modalHover display-none"}
                >
                    <section className="modal-main">
                        <div
                            id={"textModalCityHall"}
                            className="card container"
                            style={{ maxWidth: "200px" }}
                        >
                            {"A"}
                        </div>
                    </section>
                </div>
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <div className="cockpit-title">

                            <h4 className="stats-title">
                                {this.props.cockpitStore.cityName}
                            </h4>

                                <MySelect
                                    selectName="typeSelector.fromOption"
                                    selectClass="select"
                                    selectedOption={
                                    this.props.cockpitStore.typeSelector
                                        .fromOption
                                    }
                                    onChange={
                                    this.props.cockpitStore.updateComplianceData
                                    }
                                    selectOptions={
                                    this.props.cockpitStore.typeSelector
                                    .selectOptions
                                }
                                />
                                
                                
                        </div>
                        <div className="quick-search-statistics">
                            
                            <NewStats page="cockpit" regBool={false}></NewStats>
                        </div>
                        <div className="row stats-table">
                        <p style={{"text-align":"left"}}>
                                    {t("statistics.stats.date") +
                                        this.props.cockpitStore.dateInfo}
                                </p>

                        {
                            this.status.map((currentStatus, j) => {
                                console.log(currentStatus)
                                return (<>
                                <table className="table table-borderless">
                                <thead>
                                    <tr>
                                        <th className="cover">{t("cockpit." + currentStatus)}</th>

                                        <th>{t("cockpit.total")}</th>
                                        {Object.keys(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <th key={"" + currentValue + i}>
                                                {currentValue}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.total")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    currentStatus,
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total[currentStatus].total
                                            }
                                            <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    "total",
                                                    currentStatus,
                                                    "total"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        currentStatus,
                                                        "total"
                                                    );
                                                }}
                                            >
                                                {currentValue[currentStatus].total}
                                                <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    Object.keys(this.props.cockpitStore.complianceData
                                                        .website)[i],
                                                    currentStatus,
                                                    "total"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.show_text.negative")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    currentStatus,
                                                    "show_text"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total[currentStatus].show_text
                                            }
                                            <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    "total",
                                                    currentStatus,
                                                    "show_text"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        currentStatus,
                                                        "show_text"
                                                    );
                                                }}
                                            >
                                                {currentValue[currentStatus].show_text}
                                                <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    Object.keys(this.props.cockpitStore.complianceData
                                                        .website)[i],
                                                    currentStatus,
                                                    "show_text"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.registry_match.negative")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    currentStatus,
                                                    "registry_match"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total[currentStatus].registry_match
                                            }
                                            <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    "total",
                                                    currentStatus,
                                                    "registry_match"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        currentStatus,
                                                        "registry_match"
                                                    );
                                                }}
                                            >
                                                {currentValue[currentStatus].registry_match}
                                                <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    Object.keys(this.props.cockpitStore.complianceData
                                                        .website)[i],
                                                    currentStatus,
                                                    "registry_match"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.radi_municipality.negative")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    currentStatus,
                                                    "radi_municipality"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total[currentStatus].radi_municipality
                                            }
                                            <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    "total",
                                                    currentStatus,
                                                    "radi_municipality"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        currentStatus,
                                                        "radi_municipality"
                                                    );
                                                }}
                                            >
                                                {currentValue[currentStatus].radi_municipality}
                                                <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    Object.keys(this.props.cockpitStore.complianceData
                                                        .website)[i],
                                                    currentStatus,
                                                    "radi_municipality"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.pattern_match.negative")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    currentStatus,
                                                    "pattern_match"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total[currentStatus].pattern_match
                                            }
                                            <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    "total",
                                                    currentStatus,
                                                    "pattern_match"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        currentStatus,
                                                        "pattern_match"
                                                    );
                                                }}
                                            >
                                                {currentValue[currentStatus].pattern_match}
                                                <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    Object.keys(this.props.cockpitStore.complianceData
                                                        .website)[i],
                                                    currentStatus,
                                                    "pattern_match"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.duplicated.positive")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    currentStatus,
                                                    "duplicated"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total[currentStatus].duplicated
                                            }
                                            <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    "total",
                                                    currentStatus,
                                                    "duplicated"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        currentStatus,
                                                        "duplicated"
                                                    );
                                                }}
                                            >
                                                {currentValue[currentStatus].duplicated}
                                                <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    Object.keys(this.props.cockpitStore.complianceData
                                                        .website)[i],
                                                    currentStatus,
                                                    "duplicated"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.commercial_name.negative")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    currentStatus,
                                                    "commercial_name"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total[currentStatus].commercial_name
                                            }<div className="div-content">
                                            <button
                                            id="flt-message"
                                            className="btn btn-outline-info flt-message"
                                            onClick={() => {
                                                console.log("Downloading");
                                                this.props.cockpitStore.downloadExcel(
                                                this.props.userStore.user.username,
                                                "total",
                                                currentStatus,
                                                "commercial_name"
                                                );
                                            }}
                                            type="button"
                                            >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                                style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                            >
                                                <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                            </svg>
                                            </button>
                                        </div>
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        currentStatus,
                                                        "commercial_name"
                                                    );
                                                }}
                                            >
                                                {currentValue[currentStatus].commercial_name}
                                                <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    Object.keys(this.props.cockpitStore.complianceData
                                                        .website)[i],
                                                    currentStatus,
                                                    "commercial_name"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            </>
                            )})
                        }
                        {/*
                        <table className="table table-borderless">
                                <thead>
                                    <tr>
                                        <th className="cover">{t("cockpit.fraud")}</th>

                                        <th>{t("cockpit.total")}</th>
                                        {Object.keys(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <th key={"" + currentValue + i}>
                                                {currentValue}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.total")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "fraude",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.fraude.total
                                            }
                                            <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    "total",
                                                    "fraude",
                                                    "total"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "fraude",
                                                        "total"
                                                    );
                                                }}
                                            >
                                                {currentValue.fraude.total}
                                                <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    Object.keys(this.props.cockpitStore.complianceData
                                                        .website)[i],
                                                    "fraude",
                                                    "total"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.show_text.negative")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "fraude",
                                                    "show_text"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.fraude.show_text
                                            }
                                            <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    "total",
                                                    "fraude",
                                                    "show_text"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "fraude",
                                                        "show_text"
                                                    );
                                                }}
                                            >
                                                {currentValue.fraude.show_text}
                                                <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    Object.keys(this.props.cockpitStore.complianceData
                                                        .website)[i],
                                                    "fraude",
                                                    "show_text"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.registry_match.negative")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "fraude",
                                                    "registry_match"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.fraude.registry_match
                                            }
                                            <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    "total",
                                                    "fraude",
                                                    "registry_match"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "fraude",
                                                        "registry_match"
                                                    );
                                                }}
                                            >
                                                {currentValue.fraude.registry_match}
                                                <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    Object.keys(this.props.cockpitStore.complianceData
                                                        .website)[i],
                                                    "fraude",
                                                    "registry_match"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.radi_municipality.negative")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "fraude",
                                                    "radi_municipality"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.fraude.radi_municipality
                                            }
                                            <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    "total",
                                                    "fraude",
                                                    "radi_municipality"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "fraude",
                                                        "radi_municipality"
                                                    );
                                                }}
                                            >
                                                {currentValue.fraude.radi_municipality}
                                                <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    Object.keys(this.props.cockpitStore.complianceData
                                                        .website)[i],
                                                    "fraude",
                                                    "radi_municipality"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.pattern_match.negative")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "fraude",
                                                    "pattern_match"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.fraude.pattern_match
                                            }
                                            <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    "total",
                                                    "fraude",
                                                    "pattern_match"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "fraude",
                                                        "pattern_match"
                                                    );
                                                }}
                                            >
                                                {currentValue.fraude.pattern_match}
                                                <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    Object.keys(this.props.cockpitStore.complianceData
                                                        .website)[i],
                                                    "fraude",
                                                    "pattern_match"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.duplicated.positive")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "fraude",
                                                    "duplicated"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.fraude.duplicated
                                            }
                                            <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    "total",
                                                    "fraude",
                                                    "duplicated"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "fraude",
                                                        "duplicated"
                                                    );
                                                }}
                                            >
                                                {currentValue.fraude.duplicated}
                                                <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    Object.keys(this.props.cockpitStore.complianceData
                                                        .website)[i],
                                                    "fraude",
                                                    "duplicated"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.commercial_name.negative")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "fraude",
                                                    "commercial_name"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.fraude.commercial_name
                                            }<div className="div-content">
                                            <button
                                            id="flt-message"
                                            className="btn btn-outline-info flt-message"
                                            onClick={() => {
                                                console.log("Downloading");
                                                this.props.cockpitStore.downloadExcel(
                                                this.props.userStore.user.username,
                                                "total",
                                                "fraude",
                                                "commercial_name"
                                                );
                                            }}
                                            type="button"
                                            >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                                style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                            >
                                                <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                            </svg>
                                            </button>
                                        </div>
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "fraude",
                                                        "commercial_name"
                                                    );
                                                }}
                                            >
                                                {currentValue.fraude.commercial_name}
                                                <div className="div-content">
                                                <button
                                                id="flt-message"
                                                className="btn btn-outline-info flt-message"
                                                onClick={() => {
                                                    console.log("Downloading");
                                                    this.props.cockpitStore.downloadExcel(
                                                    this.props.userStore.user.username,
                                                    Object.keys(this.props.cockpitStore.complianceData
                                                        .website)[i],
                                                    "fraude",
                                                    "commercial_name"
                                                    );
                                                }}
                                                type="button"
                                                >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    style={{ fill: "#17a2b8", "minWidth": "1rem" }}
                                                >
                                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                                </svg>
                                                </button>
                                            </div>
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <table className="table table-borderless">
                                <thead>
                                    <tr>
                                        <th className="cover">{t("cockpit.possibleFraud")}</th>

                                        <th>{t("cockpit.total")}</th>
                                        {Object.keys(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <th key={"" + currentValue + i}>
                                                {currentValue}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.total")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "posfraude",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.posibleFraude.total
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "posfraude",
                                                        "total"
                                                    );
                                                }}
                                            >
                                                {currentValue.posibleFraude.total}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.show_text.negative")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "posfraude",
                                                    "show_text"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.posibleFraude.show_text
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "posfraude",
                                                        "show_text"
                                                    );
                                                }}
                                            >
                                                {currentValue.posibleFraude.show_text}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.registry_match.negative")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "posfraude",
                                                    "registry_match"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.posibleFraude.registry_match
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "posfraude",
                                                        "registry_match"
                                                    );
                                                }}
                                            >
                                                {currentValue.posibleFraude.registry_match}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.radi_municipality.negative")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "posfraude",
                                                    "radi_municipality"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.posibleFraude.radi_municipality
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "posfraude",
                                                        "radi_municipality"
                                                    );
                                                }}
                                            >
                                                {currentValue.posibleFraude.radi_municipality}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.pattern_match.negative")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "posfraude",
                                                    "pattern_match"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.posibleFraude.pattern_match
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "posfraude",
                                                        "pattern_match"
                                                    );
                                                }}
                                            >
                                                {currentValue.posibleFraude.pattern_match}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.duplicated.positive")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "posfraude",
                                                    "duplicated"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.posibleFraude.duplicated
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "posfraude",
                                                        "duplicated"
                                                    );
                                                }}
                                            >
                                                {currentValue.posibleFraude.duplicated}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.commercial_name.negative")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "posfraude",
                                                    "commercial_name"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.posibleFraude.commercial_name
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "posfraude",
                                                        "commercial_name"
                                                    );
                                                }}
                                            >
                                                {currentValue.posibleFraude.commercial_name}
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <table className="table table-borderless">
                                <thead>
                                    <tr>
                                        <th className="cover">{t("cockpit.minor")}</th>

                                        <th>{t("cockpit.total")}</th>
                                        {Object.keys(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <th key={"" + currentValue + i}>
                                                {currentValue}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.total")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "leve",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.leve.total
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "leve",
                                                        "total"
                                                    );
                                                }}
                                            >
                                                {currentValue.leve.total}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.show_text.negative")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "leve",
                                                    "show_text"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.leve.show_text
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "leve",
                                                        "show_text"
                                                    );
                                                }}
                                            >
                                                {currentValue.leve.show_text}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.registry_match.negative")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "leve",
                                                    "registry_match"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.leve.registry_match
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "leve",
                                                        "registry_match"
                                                    );
                                                }}
                                            >
                                                {currentValue.leve.registry_match}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.radi_municipality.negative")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "leve",
                                                    "radi_municipality"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.leve.radi_municipality
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "leve",
                                                        "radi_municipality"
                                                    );
                                                }}
                                            >
                                                {currentValue.leve.radi_municipality}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.pattern_match.negative")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "leve",
                                                    "pattern_match"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.leve.pattern_match
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "leve",
                                                        "pattern_match"
                                                    );
                                                }}
                                            >
                                                {currentValue.leve.pattern_match}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.duplicated.positive")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "leve",
                                                    "duplicated"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.leve.duplicated
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "leve",
                                                        "duplicated"
                                                    );
                                                }}
                                            >
                                                {currentValue.leve.duplicated}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.commercial_name.negative")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "leve",
                                                    "commercial_name"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.leve.commercial_name
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "leve",
                                                        "commercial_name"
                                                    );
                                                }}
                                            >
                                                {currentValue.leve.commercial_name}
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <table className="table table-borderless">
                                <thead>
                                    <tr>
                                        <th className="cover">{t("cockpit.correct")}</th>

                                        <th>{t("cockpit.total")}</th>
                                        {Object.keys(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <th key={"" + currentValue + i}>
                                                {currentValue}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.total")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "correct",
                                                    "total"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.correcto.total
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "correct",
                                                        "total"
                                                    );
                                                }}
                                            >
                                                {currentValue.correcto.total}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.show_text.positive")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "correct",
                                                    "show_text"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.correcto.show_text
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "correct",
                                                        "show_text"
                                                    );
                                                }}
                                            >
                                                {currentValue.correcto.show_text}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.registry_match.positive")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "correct",
                                                    "registry_match"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.correcto.registry_match
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "correct",
                                                        "registry_match"
                                                    );
                                                }}
                                            >
                                                {currentValue.correcto.registry_match}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.radi_municipality.positive")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "correct",
                                                    "radi_municipality"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.correcto.radi_municipality
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "correct",
                                                        "radi_municipality"
                                                    );
                                                }}
                                            >
                                                {currentValue.correcto.radi_municipality}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.pattern_match.positive")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "correct",
                                                    "pattern_match"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.correcto.pattern_match
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "correct",
                                                        "pattern_match"
                                                    );
                                                }}
                                            >
                                                {currentValue.correcto.pattern_match}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.duplicated.positive")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "correct",
                                                    "duplicated"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.correcto.duplicated
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "correct",
                                                        "duplicated"
                                                    );
                                                }}
                                            >
                                                {currentValue.correcto.duplicated}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                       
                                        <td
                                            style={{ width: "12rem" }}
                                            viewBox="0 0 512 512"
                                            onMouseEnter={(e) => {
                                                this.overInProcess(
                                                    e,
                                                    "totalAds"
                                                );
                                            }}
                                            onMouseLeave={(e) => {
                                                this.onLeaves(e);
                                            }}
                                        >
                                            {t("cockpit.commercial_name.positive")}
                                        </td>
                                        <td
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                    "total",
                                                    "correct",
                                                    "commercial_name"
                                                );
                                            }}
                                        >
                                            {
                                                this.props.cockpitStore
                                                    .complianceData.total.correcto.commercial_name
                                            }
                                        </td>
                                        {Object.values(
                                            this.props.cockpitStore.complianceData
                                                .website
                                        ).map((currentValue, i) => (
                                            <td
                                                key={"" + currentValue + i}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                if (
                                                    e.currentTarget ===
                                                    e.target
                                                )
                                                this.props.cockpitStore.tableToWA(
                                                        Object.keys(this.props.cockpitStore.complianceData
                                                .website)[i],
                                                        "correct",
                                                        "commercial_name"
                                                    );
                                                }}
                                            >
                                                {currentValue.correcto.commercial_name}
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                            */}
                        </div>
                        
                        
                    </div>
                </div>
                <div className="col-md-3"></div>
            </>
        ) : (
            <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                    <div className="row stats-table">
                        <h4 className="stats-title">
                            {this.props.cockpitStore.cityName}
                        </h4>
                        {/*<div className="quick-search-statistics">
              <Stats cityhall="true"></Stats>
    </div>*/}
                    </div>
                </div>
                <div className="col-md-3"></div>
            </div>
        );
    }
}

export default withTranslation()(Cockpit);
