import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";

@inject("trainingStore")

@observer
class Training extends Component {

    async componentDidMount() {
        try {
            let { t } = this.props;
            document.title = t("training.title");
        } catch (error) {
            if (error.toString() === "Error: AuthError")
                console.log("Authentication failed");
        }
    }

    render() {
        const { t } = this.props
        return (<main>
            <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <h4>{t("training.header")}</h4>
                    <hr></hr>
                    <div>
                        <p>
                            Fa uns dies vam posar en marxa el projecte de l’eina de rastreig de pisos turístics,
                            <a href="https://stlpro.talkandcode.com/"> Talk and Code</a>.
                            <span> Com us vam comentar, la formació contínua és una part essencial del procés. </span>
                        </p>
                        <p>
                            Per aquest motiu, hem optat per un format de píndoles formatives curtes
                            (Loom), d’una durada aproximada de 5 minuts. Cada setmana us n’enviarem
                            una o dues per explicar-vos, acceptem propostes del vostres interès:
                        </p>
                        <p style={{marginBottom: "0.2rem"}}>
                            <span> &#10003; </span> Funcionalitats de l’eina
                        </p>
                        <p style={{marginBottom: "0.2rem"}}>
                            <span> &#10003; </span> Metodologies de treball
                        </p>
                        <p style={{marginBottom: "0.2rem"}}>
                            <span> &#10003; </span> Accions per optimitzar la vostra tasca dins de l’estació
                        </p>
                        <p>
                            <span> &#10003; </span> Aspectes relacionats amb normativa, procediments, incoació, registres i tramitació en el dia a dia
                        </p>
                        <div id="pindoles">
                            <p>
                                A continuació, us compartim les píndoles formatives (tingueu en compte que no hi ha temps per a la comiat):
                            </p>
                            <p style={{marginBottom: "0.2rem"}}>
                                <i className="fa fa-link"></i>
                                <span> Primera: <a href="https://www.loom.com/share/6a45622f0fb1425dab19df02faf317ac?sid=57789ac5-0bdd-4bc1-a6e1-588f21e76754"> Veure el vídeo </a></span>
                            </p>
                            <p style={{marginBottom: "0.2rem"}}>
                                <i className="fa fa-link"></i>
                                <span> Segona: <a href="https://www.loom.com/share/002ad038fab34943a04a25a7393275e0?sid=17aa853f-1c47-4ac2-b7c7-077b50ad12dc"> Veure el vídeo </a></span>
                            </p>
                            <p style={{marginBottom: "0.2rem"}}>
                                <i className="fa fa-link"></i>
                                <span> Tercera: <a href="https://www.loom.com/share/f4dae5b112244111afb7156895bb5661"> Veure el vídeo </a></span>
                            </p>
                            <p style={{marginBottom: "0.2rem"}}>
                                <i className="fa fa-link"></i>
                                <span> Quarta: <a href="https://www.loom.com/share/ce5a6a32f85d476c962eb14d5071a0e9?sid=8bec56e8-e64a-4271-b35d-7b1f1206b8b6"> Veure el vídeo </a>
                                    (és en castellà i s’ha fet per participar en el </span> <span> <a href="https://www.catedraclimentguitart.cat/es/foroturistico2025/#programa"> FORUM TURÍSTIC DE DESTINS DE SOL I PLATJA A LLORET DE MAR</a> )
                                </span>
                            </p>
                            <p style={{marginBottom: "0.2rem"}}>
                                <i className="fa fa-link"></i>
                                <span> Cinquena: <a href="https://www.loom.com/share/8dfbfdb87a3343b69f4002b91b350e06?sid=bc3bc434-3c6f-4925-a662-ea0829095a00"> Veure el vídeo </a></span>
                            </p>
                        </div>
                        <hr/>
                        <div id="contactes">
                            <p style={{marginBottom: "0.4rem", fontWeight: "bolder"}}>
                                <i className="fa fa-envelope-square"></i>
                                Contactes per a dubtes o consultes:
                            </p>
                            <p style={{marginBottom: "0.3rem", fontWeight: "bold"}}> &#9674; Per a consultes sobre l'estació de treball, cerca i rastreig: </p>
                            <p style={{marginBottom: "0.3rem"}}> 
                                <i className="fa fa-envelope-o"></i> 
                                Talk and Code <a href="mailto:help@talkandcode.com"> help@talkandcode.com </a>
                            </p>
                            <p style={{marginBottom: "0.3rem", fontWeight: "bold"}}> &#9674; Per a altres noves o dubtes generals: </p>
                            <p style={{marginBottom: "0.3rem"}}>
                                <i className="fa fa-envelope-o"></i>
                                Contacte Generalitat <a href="mailto:mpalacios@gencat.cat"> mpalacios@gencat.cat </a>
                            </p>
                            <p style={{marginBottom: "0.3rem", fontWeight: "bold"}}> &#9674; Per a consultes sobre el treball diari i coordinació amb els Serveis Territorials: </p>
                            <p style={{marginBottom: "0.2rem"}}> 
                                &bull; Barcelona i Catalunya Central → 
                                <i className="fa fa-envelope-o"></i>
                                <a href="mailto:seccioinspeccioturisme.emc@gencat.cat"> seccioinspeccioturisme.emc@gencat.cat </a>
                                (Miquel)
                            </p>
                            <p style={{marginBottom: "0.2rem"}}> &bull; Girona → 
                                <i className="fa fa-envelope-o"></i>
                                <a href="mailto:sstt.emo.generalitatgirona@gencat.cat"> sstt.emo.generalitatgirona@gencat.cat </a>
                                (Roser)
                            </p>
                            <p style={{marginBottom: "0.2rem"}}> &bull; Tarragona → 
                                <i className="fa fa-envelope-o"></i>
                                <a href="mailto:bustiaturismetarragona@gencat.cat"> bustiaturismetarragona@gencat.cat </a> 
                                (Lorena) 
                            </p>
                            <p style={{marginBottom: "0.2rem"}}> &bull; Terres de l'Ebre → 
                                <i className="fa fa-envelope-o"></i>
                                <a href="mailto:bustiaturisme.terresdelebre@gencat.cat"> bustiaturisme.terresdelebre@gencat.cat </a> 
                                (Ana)
                            </p>
                            <p> &bull; Lleida → 
                                <i className="fa fa-envelope-o"></i>
                                <a href="mailto:bustiaturismelleida@gencat.cat"> bustiaturismelleida@gencat.cat </a>
                                (Adela)
                            </p>
                        </div>
                        <div id="compartir">
                            <p style={{fontWeight: "bold"}}> <i className="fa fa-bullhorn"></i> Compartiu i participeu! </p>
                            <p>
                                Si voleu compartir aquesta bona pràctica amb altres municipis, estarem
                                encantats que ho feu, ja que som conscients que no es va poder comunicar
                                a tothom.
                            </p>
                            <p> A més, si algú de vosaltres s’anima a crear un Loom, serà molt benvingut! </p>
                            <p> Moltes gràcies! </p>
                            <p> &#9883; <a href="https://stlpro.talkandcode.com/"> stlpro.talkandcode.com </a> </p>
                            <p> Web site created using create-react-app </p>
                        </div>
                    </div>
                </div>
            </div>
        </main>)
    }
}
export default withTranslation()(Training);