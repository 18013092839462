import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import MyButton from "../basicUI/MyButton";
import { withTranslation } from "react-i18next";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
const { BroadcastChannel } = require("broadcast-channel");
const channelAbbrv = new BroadcastChannel("abbrv");

@inject(
  "mapListStore",
  "navigationStore",
  "sessionStore",
  "userStore",
  "requestStore",
  "groupAdsStore",
  "processStore"
)
@observer
class GroupCard extends Component {
  async componentDidMount() {
    channelAbbrv.addEventListener("message", async (event) => {
      if (event.id === this.props.groupId) {
        let button = document.getElementById("status_id" + this.props.groupId);
        if (button) button.innerHTML = event.statesGroup.abbreviation;
        this.props.groupAdsStore.statesGroup = event.statesGroup;
        this.props.groupAdsStore.indexSituation = event.index;
        if (event.statesGroup.message && event.statesGroup.message.notes)
          this.props.groupAdsStore.notesForm = event.statesGroup.message.notes;
        else this.props.groupAdsStore.notesForm = "";
        let elem2 = document.getElementById("inputGroupSelect01");
        if (elem2) {
          for (let j = 0; j < elem2.length; j++) {
            var option = elem2.options[j];
            if (this.props.groupAdsStore.statesGroup.value === null) {
              if (option.value === "choose") option.selected = true;
              else option.selected = false;
            } else if (this.props.groupAdsStore.statesGroup.value) {
              if (option.value === this.props.groupAdsStore.statesGroup.value)
                option.selected = true;
              else option.selected = false;
            }
          }
        }
      }
    });
  }

  async componentWillUnmount() {
    await this.props.processStore.close(false);
    await this.props.groupAdsStore.close(false);
    await this.props.groupAdsStore.closeModal();
  }

  processFinished = (processList) => {
    let finished = true;
    processList.forEach((value) => {
      finished = finished && value.finished;
    });
    return finished;
  };

  processInProcess = (workingUser) => {
    let inP = false;
    if (
      workingUser !== null &&
      workingUser !== undefined &&
      workingUser !== "-"
    )
      inP = true;
    return inP;
  };

  clickedInProcess = async (idGA) => {
    // let res = await this.props.groupAdsStore.getProcesses(idGA);
    // if (!!res) {
    //   await this.props.processStore.openGAProcesses(res.process, idGA);
    // }
    //await this.props.groupAdsStore.close(true);
    // element.removeEventListener("click", e => {
    //   console.log("REMOVED")
    // })
    //document.removeEventListener("mousemove", e => {
    //this.props.mapListStore.mousePosition(e);
    let user = this.props.userStore.getUser();
    let res = await this.props.groupAdsStore.getState(idGA);
    let res2 = await this.props.groupAdsStore.getAllStates(user.group);
    if (!!res && !!res2) {
      await this.props.groupAdsStore.openGAStates(
        res.state,
        res.lastModification,
        res.notes,
        user,
        res2.adStates,
        idGA
      );
    }
  };

  overInProcess = async (e, idGA) => {
    //let res = await this.props.groupAdsStore.getProcesses(idGA);
    // setTimeout(() => {
    // 	this.props.processStore.openGATasks(res.process,idGA)
    // }, 500)
    // if (!!res) {
    //   await this.props.processStore.openGATasks(res.process, idGA);
    // }
    let x = e.clientX;
    let y = e.clientY;
    let workingUser = this.props.workingUser;
    let res = await this.props.groupAdsStore.getState(idGA);
    // setTimeout(() => {
    //   	this.props.groupAdsStore.openGATasks(res.state, res.lastModification, res.notes, idGA)
    //   }, 500)
    if (!!res) {
      await this.props.groupAdsStore.openGATasks(
        res.state,
        res.lastModification,
        res.notes,
        idGA,
        x,
        y,
        workingUser
      );
    }
  };

  onEnters = async (idGA) => {
    let res = await this.props.groupAdsStore.getState(idGA);
    if (!!res) {
      await this.props.groupAdsStore.openGATasks(
        res.state,
        res.lastModification,
        res.notes,
        idGA
      );
    } /*
    <span style={{color:"green"}}>&#x2713;</span>
    */
  };

  onLeaves = async (idGA) => {
    // setTimeout(() => {
    //   this.props.processStore.close(false);
    // }, 200);
    // await this.props.processStore.close(false);
    setTimeout(() => {
      this.props.groupAdsStore.closeModal();
    }, 250);
    await this.props.groupAdsStore.closeModal();
  };

  render() {
    // this.updateUser();
    const { t } = this.props;
    if (this.props.sessionStore.initialized) {
      return (
        <div
          className="group-info-status"
          style={{ width: "30%", marginLeft: "5%" }}
        >
          {this.props.fiProc ? (
            <p className="finished">{t("wa.card.finished")}</p>
          ) : this.processInProcess(this.props.workingUser) &&
            this.props.workingUser === this.props.mapListStore.user.username ? (
            <div className="group-info-child">
              <b>
                <OverlayTrigger
                  delay={{ show: 500, hide: 100 }}
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip">{t("wa.card.Assigned")}</Tooltip>
                  }
                >
                  <svg
                    className="fas_fa-user"
                    style={{
                      width: "1rem",
                      "marginRight": "1rem",
                      fill: "rgb(169, 169, 169)",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 55.93 63.85"
                  >
                    <path
                      className="cls-1"
                      d="M29.36,12.17A23,23,0,0,1,32,10.25a18.86,18.86,0,0,1,4-1.62,18.44,18.44,0,0,1,5.83-.41l2.2.42A17.63,17.63,0,0,1,47.42,10l.53.29a19.54,19.54,0,0,1,2.69,1.91L52,13.56a17.4,17.4,0,0,1,1.87,2.55,18.39,18.39,0,0,1,1.64,4l.43,2.33L56,24.12l-.07,1.31a.08.08,0,0,0,0,.07v.29l-.43,2.34a1.48,1.48,0,0,0-.19.57A20,20,0,0,1,53.93,32a22.68,22.68,0,0,1-2,2.73l-1.39,1.37A19.44,19.44,0,0,1,48,38l-1.36.68a20.84,20.84,0,0,1-2.58.93L41.75,40l-.44,0a.08.08,0,0,0-.07,0L40,40.09,38.54,40c-.86-.11-1.71-.26-2.55-.44A18.53,18.53,0,0,1,32.05,38a18.68,18.68,0,0,1-2.71-2l-1.25-1.23a21.33,21.33,0,0,1-2-2.8,19.14,19.14,0,0,1-1.57-3.91l-.42-2.27L24,24.12l.08-1.68.42-2.33a19.91,19.91,0,0,1,1.64-4A21.14,21.14,0,0,1,28,13.5Z"
                      transform="translate(-12.04 -8.15)"
                    />
                    <path
                      className="cls-1"
                      d="M68,64c0,1,0,1.91-.08,2.87a6,6,0,0,1-5.49,5.07c-3.14.11-6.29,0-9.44,0H19.39a11.54,11.54,0,0,1-3.21-.29L16,71.62a5.87,5.87,0,0,1-3.9-4.91c-.13-.9,0-1.81-.07-2.72V60a2.15,2.15,0,0,0,.07-.67c.08-.6.14-1.2.26-1.79A10.44,10.44,0,0,0,12.7,56c.05-.13.13-.26.17-.4a9.8,9.8,0,0,1,.77-1.94l.83-1.6,1.59-2.12c.6-.65,1.21-1.28,1.86-1.88L20,46.51a18.45,18.45,0,0,1,4.07-1.79,23.78,23.78,0,0,1,3.22-.63l.73,0c.25,0,.49,0,.73,0a15.11,15.11,0,0,1,2.33,0,3.54,3.54,0,0,0,.88.36,17.41,17.41,0,0,0,4,1.17A17.52,17.52,0,0,0,40,46c.56,0,1.11,0,1.66-.06A11.11,11.11,0,0,0,44,45.63,15.87,15.87,0,0,0,48,44.5,7.15,7.15,0,0,0,49,44.07H52l.87,0c.17.13.37.07.56.09a25.18,25.18,0,0,1,2.58.55,17.64,17.64,0,0,1,4,1.8c.73.46,1.41,1,2.09,1.5s1.26,1.23,1.86,1.87S65,51.31,65.49,52a20.92,20.92,0,0,1,1.8,4c.23,1,.45,2,.59,3A4.22,4.22,0,0,0,68,60Z"
                      transform="translate(-12.04 -8.15)"
                    />
                  </svg>
                </OverlayTrigger>
              <img 
                  className={
                    "stp-platform-logo"
                    }
                  src={(!!this.props.stp
                      ? "/logos/stpBlack.png"
                      : "/logos/stpGrey.png")}
                  alt={
                    "Save The Proof"
                  }
                />
              </b>

              <MyButton
                id={"status_id" + this.props.groupId}
                type="button"
                onMouseOver={(e) => {
                  this.overInProcess(e, this.props.groupId);
                }}
                onMouseLeave={(e) => {
                  this.onLeaves(this.props.groupId);
                }}
                disabled={
                  this.props.workingUser ===
                  this.props.mapListStore.user.username
                    ? "false"
                    : "true"
                }
                text={
                  this.props.adState && this.props.adState.abbreviation
                    ? this.props.adState.abbreviation
                    : "NT"
                }
                className="in-process"
                onClick={(e) => {
                  this.clickedInProcess(this.props.groupId);
                }}
              ></MyButton>
            </div>
          ) : this.processInProcess(this.props.workingUser) ? (
            <div className="group-info-child">
              <b>
                <OverlayTrigger
                  delay={{ show: 500, hide: 100 }}
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip">{t("wa.card.Assigned")}</Tooltip>
                  }
                >
                  <svg
                    className="fas_fa-user"
                    style={{
                      width: "1rem",
                      "marginRight": "1rem",
                      fill: "rgb(169, 169, 169)",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 55.93 63.85"
                  >
                    <path
                      className="cls-1"
                      d="M29.36,12.17A23,23,0,0,1,32,10.25a18.86,18.86,0,0,1,4-1.62,18.44,18.44,0,0,1,5.83-.41l2.2.42A17.63,17.63,0,0,1,47.42,10l.53.29a19.54,19.54,0,0,1,2.69,1.91L52,13.56a17.4,17.4,0,0,1,1.87,2.55,18.39,18.39,0,0,1,1.64,4l.43,2.33L56,24.12l-.07,1.31a.08.08,0,0,0,0,.07v.29l-.43,2.34a1.48,1.48,0,0,0-.19.57A20,20,0,0,1,53.93,32a22.68,22.68,0,0,1-2,2.73l-1.39,1.37A19.44,19.44,0,0,1,48,38l-1.36.68a20.84,20.84,0,0,1-2.58.93L41.75,40l-.44,0a.08.08,0,0,0-.07,0L40,40.09,38.54,40c-.86-.11-1.71-.26-2.55-.44A18.53,18.53,0,0,1,32.05,38a18.68,18.68,0,0,1-2.71-2l-1.25-1.23a21.33,21.33,0,0,1-2-2.8,19.14,19.14,0,0,1-1.57-3.91l-.42-2.27L24,24.12l.08-1.68.42-2.33a19.91,19.91,0,0,1,1.64-4A21.14,21.14,0,0,1,28,13.5Z"
                      transform="translate(-12.04 -8.15)"
                    />
                    <path
                      className="cls-1"
                      d="M68,64c0,1,0,1.91-.08,2.87a6,6,0,0,1-5.49,5.07c-3.14.11-6.29,0-9.44,0H19.39a11.54,11.54,0,0,1-3.21-.29L16,71.62a5.87,5.87,0,0,1-3.9-4.91c-.13-.9,0-1.81-.07-2.72V60a2.15,2.15,0,0,0,.07-.67c.08-.6.14-1.2.26-1.79A10.44,10.44,0,0,0,12.7,56c.05-.13.13-.26.17-.4a9.8,9.8,0,0,1,.77-1.94l.83-1.6,1.59-2.12c.6-.65,1.21-1.28,1.86-1.88L20,46.51a18.45,18.45,0,0,1,4.07-1.79,23.78,23.78,0,0,1,3.22-.63l.73,0c.25,0,.49,0,.73,0a15.11,15.11,0,0,1,2.33,0,3.54,3.54,0,0,0,.88.36,17.41,17.41,0,0,0,4,1.17A17.52,17.52,0,0,0,40,46c.56,0,1.11,0,1.66-.06A11.11,11.11,0,0,0,44,45.63,15.87,15.87,0,0,0,48,44.5,7.15,7.15,0,0,0,49,44.07H52l.87,0c.17.13.37.07.56.09a25.18,25.18,0,0,1,2.58.55,17.64,17.64,0,0,1,4,1.8c.73.46,1.41,1,2.09,1.5s1.26,1.23,1.86,1.87S65,51.31,65.49,52a20.92,20.92,0,0,1,1.8,4c.23,1,.45,2,.59,3A4.22,4.22,0,0,0,68,60Z"
                      transform="translate(-12.04 -8.15)"
                    />
                  </svg>
                </OverlayTrigger>
              <img 
                  className={
                    "stp-platform-logo"
                    }
                  src={(!!this.props.adState
                      ? "/logos/stpBlack.png"
                      : "/logos/stpGrey.png")}
                  alt={
                    "Save The Proof"
                  }
                />
              </b>
              <button
                id={"status_id" + this.props.groupId}
                type="button"
                onMouseOver={(e) => {
                  this.overInProcess(e, this.props.groupId);
                }}
                onMouseLeave={(e) => {
                  this.onLeaves(this.props.groupId);
                }}
                className="in-process"
              >
                {this.props.adState && this.props.adState.abbreviation
                  ? this.props.adState.abbreviation
                  : "NT"}
              </button>
            </div>
          ) : (
            <div className="group-info-child">
              {/* <p className="not-assigned">{t("wa.card.noAssigned")}</p> */}
              <b>
                <OverlayTrigger
                  delay={{ show: 500, hide: 100 }}
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip">{t("wa.card.noAssigned")}</Tooltip>
                  }
                >
                  <svg
                    className="fas fa-user-slash"
                    style={{
                      height: "1rem",
                      "marginRight": "1rem",
                      fill: "rgb(169, 169, 169)",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                  >
                    <path
                      d="M633.8 458.1L362.3 248.3C412.1 230.7 448 183.8 448 128 448 57.3 390.7 0 320 0c-67.1 0-121.5 51.8-126.9 117.4L45.5 3.4C38.5-2 28.5-.8 23 6.2L3.4 31.4c-5.4 7-4.2 17 2.8 22.4l588.4 454.7c7 5.4 17 4.2 22.5-2.8l19.6-25.3c5.4-6.8 4.1-16.9-2.9-22.3zM96 422.4V464c0 26.5 21.5 48 48 48h350.2L207.4 290.3C144.2 301.3 96 356 96 422.4z"
                    ></path>
                  </svg>
                </OverlayTrigger>
                <img 
                  className={
                    "stp-platform-logo"
                    }
                  src={(!!this.props.adState
                      ? "/logos/stpBlack.png"
                      : "/logos/stpGrey.png")}
                  alt={
                    "Save The Proof"
                  }
                />
              </b>
              <button
                id={"status_id" + this.props.groupId}
                type="button"
                onMouseOver={(e) => {
                  this.overInProcess(e, this.props.groupId);
                }}
                onMouseLeave={(e) => {
                  this.onLeaves(this.props.groupId);
                }}
                className="in-process"
              >
                {this.props.adState && this.props.adState.abbreviation
                  ? this.props.adState.abbreviation
                  : "NT"}
              </button>
            </div>
          )}
        </div>
      );
    }
  }
}

/*
      <MyButton type="button" onMouseOver={e => {this.overInProcess(ga._id);}} onMouseOut={e => {this.onLeaves(ga._id);}} disabled={ga.workingUser === this.props.mapListStore.user.username ? "false" : "true"} text="In Process" className="in-process" onClick = {() => {this.clickedInProcess(ga._id)}}></MyButton>) 
  

*/

export default withTranslation()(GroupCard);
