import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import MyButton from "../basicUI/MyButton";
@inject("otaStore")
@observer
class Ota extends Component {

	async componentDidMount() {
        try {
            let { t } = this.props;
            document.title = t("ota.title");
        } catch (error) {
            if (error.toString() === "Error: AuthError")
                console.log("Authentication failed");
        }
    }


	render() {
		const { t } = this.props
		return (<main>
			<div className="row">

				<div className="col-md-1">

				</div>
				<div className="col-md-10 ota-md" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
					<h4>{t("ota.header")}</h4>
					<hr></hr>
					<details>
						<summary>
							Airbnb
						</summary>
						<div style={{ marginLeft: "2rem" }}>
							{/*<details>
								<summary>
									{t("ota.contact)}
								</summary>
								<hr />
								<div>
									<p>
										Relacions públiques <a href="mailto:irabasa@expediagroup.com">irabasa@expediagroup.com</a>
									</p>
								</div>

							</details>*/}
							<details>
								<summary>
									{t("ota.comunication")}
								</summary>
								<div style={{ marginLeft: "2rem" }}>
									<p>{t("ota.webportal")} <a href="https://airbnb-legal.my.site.com/s/login/?language=es" target="_blank" rel="noreferrer noopener">https://airbnb-legal.my.site.com/s/login/?language=es</a></p>
									<p>{t("ota.mailportal")} <a href="mailto:terms@airbnb.com">terms@airbnb.com</a> <a href="mailto: dsa-authorities@airbnb.com"> dsa-authorities@airbnb.com</a></p>
								</div>
							</details>
						</div>
					</details>
					<hr/><details>
						<summary>
							Booking
						</summary>
						<div style={{ marginLeft: "2rem" }}>
							<details>
								<summary>
									{t("ota.contact")}
								</summary>
								<div style={{ marginLeft: "2rem" }}>
									<p>
										<a href="mailto:ana.abade@booking.com">ana.abade@booking.com</a>
									</p>
									<p>
										Mireia Prieto, Regional Director South EMEA. <a href="mailto:mireia.prieto@booking.com">mireia.prieto@booking.com</a>
									</p>
									<p>
										Alexis Darmois, Senior Manager public affaris. <a href="mailto:alexis.darmois@booking.com">alexis.darmois@booking.com</a>
									</p>
									<p>
										Pilar Crespo, Regional Manager Spain&Portugal. <a href="mailto:pilar.crespo@booking.com">pilar.crespo@booking.com</a>
									</p>
									<p>
										Susana Tarazaga, Area Manager Catalunya. <a href="mailto:susana.tarazaga@booking.com">susana.tarazaga@booking.com</a>
									</p>
									<p>
										Resposable anuncis europa <a href="mailto:tatiana.usheva@booking.com">tatiana.usheva@booking.com </a>

									</p>
								</div>

							</details>
							<details>
								<summary>
									{t("ota.comunication")}
								</summary>
								<div style={{ marginLeft: "2rem" }}>
									<p>{t("ota.webportal")} <a href="https://booking.service-now.com/authorityportal.booking.com" target="_blank" rel="noreferrer noopener">authorityportal.booking.com</a></p>
								</div>
							</details>
						</div>
					</details>
					<hr/><details>
						<summary>
							Idealista
						</summary>
						<div style={{ marginLeft: "2rem" }}>
							<details>
								<summary>
									{t("ota.contact")}
								</summary>
								<div style={{ marginLeft: "2rem" }}>
									<p>
									Equipo legal Andrea Viale <a href="mailto:aviale@idealista.com">aviale@idealista.com</a>
									</p>
								</div>

							</details>
							<details>
								<summary>
									{t("ota.comunication")}
								</summary>
								<div style={{ marginLeft: "2rem" }}>
									<p>{t("ota.mailportal")} <a href="mailto:info@idealista.com">info@idealista.com</a></p>
								</div>
							</details>
						</div>
					</details>
					<hr/><details>
						<summary>
							Rentalia
						</summary>
						<div style={{ marginLeft: "2rem" }}>
							<details>
								<summary>
									{t("ota.contact")}
								</summary>
								<div style={{ marginLeft: "2rem" }}>
									<p>
									Almudena Ucha <a href="mailto:almudena@rentalia.com">almudena@rentalia.com</a>;  <a href="mailto:aucha@idealista.com">aucha@idealista.com</a>
									</p>
								</div>

							</details>
							<details>
								<summary>
									{t("ota.comunication")}
								</summary>
								<div style={{ marginLeft: "2rem" }}>
								<p>{t("ota.mailportal")} <a href="mailto:info@rentalia.com?cc=almudena@rentalia.com">info@rentalia.com con copia a almudena@rentalia.com</a></p>
								</div>
							</details>
						</div>
					</details>
					<hr/><details>
						<summary>
							Vrbo Expedia group
						</summary>
						<div style={{ marginLeft: "2rem" }}>
							<details>
								<summary>
									{t("ota.contact")}
								</summary>
								<div style={{ marginLeft: "2rem" }}>
									<p>
										Relacions públiques <a href="mailto:irabasa@expediagroup.com">irabasa@expediagroup.com</a>
									</p>
								</div>

							</details>
							<details>
								<summary>
									{t("ota.comunication")}
								</summary>
								<div style={{ marginLeft: "2rem" }}>
									<p>{t("ota.mailportal")} <a href="mailto:legal@vrbo.com?cc=irabasa@expediagroup.com">legal@vrbo.com con copia a irabasa@expediagroup.com</a></p>
								</div>
							</details>
						</div>
					</details>
					<hr></hr>
					<MyButton
						id="ota-excel"
                            className="btn btn-outline-info flt-message"
                            onClick={() => {
                              console.log("Downloading");
                              this.props.otaStore.downloadExcel(
                                
                              );
                            }}
							text={t("ota.excel")}/>

				</div>
				<div className="col-md-1">

				</div>
			</div>
		</main>)
	}
}
export default withTranslation()(Ota);