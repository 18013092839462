import { observable, action, computed } from "mobx";

class AdGroupEditStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @observable users;
    @observable usersInitial;
    @observable regions;
    @observable myAssigned = null;
    @observable myAssignedInitial = null;
    @observable adgroupsInitial = null;
    @observable selectedUser = null;
    @observable initialized = false;
    @observable loading = false;
    @observable showAlert = false;
    @observable confirmAlert = false;
    @observable adGroupSearch = "";
    @observable assignedAdGroupSearch = "";
    @observable workingUserSearch = "";
    @observable order = 1
    @observable numAdsBD = 0;
    @observable activeSort = "adGroupCode"

    @action("Initialize Store") initializeStore = async () => {
        try {
            let users
            if (this.rootStore.userStore.user.isSuperuser) {
                users = (
                    await this.rootStore.requestStore.getAllUsers({
                        projection: "e$d$f$i",
                        limit: "9999999999",
                        sort: "d",
                    })
                ).data;
            } else {
                users = (
                    await this.rootStore.requestStore.getAllUsersExceptSuperusers({
                        projection: "e$d$f$i",
                        limit: "9999999999",
                        sort: "d",
                    })
                ).data;
            }
            this.adGroupSearch = ""
            this.assignedAdGroupSearch = ""
            this.workingUserSearch = ""
            let countState = await this.rootStore.requestStore.getUserStats()
            this.users = users.map(item => {
                let data = countState.find(item2 => item2._id === item.username)
                for (let element of ["count", "noNIRTC", "procreport", "checked", "inprocess", "tocheck", "notlocated", "otherEstab", "migrated", "inProcess"]) {
                    item[element] = "0"
                    if (data && data[element]) item[element] = data[element]
                }
                return item
            })
            this.usersInitial = JSON.parse(JSON.stringify(this.users))
            this.numAdsBD = await this.rootStore.requestStore.countAdsBD()
            this.initialized = true;
        } catch (error) {
            if (error.toString() === "Error: AuthError")
                console.log("Authentication failed");
        }
    };

    @action("update property not in form") updateProperty(key, value) {
        this[key] = value;
    }

    @action("Select user and change adgroups") changeUserSelected = async (user) => {
        try {
            this.loading = true
            this.adGroupSearch = ""
            this.assignedAdGroupSearch = ""
            this.myAssigned = null
            this.adgroupsInitial = []
            let toSelect = user._id;
            this.selectedUser = (
                await this.rootStore.requestStore.getUserById(toSelect, {
                    projection: "e$d$f$i",
                    limit: "9999999999",
                })
            ).data;
            let myAssigned = await this.rootStore.requestStore.getAssignedList(this.selectedUser.username)
            this.myAssigned = myAssigned.slice().sort((a, b) => {
                if (a._id < b._id) return -1;
                else return 1
            })
            this.myAssignedInitial = JSON.parse(JSON.stringify(this.myAssigned));
            if (this.selectedUser.assignedRegions.length > 0) {
                let res
                res = await this.rootStore.requestStore.getAdGroupsToAssign(this.selectedUser.assignedRegions, 0, 5000);
                this.adgroupsInitial = this.adgroupsInitial.concat(res)
                let existingIds = new Set(this.adgroupsInitial.map(item => item._id));
                let start = 0
                this.loading = false
                while (start < this.numAdsBD) {
                    start += 5000
                    res = await this.rootStore.requestStore.getAdGroupsToAssign(this.selectedUser.assignedRegions, start, 5000);
                    let filteredRes = res.filter(item => !existingIds.has(item._id));
                    this.adgroupsInitial = this.adgroupsInitial.concat(filteredRes)
                    filteredRes.forEach(element => {
                        existingIds.add(element._id)
                    });
                    

                }
            }


            this.loading = false
        } catch (error) {
            if (error.toString() === "Error: AuthError")
                console.log("Authentication failed");
        }
    };

    @action("Select Adgroup") selectAdGroupToMine = async (id) => {
        try {
            let res = await this.rootStore.requestStore.changeWorkingNew(
                id,
                this.selectedUser.username,
                "AdGroupEdit",
                this.selectedUser.username
            );
            let index = this.myAssigned.findIndex(item => item._id > id);
            let adgroup = this.adgroupsInitial.find(item => item._id === id)
            if (index === -1) {
                this.myAssigned.push(adgroup);
            } else {
                this.myAssigned.splice(index, 0, adgroup);
            }
            index = this.myAssignedInitial.findIndex(item => item._id > id);
            if (index === -1) {
                this.myAssignedInitial.push(adgroup);
            } else {
                this.myAssignedInitial.splice(index, 0, adgroup);
            }
            if (res === undefined) {
                this.updateProperty("showAlert", true);
                document.getElementById("danger-alert-workingRegion").innerHTML =
                    "El grup d'anuncis no s'ha pogut afegir. Siusplau, intenti-ho més tard.";
                setTimeout(() => {
                    this.updateProperty("showAlert", false);
                }, 5000);
            } else {
                this.updateProperty("confirmAlert", true);
                document.getElementById("succes-alert-workingRegion").innerHTML =
                    "El grup d'anuncis s'ha afegit correctament.";
                setTimeout(() => {
                    this.updateProperty("confirmAlert", false);
                }, 5000);
            }
        } catch (error) {
            if (error.toString() === "Error: AuthError")
                console.log("Authentication failed");
        }
    };

    @action("Deselect Region") deselectGroup = async (target) => {
        try {
            this.myAssigned = this.myAssigned.filter((item) => item._id !== target.id)
            this.myAssignedInitial = this.myAssignedInitial.filter((item) => item._id !== target.id)
            let res = await this.rootStore.requestStore.deleteWorkingUser(this.selectedUser.username, target.id)
            if (res === undefined) {
                this.updateProperty("showAlert", true);
                document.getElementById("danger-alert-workingRegion").innerHTML =
                    "El grup d'anuncis no s'ha pogut eliminar. Siusplau, intenti-ho més tard.";
                setTimeout(() => {
                    this.updateProperty("showAlert", false);
                }, 5000);
            } else {
                this.updateProperty("confirmAlert", true);
                document.getElementById("succes-alert-workingRegion").innerHTML =
                    "El grup d'anuncis s'ha eliminat correctament.";
                setTimeout(() => {
                    this.updateProperty("confirmAlert", false);
                }, 5000);
            }
        } catch (error) {
            if (error.toString() === "Error: AuthError")
                console.log("Authentication failed");
        }
    };

    @action("Get Suggested Assigned") getSuggestedAssigned = async () => {
        try {
            const inputValue = this.assignedAdGroupSearch.trim().toLowerCase();
            let regex = new RegExp(inputValue)
            if (inputValue.length > 0) this.myAssigned = this.myAssignedInitial.filter(item => regex.test(item._id))
            else this.myAssigned = JSON.parse(JSON.stringify(this.myAssignedInitial))
        } catch (error) {
            if (error.toString() === "Error: AuthError")
                console.log("Authentication failed");
        }
    };

    @action("Get Suggested User") getSuggestedUser = async () => {
        try {
            const inputValue = this.workingUserSearch.trim().toLowerCase();
            let regex = new RegExp(inputValue)
            if (inputValue.length > 0) this.users = this.usersInitial.filter(item => regex.test(item.username))
            else this.users = JSON.parse(JSON.stringify(this.usersInitial))
        } catch (error) {
            if (error.toString() === "Error: AuthError")
                console.log("Authentication failed");
        }
    }

    @action("Sort by:") onClickTh(e, name) {
        let expr
        if (this.activeSort === name) {
            this.order *= -1
        }
        else {
            this.activeSort = name
            this.order = 1
        }
        if (name === "adGroupCode") {
            expr = function (a, b) {
                if (a._id < b._id) return -1
                else return 1
            }
        }
        else if (name === "numAds") {
            expr = function (a, b) {
                if (a.childrenId.length < b.childrenId.length) return -1
                else return 1
            }
        }
        else if (name === "city") {
            expr = function (a, b) {
                let city_a = a.municipality ? a.municipality : a.municipi ? a.municipi : ""
                city_a = city_a.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                let city_b = b.municipality ? b.municipality : b.municipi ? b.municipi: ""
                city_b = city_b.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                if (city_a < city_b) return -1
                else return 1
            }
        }
        else if(name === "roomType"){
            expr = function (a, b) {
                let room_a = a.roomType ? a.roomType : ""
                room_a = room_a.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                let room_b = b.roomType ? b.roomType : ""
                room_b = room_b.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                if (room_a < room_b) return -1
                else return 1
            }
        }
        
        if (expr && this.adgroupsInitial) {
            this.adgroupsInitial = this.adgroupsInitial.slice().sort((a, b) => expr(a, b) * this.order)
            this.myAssignedInitial = this.myAssignedInitial.slice().sort((a, b) => expr(a, b) * this.order)
            this.myAssigned = this.myAssigned.slice().sort((a, b) => expr(a, b) * this.order)
        }
        expr = null
        if (name === "username") {
            expr = function (a, b) {
                if (a.username < b.username) return -1
                else return 1
            }
        }
        else if (name === "assigned") {
            expr = function (a, b) {
                if (a.count < b.count) return -1
                else return 1
            }
        }
        else if (name === "migrated") {
            expr = function (a, b) {
                if (a.migrated < b.migrated) return -1
                else return 1
            }
        }
        else if (name === "checked") {
            expr = function (a, b) {
                if (a.checked < b.checked) return -1
                else return 1
            }
        }
        else if (name === "tocheck") {
            expr = function (a, b) {
                if (a.tocheck < b.tocheck) return -1
                else return 1
            }
        }
        if (expr) {
            this.users = this.users.slice().sort((a, b) => expr(a, b) * this.order)
            this.usersInitial = this.usersInitial.slice().sort((a, b) => expr(a, b) * this.order)
        }
    }

    @computed get adgroups() {
        if (this.selectedUser) {
            let inputValue = this.adGroupSearch.trim().toLowerCase();
            let result
            if (inputValue.length > 0) {
                let regex = new RegExp(inputValue)
                result = this.adgroupsInitial.filter(item => regex.test(item._id))
            }
            else {
                result = this.adgroupsInitial
            }
            return result
        }
    }
}
export default AdGroupEditStore;
