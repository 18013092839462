import DashboardStore from "../components/Dashboard/DashboardStore";
import UserStore from "./UserStore";
import NavigationStore from "./NavigationStore";
import RequestStore from "./RequestStore";
import MapListStore from "../components/Map/MapListStore";
import PageStore from "./PageStore";
import TeamStore from "../components/Team/TeamStore";
import AdStore from "./AdStore";
import LogStore from "./LogStore";
import GenericListStore from "../components/GenericList/GenericListStore";
import LicenseListStore from "../components/LicenseList/LicenseListStore";
import HostListStore from "../components/HostList/HostListStore";
import LicenseStore from "../components/LicenseList/LicenseStore";
import HostStore from "../components/Host/HostStore";
import GroupEditorStore from "../components/GroupEditor/GroupEditorStore";
import ForgotPasswordStore from "../components/ForgotPassword/ForgotPasswordStore";
import ResetPasswordStore from "../components/ResetPassword/ResetPasswordStore";
import ContactStore from "../components/Contact/ContactStore";
import SearchStore from "../components/Search/SearchStore";
import UserProfileStore from "../components/UserProfile/UserProfileStore";
import ProcessStore from "../components/Process/ProcessStore";
import UIStore from "../components/basicUI/UIStore";
import GroupAdsStore from "../components/GroupAds/GroupAdsStore";
import RegionSelectorStore from "../components/RegionSelector/RegionSelectorStore";
import StatisticStore from "../components/Statistics/StatisticStore";
import WorkingRegionStore from "../components/WorkingRegionEdit/WorkingRegionStore";
import ActivityStore from "../components/Activity/ActivityStore";
import i18n from "../i18n"
import NewUserStore from "../components/NewUser/NewUserStore";
import NewClientStore from "../components/NewClient/NewClientStore";
import UnionStore from "../components/GroupAdsUnion/UnionStore";
import TransferAdsStore from "../components/TransferAds/TransferAdsStore";
import CityHallStore from "../components/CityHall/CityHallStore";
import TicketsStore from "../components/Tickets/TicketsStore"
import CommandStore from "../components/Command/CommandStore"
import NewRegionSelectorStore from "../components/NewRegionSelector/NewRegionSelectorStore";
import StatsNircStore from "../components/Statistics/StatsNirc/StatsNircStore";
import CockpitStore from "../components/Cockpit/CockpitStore";
import MapLicenseStore from "../components/MapLicense/MapLicenseStore";
import OtaStore from "../components/Ota/OtaStore"
import AdGroupEditStore from "../components/AdGroupEdit/AdGroupEditStore";
import TrainingStore from "../components/Training/TrainingStore";

class RootStore {
  constructor(sessionStore) {
    this.sessionStore = sessionStore;
    this.navigationStore = new NavigationStore(this);
    this.requestStore = new RequestStore(this);
    this.userStore = new UserStore(this);
    this.dashboardStore = new DashboardStore(this);
    this.mapListStore = new MapListStore(this);
    this.teamStore = new TeamStore(this);
    this.pageStore = new PageStore(this);
    this.adStore = new AdStore(this);
    this.logStore = new LogStore(this);
    this.licenseStore = new LicenseStore(this);
    this.forgotPasswordStore = new ForgotPasswordStore(this);
    this.resetPasswordStore = new ResetPasswordStore(this);
    this.hostStore = new HostStore(this);
    this.contactStore = new ContactStore(this);
    this.searchStore = new SearchStore(this);
    this.userProfileStore = new UserProfileStore(this);
    this.processStore = new ProcessStore(this);
    this.uiStore = new UIStore(this);
    // this.favStore = new FavStore(this);
    this.groupAdsStore = new GroupAdsStore(this);
    this.groupEditorStore = new GroupEditorStore(this);
    this.genericListStore = new GenericListStore(this);
    this.licenseListStore = new LicenseListStore(this);
    this.hostListStore = new HostListStore(this);
    this.regionSelectorStore = new RegionSelectorStore(this);
    this.statisticStore = new StatisticStore(this);
    this.workingRegionStore = new WorkingRegionStore(this);
    this.adGroupEditStore = new AdGroupEditStore(this);
    this.activityStore = new ActivityStore(this);
    this.t = i18n.t;
    this.newUserStore = new NewUserStore(this);
    this.newClientStore = new NewClientStore(this);
    this.unionStore = new UnionStore(this);
    this.transferAdsStore = new TransferAdsStore(this);
    this.cityHallStore = new CityHallStore(this);
    this.ticketsStore = new TicketsStore(this);
    this.commandStore = new CommandStore(this);
    this.newRegionSelectorStore = new NewRegionSelectorStore(this);
    this.statsNircStore = new StatsNircStore(this);
    this.cockpitStore = new CockpitStore(this);
    this.mapLicenseStore = new MapLicenseStore(this);
    this.otaStore = new OtaStore(this);
    this.trainingStore = new TrainingStore(this);
    this.version = "1.0.8.2"
  }

  getVersion = () => {
    return this.version;
  };
}

export default RootStore;
