import React, { Component } from "react";
import { observer } from "mobx-react";

@observer
class MySuggestionElem extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  findAncestor(el, cls) {
    if (!el.classList.contains(cls)) {
      while ((el = el.parentElement) && !el.classList.contains(cls));
    }
    return el;
  }

  convertToFormat(tags) {
    let result = ""
    for (let i in tags) {
      result += tags[i]
      if (parseInt(i)+1 < tags.length) result += " "
    }
    return result
  }

  onClick(event) {
    // let elem = this.findAncestor(event.target, "sugg_" + this.props.index);
    let elem = document.getElementById("sugg_" + this.props.index);
    let id = document.getElementById("id_" + this.props.index).innerText;
    let nameElem = document.getElementById("name_" + this.props.index);
    let adm = document.getElementById("adm_" + this.props.index)
    let _id = this.props.suggestion._id
    if(adm) adm = adm.innerText
    let name = "";
    if (nameElem) {
      name = nameElem.innerHTML;
    }
    if(elem){
      elem = elem.innerText;
    }
    // var clickedElement = "";
    // var children = elem.children;
    // let info = "";
    // for (var i = 0; i < children.length; i++) {
    //   if (i > 0) info += "_";
    //   info += children[i]["innerText"];
    // }
    this.props.onClick(id, name, adm, elem,_id);
  }

  render() {
    return (
      <li
        id={"sugg_" + this.props.index}
        name={this.props.suggestion.name}
        onClick={this.onClick}
      >
        {this.props.searchOption === "ad_id" && (
          <label className="display-none" id={"id_" + this.props.index}>
            {this.props.suggestion.adGroupCode}
          </label>
        )}
        {this.props.searchOption === "groupad_id" && (
          <label className="display-none" id={"id_" + this.props.index}>
            {this.props.suggestion._id}
          </label>
        )}
        {this.props.searchOption === "license_code" && (
          <label className="display-none" id={"id_" + this.props.index}>
            {this.props.suggestion.licenseCode}
          </label>
        )}
        {this.props.searchOption === "host_name" && (
          <label className="display-none" id={"id_" + this.props.index}>
            {this.props.suggestion.hostNickname}
          </label>
        )}
        {this.props.searchOption === "region_name" && (
          <label className="display-none" id={"id_" + this.props.index}>
            {this.props.suggestion._id}
          </label>
        )}
        {this.props.searchOption === "tag" && (
          <label className="display-none" id={"id_" + this.props.index}>
            {this.props.suggestion.id}
          </label>
        )}
        {this.props.searchOption === "host_id" && (
          <label className="display-none" id={"id_" + this.props.index}>
            {this.props.suggestion.id}
          </label>
        )}
        {this.props.fieldsToShow.map(field => (
          <p
            className="suggestion-label"
            key={field + "_" + this.props.index}
            id={field + "_" + this.props.index}
          >
            {field !== "tags" && field !== "parentName" ? this.props.suggestion[field] : field !== "tags" && field === "parentName" && this.props.suggestion[field] !== undefined ? "("+this.props.suggestion[field]+")" : this.convertToFormat(this.props.suggestion[field])}
          </p>
        ))}
      </li>
    );
  }
}

export default MySuggestionElem;
