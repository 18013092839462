import { observable, action, computed, values } from "mobx";
import i18n from "../../i18n";

class NewClientStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @observable visibleFilter = "";

    @observable written = false;
    @observable editStates = false;
    @observable editCompliance = false;

    @observable newClientForm = {
        clientName: "",
        regionName: "",
        adm: "",
        code: "",
        platforms: "",
        arrayPlatforms: [],
        adStates: [],
        mandatoryLicense: false,
        allowedAccess: false,
        monthlyMapping: false,
        compliance: false,
        dashboard: false,
        censusMap: false,
        complianceStatus: [],
        constraints: "",
        arrayConstraints: [],
    };

    @observable permits = {}
    @observable arrayPermits = [];
    @observable arrayRoles = [];
    
    @observable permitSaved = false;

    @observable adStatesForm = {
        name: "",
        abbreviation: "",
        values: [],
        editVal: -1,
    };

    @observable complianceStatusForm = {
        key: "",
        fillColor: "",
        strokeColor: "",
        constraints: [],
        editVal: -1,
    };

    @observable constraintForm = {
        key: "",
        value: false,
    }

    @action("Initialize Store") initializeStore = async () => {
        this.initializePermits();
        //this.initialized = true;
    };

    @action("update form property") updateFormProperty(key, value) {
        this.newClientForm[key] = value;
    }

    @action("update form property") updateAdSFormProperty(key, value) {
        this.adStatesForm[key] = value;
    }

    @action("update form property") updateCompSFormProperty(key, value) {
        this.complianceStatusForm[key] = value;
    }

    @action("convert arrays") convertArrays(array) {
        array = array.replace(/\s+/g, '');
        return array.split(',');
    }

    @action("update attribute") updateVisibleFilter = (value) => {
        if (this.visibleFilter === value) this.visibleFilter = "";
        else {
            this.visibleFilter = value;
        }
    };

    @action("check box") checkBox = (key) => {
        this.newClientForm[key] = !this.newClientForm[key];
    }

    @action("load adState") loadState = (key) => {
        this.adStatesForm = JSON.parse(JSON.stringify(this.newClientForm.adStates[key]));
        this.adStatesForm.editVal = key;
    }

    @action("add adState") addState() {
        let val = document.getElementById("adSValues").value; //Buscar otra manera, pasar value por parámetro?
        let adStatesNew = {
            name: this.adStatesForm.name,
            abbreviation: this.adStatesForm.abbreviation,
            values: this.convertArrays(val)
        }
        this.newClientForm.adStates.push(adStatesNew);
        this.clearState();
    }

    @action("edit adState") editAdState = (key) => {
        let adStatesUpdate = {
            name: this.adStatesForm.name,
            abbreviation: this.adStatesForm.abbreviation,
            values: this.adStatesForm.values,
        }
        this.newClientForm.adStates[key] = adStatesUpdate;
        this.editStates = false;
        this.clearState();
    }

    @action("clear adState") clearState() {
        this.adStatesForm = {
            name: "",
            abbreviation: "",
            values: [],
            editVal: -1,
        }
    }

    @action("load complianceStatus") loadCompliance = (key) => {
        this.complianceStatusForm = JSON.parse(JSON.stringify(this.newClientForm.complianceStatus[key]));
        this.complianceStatusForm.editVal = key;
    }

    @action("add complianceStatus") addCompliance() {
        let complianceStatusNew = {
            key: this.complianceStatusForm.key,
            fillColor: this.complianceStatusForm.fillColor,
            strokeColor: this.complianceStatusForm.strokeColor,
            constraints: this.complianceStatusForm.constraints,
        }
        this.newClientForm.complianceStatus.push(JSON.parse(JSON.stringify(complianceStatusNew)));
        this.clearCompliance();
    }

    @action("edit complianceStatus") editComplianceStatus = (key) => {
        let complianceUpdate = {
            key: this.complianceStatusForm.key,
            fillColor: this.complianceStatusForm.fillColor,
            strokeColor: this.complianceStatusForm.strokeColor,
            constraints: this.complianceStatusForm.constraints,
        }
        this.newClientForm.complianceStatus[key] = JSON.parse(JSON.stringify(complianceUpdate));
        this.editCompliance = false;
        this.clearCompliance();
    }

    @action("clear complianceStatus") clearCompliance() {
        let constr = this.complianceStatusForm.constraints;
        for (let i = 0; i < constr.length; i++) {
            constr[i].value = false;
        }
        this.complianceStatusForm = {
            key: "",
            fillColor: "",
            strokeColor: "",
            constraints: constr,
            editVal: -1,
        }
    }

    @action("update constraint") updateCheckConstraint(key) {
        this.complianceStatusForm.constraints[key].value = !this.complianceStatusForm.constraints[key].value;
    }

    @action("initialize all permits with empty data") initializePermits() {
        let arrayPermits = ["regionEdit", "adGroupEdit", "assignAds", "excelAssigned", "complianceFilter"];
        let permitIni = {
            usage: false,
            roles: [],
            users: []
        }
        for (let i in arrayPermits) {
            this.permits[arrayPermits[i]] = permitIni;
        }
    }

    @action("add or remove permits from the list") addPermit(permit) {
        if (this.arrayPermits.includes(permit)) {
            let i = this.arrayPermits.indexOf(permit);
            this.arrayPermits.splice(i, 1);
        }
        else {
            this.arrayPermits.push(permit);
        }
    }

    @action("save permits") savePermits() {
        if (this.permitSaved) {
            this.arrayPermits = [];
            this.arrayRoles = [];
            this.initializePermits();
            this.permitSaved = false;
        }
        else {
            let keys = Object.keys(this.permits);
            for (let i = 0; i < keys.length; ++i) {
                if (this.arrayPermits.includes(keys[i])) this.permits[keys[i]].usage = true;
                else this.permits[keys[i]].usage = false;
            }
            this.permitSaved = true;
        }
    }

    @action("add a role from the list") addRole() {
       let newRole = {
        name: "",
        rank: this.arrayRoles.length
       }
       if (!this.arrayRoles.includes(newRole)) this.arrayRoles.push(newRole);
    }

    @action("remove a role from the list") removeRole(role, i) {
        if (i !== (this.arrayRoles.length - 1)) {
            for (let j = (i + 1); j < this.arrayRoles.length; ++j) this.arrayRoles[j].rank--;
        }
        this.arrayRoles.splice(i, 1);
        let keys = Object.keys(this.permits);
        for (let k in keys) {
            let l = this.permits[keys[k]].roles.indexOf(role.name);
            if (l !== -1) this.permits[keys[k]].roles.splice(l, 1);
        }
    }

    @action("update permits from a role") updateRolePermit(permit, role) {
        if (this.permits[permit].roles.includes(role.name)) {
            let i = this.permits[permit].roles.indexOf(role.name);
            if (i !== -1) this.permits[permit].roles.splice(i, 1);
        }
        else {
            this.permits[permit].roles.push(role.name);
        }
    }

    @action("update the value of a role") updateRoleProperty(i, value) {
        let keys = Object.keys(this.permits);
        for (let j in keys) {
            let k = this.permits[keys[j]].roles.indexOf(this.arrayRoles[i].name);
            if (k !== -1) this.permits[keys[j]].roles[k] = value;
        }
        this.arrayRoles[i].name = value;
    }

    //Comprova si hi ha un role amb el nom buit
    @computed get emptyRole() {
        let empty = false;
        if (this.arrayRoles.length > 0) {
            for (let i in this.arrayRoles) {
                if (this.arrayRoles[i].name === "") empty = true;
            }
        }
        return empty;
    }

    @action("save data") saveData() {
        //Guarda array platforms
        let platforms = this.newClientForm.platforms;
        let ap = this.convertArrays(platforms);
        this.newClientForm.arrayPlatforms = ap;
        //Guarda array constraints
        let constraints = this.newClientForm.constraints;
        let ac = this.convertArrays(constraints);
        this.newClientForm.arrayConstraints = ac;
        //Genera array constraints a complianceStatusForm
        this.updateConstraint();
        //Activar compliance status
        if (this.newClientForm.constraints !== 'undefined' && this.newClientForm.constraints.length > 0) this.written = true;
    }

    @action("update Constraint list") updateConstraint() {
        //add constraints
        for (let constraint of this.newClientForm.arrayConstraints) {
            let constraintCompStatus = {
                key: constraint,
                value: false,
            }
            //afegir constraints a complianceStatusForm si falten
            let existCSForm = false;
            for (let i = 0; i < this.complianceStatusForm.constraints.length; i++) {
                if (constraintCompStatus.key === this.complianceStatusForm.constraints[i].key) existCSForm = true;
            }
            if (!existCSForm) this.complianceStatusForm.constraints.push(constraintCompStatus);
            //afegir constraints noves a newClientForm si es modifica la llista de constraints
            if (this.written && this.newClientForm.complianceStatus.length > 0) {
                let existNCForm = false;
                for (let i = 0; i < this.newClientForm.complianceStatus[0].constraints.length; i++) {
                    if (constraintCompStatus.key === this.newClientForm.complianceStatus[0].constraints[i].key) existNCForm = true;
                }
                if (!existNCForm) {
                    for (let i = 0; i < this.newClientForm.complianceStatus.length; i++) {
                        this.newClientForm.complianceStatus[i].constraints.push(constraintCompStatus);
                    }
                }
            }
        }
        //remove constraints
        //treure constraints a complianceStatusForm si ja no hi son
        let removeCSForm = [];
        for (let i = 0; i < this.complianceStatusForm.constraints.length; i++) {
            let noExistCSForm = true;
            for (let j = 0; j < this.newClientForm.arrayConstraints.length; j++) {
                if (this.complianceStatusForm.constraints[i].key === this.newClientForm.arrayConstraints[j]) noExistCSForm = false;
            }
            if (noExistCSForm) removeCSForm.push(i);
        }
        //recorre array de remove i treure constraints a complianceStatusForm
        for (let i = 0; i < removeCSForm.length; i++) {
            this.complianceStatusForm.constraints.splice(removeCSForm[i], 1);
        }
        //treure constraints a newClientForm si ja no hi son
        if (this.written && this.newClientForm.complianceStatus.length > 0) {
            let removeNCForm = [];
            for (let i = 0; i < this.newClientForm.complianceStatus[0].constraints.length; i++) {
                let noExistNCForm = true;
                for (let j = 0; j < this.newClientForm.arrayConstraints.length; j++) {
                    if (this.newClientForm.complianceStatus[0].constraints[i].key === this.newClientForm.arrayConstraints[j]) noExistNCForm = false;
                }
                if (noExistNCForm) removeNCForm.push(i);
            }
            for (let i = 0; i < removeNCForm.length; i++) {
                for (let k = 0; k < this.newClientForm.complianceStatus.length; k++) {
                    this.newClientForm.complianceStatus[k].constraints.splice(removeNCForm[i], 1);
                }
            }
        }
    }

    @action("Create new Client") createClient = async () => {
        let body = {
            name: this.newClientForm.clientName,
            group: this.newClientForm.regionName,
            adm: this.newClientForm.adm,
            code: this.newClientForm.code,
            platforms: this.newClientForm.arrayPlatforms,
            adStates: this.newClientForm.adStates,
            mandatoryLicense: this.newClientForm.mandatoryLicense,
            allowedAccess: this.newClientForm.allowedAccess,
            monthlyMapping: this.newClientForm.monthlyMapping,
            compliance: this.newClientForm.compliance,
            dashboard: this.newClientForm.dashboard,
            censusMap: this.newClientForm.censusMap,
            complianceStatus: this.newClientForm.complianceStatus,
            constraints: this.newClientForm.arrayConstraints,
            permits: this.permits,
            roles: this.arrayRoles
        }
        console.log("Body: ", body);
        let res = await this.rootStore.requestStore.createNewClient(body);
        this.newClientForm = {
            clientName: "",
            regionName: "",
            adm: "",
            code: "",
            platforms: "",
            arrayPlatforms: [],
            adStates: [],
            complianceStatus: [],
            constraints: "",
            arrayConstraints: [],
        }
        this.initializePermits();
        this.arrayPermits = [];
        this.arrayRoles = [];
        this.permitSaved = false;
    }

    @action("upload file") uploadFile = async () => {
        //manda-ho a requestStore per fer el post
        const url = 'https://httpbin.org/post';
        let form = document.getElementById("load-form");
        if (!(form instanceof HTMLFormElement)) console.error("No s'ha trobat form valid");
        let formData = new FormData(form);
        const file = document.getElementById("load-file").files[0];
        //if (file) formData.append("file", file);
        let res = await this.rootStore.requestStore.uploadFileServer(formData);
    }
}
export default NewClientStore;