import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import MyButton from "../basicUI/MyButton";
import { withTranslation } from "react-i18next";
import MyModal from "../basicUI/MyModal";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

@inject("groupEditorStore", "sessionStore", "navigationStore")
@observer
class GroupEditor extends Component {
  async componentDidMount() {
    try {
      let {
        match: {
          params: { code },
        },
      } = this.props;
      let { t } = this.props;
      document.title = t("ng.title");
      //profile function
      await this.props.groupEditorStore.getAdsAndAdGroupByCode(code);
    } catch (error) {
      if (error.toString() === "Error: AuthError")
        console.log("Authentication failed");
    }
  }

  componentWillUnmount() {
    this.props.groupEditorStore.updateProperty("showAlert", false);
    this.props.groupEditorStore.updateProperty("confirmAlert", false);
  }

  slideImage = (index, direction) => {
    let indexOfImage = this.props.groupEditorStore.ads[index].index;
    let lengthOfImagesArray =
      this.props.groupEditorStore.ads[index].images.length;
    if (direction === -1) {
      if (indexOfImage - 1 < 0)
        this.props.groupEditorStore.ads[index].index = lengthOfImagesArray - 1;
      else this.props.groupEditorStore.ads[index].index = indexOfImage - 1;
    } else if (direction === 1) {
      if (lengthOfImagesArray - 1 < indexOfImage + 1)
        this.props.groupEditorStore.ads[index].index = 0;
      else this.props.groupEditorStore.ads[index].index = indexOfImage + 1;
    }
  };

  render() {
    const { t } = this.props;
    if (this.props.sessionStore.initialized) {
      return (
        <>
          <main role="main" className="container-fluid groupEditor-page">
            <div className="row agrupacio-content">
              <div
                className={
                  "alert alert-danger" +
                  (!!this.props.groupEditorStore.showAlert
                    ? " alert-custom show"
                    : " alert-custom hide")
                }
              >
                <p id="danger-alert-groupeditor"></p>
                <button
                  type="button"
                  className="close"
                  onClick={() => {
                    this.props.groupEditorStore.updateProperty(
                      "showAlert",
                      false
                    );
                  }}
                >
                  x
                </button>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <div className="row">
                  <div className="col-md-12">
                    <OverlayTrigger
                      delay={{
                        show: 500,
                        hide: 100,
                      }}
                      overlay={
                        <Tooltip id="tooltip">
                          {t("ng.tooltips.newGroup")}
                        </Tooltip>
                      }
                    >
                      <button
                        type="button"
                        className="btn btn-outline-info edit-agrupacions"
                        onClick={() => {
                          this.props.groupEditorStore.updateProperty("showModal", true)
                          this.props.groupEditorStore.updateProperty("workingUserModal", false)
                          this.props.groupEditorStore.updateProperty("individualModal", false)
                        }}
                      >
                        {t("ng.newGroup")}
                      </button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      delay={{
                        show: 500,
                        hide: 100,
                      }}
                      overlay={
                        <Tooltip id="tooltip">
                          {t("ng.tooltips.newInd")}
                        </Tooltip>
                      }
                    >
                      <button
                        type="button"
                        className="btn btn-outline-info edit-agrupacions"
                        data-toggle="modal"
                        data-target=".edit-agrupacions-confirmacio-modal-individual"
                        onClick={() => {
                          this.props.groupEditorStore.updateProperty("showModal", true)
                          this.props.groupEditorStore.updateProperty("workingUserModal", false)
                          this.props.groupEditorStore.updateProperty("individualModal", true)
                        }}
                      >
                        {t("ng.newInd")}
                      </button>
                    </OverlayTrigger>
                    <MyModal />

                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 lists">
                    <table className="table table-bordered table-striped tableFixHead">
                      <thead>
                        <tr>
                          <th></th>
                          <th>{t("ng.ad")}</th>
                          <th>{t("ng.image")}</th>
                          <th>{t("ng.licenseCode")}</th>
                          <th>{t("ng.licenseStatus")}</th>
                          <th>{t("ng.hostName")}</th>
                          <th>{t("ng.host")}</th>
                          <th>{t("ng.room")}</th>
                          <th>{t("ng.lat")}</th>
                          <th>{t("ng.long")}</th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.props.groupEditorStore.ads
                          ? this.props.groupEditorStore.ads.map((ad, i) => {
                            return (
                              <tr key={ad.id}>
                                <td className="check-td">
                                  <input
                                    type="checkbox"
                                    id={"checkbox" + ad.id}
                                    name={"checkbox." + ad.id}
                                    className="nocheck"
                                    onChange={(e) => {
                                      this.props.groupEditorStore.updateCheckboxListFilter(
                                        ad,
                                        e
                                      );
                                    }}
                                  ></input>
                                </td>
                                <td>{ad.id}</td>
                                <td
                                  className="reagrup-img-td"
                                  style={{ width: "14rem" }}
                                >
                                  <div className="reagrup-ad-components">
                                    <MyButton
                                      className="btn btn-default reagrup-arrow-btn-left"
                                      text={
                                        <svg
                                          aria-hidden="true"
                                          className="fas fa-chevron-left"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 320 512"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                                          ></path>
                                        </svg>
                                      }
                                      onClick={() => {
                                        this.slideImage(i, -1);
                                      }}
                                    />
                                    <MyButton
                                      className="btn btn-default reagrup-arrow-btn-right"
                                      text={
                                        <svg
                                          className="fas fa-chevron-right"
                                          aria-hidden="true"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 320 512"
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                                          ></path>
                                        </svg>
                                      }
                                      onClick={() => {
                                        this.slideImage(i, 1);
                                      }}
                                    />
                                  </div>

                                  <img
                                    className="reagrup-ads-img"
                                    src={
                                      ad.index >= 0
                                        ? ad.images[ad.index]
                                        : null
                                    }
                                    alt="Card img cap"
                                  />
                                </td>
                                <td>
                                  {ad.license && ad.license.code
                                    ? ad.license.code
                                    : "-"}
                                </td>
                                <td>
                                  {ad.license
                                    ? ad.license.status === "noff"
                                      ? t("ng.noOfficial")
                                      : ad.license.status === "offi"
                                        ? t("ng.official")
                                        : ad.license.status === "bfor"
                                          ? t("ng.badFormat")
                                          : ad.license.status === "noli"
                                            ? t("ng.noLicense")
                                            : "-"
                                    : "-"}
                                </td>
                                <td>
                                  {ad.host && ad.host.nickname
                                    ? ad.host.nickname
                                    : "-"}
                                </td>
                                <td>
                                  {ad.host && ad.host.id ? ad.host.id : "-"}
                                </td>
                                <td>{ad.roomType ? ad.roomType : "-"}</td>
                                <td>{ad.geometry.coordinates[1]}</td>
                                <td>{ad.geometry.coordinates[0]}</td>
                              </tr>
                            );
                          })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          </main>
        </>
      );
    }
  }
}

export default withTranslation()(GroupEditor);
